import React, { useEffect, useState } from "react";
import PolicyModel from "./PolicyModel";
import { Clock, IndianRupee, MapPin } from "lucide-react";
import AddressModel from "./AddressModel";
import { useLocation } from "react-router-dom";
import { addToCard, getCartItemList, getDetailsForBookingApi, getProviderOnDemand, payment, removeFromCart, SavedAddressApi } from "../../Service/api-path";
import Skeleton from '@mui/material/Skeleton';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { Snackbar } from "@mui/material";
import { CleanHands } from "@mui/icons-material";
import axios from "axios";
import { StandaloneSearchBox, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import { getProviderOnDemand } from "../../Service/api-path";

const AddCardComponent = () => {
  const local = useLocation();
  const [addcartLoading, setAddCartLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  // const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [cardList, setcartList] = useState([]);
  const location = useLocation();
  const [BookDate, setBookDate] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [searchBox, setSearchBox] = useState(null);
      const [address, setAddress] = useState("");
    
  
  const [markerPosition, setMarkerPosition] = useState(null);
console.log(markerPosition,"==markerPosition====")
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });

  console.log(addresses,"===addressstate")
  // console.log('location: ', location);
  const user_id = localStorage.getItem('user_id');
  const [sendDetails, setSendDetails] = useState([]);
  const handleSave = () => {
    console.log('Address saved:', currentAddress);
  };
  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const location = places[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        setMarkerPosition({ lat, lng });
        console.log('Latitude:', lat);
        console.log('Longitude:', lng);
      }
    }
  };
  

  const getDetailsForBooking = async () => {
    const response = await getDetailsForBookingApi({ user_id });

    if (response.data) {
      setSendDetails(response.data.result);
    }
  }

  useEffect(() => {
    if (local.pathname === '/cart') {
      getDetailsForBooking();
    }
  }, [local.pathname]);

  useEffect(()=>{
     fetchSavedAddress()
  },[])

  const fetchSavedAddress =async()=>{
    setLoading(true);
    try {
      console.log(SavedAddressApi)
      const response = await SavedAddressApi()
      console.log(response,"===response address")
      setAddresses(response.data);
    } catch (error) {
      console.error('Failed to fetch addresses:', error);
    } finally {
      setLoading(false);
    }
  }

  const GetCardList = async (user_id) => {
    const response = await getCartItemList({ user_id });
    console.log('get cardlist: ', response?.data?.result);
    if (Array.isArray(response?.data?.result)) {
      const total = response.data.result.reduce(
        (acc, item) => acc + item.standard_price * item.quantity,
        0
      );
      console.log('total: ', total);
      console.log('response: ', response);
      setTotalAmount(total);
      setcartList(response.data.result);
      setLoading(false);
    } else {
      console.error('response.data.result is not an array:', response?.data?.result);
      setTotalAmount(0); // Default to 0 if no valid data is available
      setLoading(false);
      setcartList([]); // Set an empty cart list
    }
  }

  console.log('user_id: ', user_id);
  useEffect(() => {
    if (location.pathname === '/cart') {
      GetCardList(user_id);
    }
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleRemoveFromCart = async (item, index) => {

    try {
      setAddCartLoading(true);
      const payload = {
        user_id,
        masterId: item.masterId,
        cat_id: item.cat_id,
        sub_cat_id: item.sub_cat_id,
        quantity: item.quantity - 1,
        provider_id: item.provider_id,
      };
      console.log('payload: ', payload);

      const response = await removeFromCart(payload);
      if (response.data) {  
        setcartList((prevCardList) =>
          prevCardList.map((cartItem, idx) =>
            idx === index
              ? { ...cartItem, quantity: cartItem.quantity > 0 ? cartItem.quantity - 1 : 0 }
              : cartItem
          )
        );

        setTotalAmount((totalAmount) =>
          totalAmount - (item.standard_price || 0)
        );
        setAddCartLoading(false);
        setMessage(response.data.message);
        handleClick();

        if (item.quantity - 1 === 0) {
          await GetCardList(user_id);
        }
      }
      console.log("response: ", response);
    } catch (error) {
      console.error("Error removing from cart: ", error);
      setAddCartLoading(false);
      setcartList((prevCardList) =>
        prevCardList.map((cartItem, idx) =>
          idx === index
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    }
  };


  const handleAddToCart = async (item, index) => {
    console.log('item: ', item);
    setcartList((prevCardList) =>
      prevCardList.map((cartItem, idx) =>
        idx === index
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
    try {
      // Prepare payload for the API
      setAddCartLoading(true);
      // console.log("ITEM",item)
      const payload = {
        user_id,
        masterId: item.masterId,
        cat_id: item.cat_id,
        sub_cat_id: item.sub_cat_id,
        quantity: item.quantity + 1,
        provider_id: item.provider_id,
        price: item.standard_price,
        booking_time: item.booking_time,
        schedule_date: item.schedule_date,
      };
      console.log('payload: ', payload);

      const response = await addToCard(payload);
      if (response.data) {
        setTotalAmount((prevTotalAmount) =>
          Number(prevTotalAmount) + Number(item.standard_price || 0)
        );
        setAddCartLoading(false);
        setMessage(response.data.message);
        handleClick();
        // console.log('totalAmount: ', totalAmount);
      }
      console.log("response: ", response);
    } catch (error) {
      console.error("Error adding to cart: ", error);
      // Revert the optimistic update in case of an error
      setAddCartLoading(false);
      setcartList((prevCardList) =>
        prevCardList.map((cartItem, idx) =>
          idx === index
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
  };

  const toggleAddressModal = () => {
    setIsAddressOpen(!isAddressOpen);
  }

  const handleSnackbarClose = () => setSnackbarOpen(false);

  

  const toggleModal = () => {
    setIsOpen(!isOpen);
  }

  const [currentAddress, setCurrentAddress] = useState('');
  const [add, setAdd] = useState('');

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          console.log("object", data);
          setAdd(data.address);
          const addressParts = [
            data.address?.house_number || '',
            data.address?.residential || data?.address?.city || '',
            data.address?.state_district || '',
            data.address?.state || ''
          ];
          const formattedAddress = addressParts
            .filter(part => part.trim() !== '') // Filter out empty parts
            .join(', ');
          setCurrentAddress(formattedAddress);

        })
        .catch((error) => console.error("Error fetching location:", error));
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          bgcolor: '#121212',
          p: 8,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Skeleton
          sx={{ bgcolor: 'grey.900' }}
          variant="rectangular"
          width={210}
          height={118}
        />
      </Box>
    );
  }

  // const handleClickPayNow = async () => {
  //   console.log('Pay Now Clicked');
  //   if (!currentAddress || !sendDetails) {
  //     alert('Please select address and send details');
  //   }
  //   const orders = cardList?.map(item => ({
  //     sub_cat_id: item.sub_cat_id,
  //     provider_id: item.provider_id,
  //     quantity: item.quantity,
  //     schedule_time: item.booking_time,
  //     schedule_date: item.schedule_date,
  //     user_address: currentAddress,
  //   }));

  //   const payload = {
  //     user_id,
  //     orders,
  //   };
  //   console.log('payload: ', payload);
  //   try {
  //     const response = await payment(payload);
  //     console.log('response: ', response.data);
  //     if (response.data) {
  //       alert('Payment Successful');
  //       await GetCardList(user_id)
  //     }
  //   } catch (error) {
  //     console.error('Error while processing payment:', error);

  //   }
  // }
  
  
  const handleClickPayNow = async () => {
    if (!currentAddress || !sendDetails) {
      alert("Please select an address with a provider.");
      return;
    }
  
    const orders = cardList?.map(item => ({
      sub_cat_id: item.sub_cat_id,
      provider_id: item.provider_id,
      quantity: item.quantity,
      schedule_time: item.booking_time,
      schedule_date: item.schedule_date,
      user_address: currentAddress,
    }));
  
    const payload = {
      user_id,
      orders,
    };
  
    console.log("payload: ", payload);
  
    try {
      const response = await payment(payload);
      console.log("response: ", response.data);
      if (response.data) {
        alert("Payment Successful");
        await GetCardList(user_id);
      }
    } catch (error) {
      console.error("Error while processing payment:", error);
    }
  };
  
  const isPayNowDisabled = !currentAddress || !sendDetails || !sendDetails;



  const onProviderCheck = (location, address) => {
    setCurrentAddress(address); // Update selected address
    setMarkerPosition(location); // Update marker position
    console.log("Provider check successful for:", address);
  };
  

  const handleAddressClick = async (address) => {
    let lat = address.latitude || address.latitude2 || address.latitude3 || null;
    let lng = address.longitude || address.longitude2 || address.longitude3 || null;
  
    if (!lat || !lng) {
      setSnackbarMessage("Selected address does not have valid coordinates.");
      setSnackbarOpen(true);
      return;
    }
  
    // Convert lat/lng to float and update state
    const selectedLocation = { lat: parseFloat(lat), lng: parseFloat(lng) };
    setMarkerPosition(selectedLocation);
    setCurrentAddress(address.Address);
  
    try {
      setLoading(true);
      const response = await getProviderOnDemand({
        lat: selectedLocation.lat,
        lng: selectedLocation.lng,
        sub_cat_id: cardList.sub_cat_id || null,
        radius: 5,
      });
  
      if (response?.data?.result?.length > 0) {
        // ✅ Provider found, store for Pay Now API
        setSendDetails(selectedLocation);
        setSnackbarMessage("Provider found! You can proceed with payment.");
        setSnackbarOpen(true);
      } else {
        // ❌ No provider, show error message
        setSendDetails(null); // Clear provider details
        setSnackbarMessage("No service providers available in this area.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error checking providers:", error);
      setSnackbarMessage("An error occurred while checking providers.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  return (
    <div className="flex flex-col md:flex-row w-full min-h-screen p-3 bg-gray-50">
      <div className="flex flex-col w-full md:w-2/3 max-h-[95vh] bg-white rounded-lg shadow-md md:p-10 p-3 space-y-8 overflow-y-auto">
        <div className="border p-2">
        <div className="border-b p-3">
  <h3 className="font-bold flex items-center gap-5 text-lg font-mono">
    <span><MapPin /></span> Send booking details to
  </h3>
  {/* <p className="text-gray-600 m-2 font-mono md:ml-10"> */}
  <p className="text-gray-600 m-2 font-mono md:ml-10">{sendDetails}</p>

    {/* {sendDetails ? `Lat: ${sendDetails.lat}, Lng: ${sendDetails.lng}` : "No address selected yet."} */}
  {/* </p> */}
</div>

          <div className="border-b p-3">
            <h3 className="font-bold text-lg font-mono flex flex-col md:flex-row md:inline-flex items-start md:items-center gap-3 md:gap-5">
              <span className="flex items-center">
                <MapPin className="mr-1" />Address -
              </span>
              <span className="font-normal ml-0 md:ml-1"> {currentAddress || "No address selected yet."}</span>
            </h3>
             
            {/* <div>
      {addresses.length > 0 && (
        <ul className="mt-3">
          {addresses.map((address) => (
            <li
              key={address.id}
              className="p-2 border rounded-lg mb-2 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setCurrentAddress(address.Address);
                handlePlacesChanged(address.lat, address.lng);
                handleSave(address.Address);
              }}
            >
              {address.Address}
            </li>
          ))}
        </ul>
      )}
    </div> */}


<div>
  {addresses.length > 0 && (
    <ul className="mt-3">
      {addresses.map((address) => (
        <li
          key={address.id}
          className="p-2 border rounded-lg mb-2 cursor-pointer hover:bg-gray-100"
          onClick={() => handleAddressClick(address)}
        >
          {address.Address}
        </li>
      ))}
    </ul>
  )}
</div>

            <button
              onClick={toggleAddressModal}
              className="block mt-4 md:mt-2 w-full md:w-auto py-2 px-4 bg-purple-600 font-mono text-white rounded-lg hover:bg-purple-700"
            >
              Search Address
            </button>

           
          </div>

         
          <div className="p-3">
            <h3 className="font-bold text-lg inline-flex font-mono"><IndianRupee
            /><p className="md:ml-5">Payment Method</p></h3>
            {isPayNowDisabled ? (
              <button
                className="block mt-2 md:ml-10 py-2 px-4 bg-gray-300 font-mono text-white rounded-lg cursor-not-allowed">
                Pay Now
              </button>
            ) : (
              <button
  onClick={handleClickPayNow}
  disabled={!sendDetails} // Disable if no provider exists
  className={`block mt-2 md:ml-10 py-2 px-4 font-mono text-white rounded-lg ${
    !sendDetails ? "bg-gray-300 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-700"
  }`}
>
  Pay Now
</button>

            )}
          </div>
        </div>
        <div className="w-full">
          <h3 className="font-bold text-xl font-mono md:mt-[-10px]">Cancellation policy</h3>
          <p className="text-gray-600  text-sm font-mono">
            Free cancellations if done more than 3 hrs before the service or if a
            professional isn’t assigned. A fee will be charged otherwise.
          </p>
          <p onClick={toggleModal} className=" cursor-pointer  underline mt-2 font-semibold font-mono">Read full policy</p>
        </div>
      </div>
      {isOpen && <PolicyModel isOpen={isOpen} toggleModal={toggleModal} />}

      {isAddressOpen && 
      
      <AddressModel
      isAddressOpen={isAddressOpen}
      toggleAddressModal={toggleAddressModal}
      subCatId={cardList?.[0]?.sub_cat_id || null}
      onProviderCheck={(location, address) => {
        setCurrentAddress(address); // Update the selected address
        console.log("Address successfully updated:", address);
        // Additional logic can go here if needed
      }}
    />
    

      }

      <div className="flex flex-col font-mono  w-full md:w-1/3  bg-white rounded-lg shadow-md mt-4 md:mt-0 md:ml-6 p-4 space-y-10">
        <div className="max-h-[60vh] space-y-5 overflow-y-scroll">

          <div>
            {cardList?.length > 0 ? (
              cardList?.map((item, index) => {
                return (
                  <div key={index} className="border-b pb-4">
                    <div className="flex justify-between">
                      <h3 className="font-bold ">{item.cat_name}</h3>
                      <p className="font-bold font-oswald mr-1">₹{item.standard_price}</p>
                    </div>
                    <div className="flex justify-between items-center mt-2">
                      <div className="flex justify-between items-center">
                        {/* <img className="h-13 w-20" src={catImage.images[0].url} alt="Cat" /> */}
                        <p className="text-gray-600 text-sm ml-2">{item.sub_cat_name}</p>
                      </div>
                      <div className="flex items-center">
                        <button onClick={() => handleRemoveFromCart(item, index)} className={`px-2 py-1 border rounded-lg mr-1 ${addcartLoading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={addcartLoading}>-</button>
                        <span className="px-4">{item.quantity}</span>
                        <button
                          onClick={() => handleAddToCart(item, index)}
                          className={`px-2 py-1 border rounded-lg mr-1 ${addcartLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={addcartLoading}
                        >
                          +
                        </button>

                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="border p-4 rounded-lg hidden md:flex flex-col justify-center items-center">
                <img
                  className="h-[70%] w-[80%]"
                  src="https://img.freepik.com/premium-vector/empty-cart_701961-7086.jpg"
                  alt="Empty Cart"
                />
                {/* <h1 className="text-[#626161] font-semibold">No items in your cart</h1> */}
                {
                  user_id ? (<h1 className="text-[#626161] font-semibold">No items in your cart</h1>) : (
                    <h1 className="text-[#626161] font-semibold">You are not Login</h1>
                  )
                }
              </div>
            )}
          </div>

          <div className="border-b pb-4">
            <p className="flex items-center space-x-2">
              <span className="text-green-600 font-bold">✔ ucCover</span>
              <span className="text-gray-500">protection on this booking</span>
            </p>
            <div className="flex items-center mt-2 space-x-2">
              <input type="checkbox" className="form-checkbox" />
              <span>Avoid calling before reaching the location</span>
            </div>
          </div>

          <div className="border-b pb-4">
            <p className="flex justify-between items-center">
              <span className="font-bold text-gray-800">Coupons and offers</span>
              <span className="text-purple-600 cursor-pointer">2 offers ➔</span>
            </p>
          </div>
        </div>
        <div>
          <h3 className="font-bold text-lg">Payment Summary</h3>
          <div className="flex justify-between items-center mt-2">
            <p className="text-gray-600">Amount to pay</p>
            <p className="font-bold">₹{totalAmount}</p>
          </div>
          <p className="text-purple-600 cursor-pointer mt-2">View breakdown</p>
        </div>

      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
      // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              
              
            />

    </div>
  );
  
};

export default AddCardComponent;
