import React from 'react'
import Navbar from '../components/Navbar'
import ListProvider from '../components/ServiceProvider/ListProvider/ListProvider.js'
import Footer from '../components/Footer'

function ServiceProvider({currentAddress}) {
 
  return (
    <div>
      <Navbar currentAddress={currentAddress} />
      <ListProvider currentAddress={currentAddress} />
      <Footer />
    </div>
  )
}

export default ServiceProvider
