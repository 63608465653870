import { X } from "lucide-react";
import React from "react";

function PolicyModel({ isOpen, toggleModal }) {

  return (
    <div className="flex items-center justify-center min-h-screen">
      {isOpen && (
        <>

          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="flex flex-col max-w-[90%] max-h-[40%] sm:max-h-[70%] sm:w-[40%] overflow-auto p-4 bg-white rounded-lg shadow-lg">
              <div className="mb-4">
                <X onClick={toggleModal} className="cursor-pointer" />
                <img
                  className="h-[80vh] w-auto mx-auto"
                  src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_template/w_1232,dpr_2,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1695716473247-98f13d.jpeg"
                  alt="Image 1"
                />
              </div>
              <div className="mb-4">
                <img
                  className="h-[80vh] w-auto mx-auto"
                  src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_template/w_1232,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1695716479304-95838d.jpeg"
                  alt="Image 2"
                />
              </div>
              <div className="mb-4">
                <img
                  className="h-[80vh] w-auto mx-auto"
                  src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_template/w_1232,dpr_1,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1695716487133-a6d7f9.jpeg"
                  alt="Image 3"
                />
              </div>
            </div>
          </div></>

      )}
    </div>
  );
}

export default PolicyModel;
