import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getMasterCategory, getSubCategory, subcategoryservice, addSubProvider, getCurrentMasterCategory, getCurrentCategory, getSubCurrentCategory } from '../Service/provider-api-path';
import Cookies from 'js-cookie'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const AddEmployee = () => {
  const [masterServices, setMasterServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const provider_id = Cookies.get('providerid')
  console.log("provider id", provider_id)
  const [formData, setFormData] = useState({
    subProoviderName: '',
    subProviderAge: '',
    subMasterId: '',
    subProviderCatId: '',
    subProviderSubCatId: '',
    provideId: '',
    sub_providerNumber: ''
  });

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchMasterServices = async () => {
      try {
        // const response = await axios.get(getMasterCategory);
        const response = await axios.get(`${getCurrentMasterCategory}?provider_id=${provider_id}`);
        setMasterServices(response.data.result);
      } catch (error) {
        console.error('Error fetching master services:', error);
      }
    };

    fetchMasterServices();
  }, []);

  const fetchCategories = async (serviceId) => {
    try {
      // const response = await axios.get(`${getSubCategory}?masterid=${serviceId}`);
      const response = await axios.get(`${getCurrentCategory}?provider_id=${provider_id}&masterId=${serviceId}`);
      setCategories(response.data.result);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      // const response = await axios.get(`${subcategoryservice}/${categoryId}`);
      const response = await axios.get(`${getSubCurrentCategory}?provider_id=${provider_id}&masterId=${formData.subMasterId}&catId=${categoryId}`);
      setSubCategories(response.data.result);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const providerId = Cookies.get('providerid');
    console.log('Provider ID from Cookie:', providerId); // Log the value of providerid
    if (providerId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        providerId: providerId,
      }));
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get provider ID from cookies
    const providerId = Cookies.get('providerid');

    // Prepare payload with proper data types
    const payload = {
      subProoviderName: formData.subProviderName, // String
      subProviderAge: parseInt(formData.subProviderAge, 10), // Integer
      subMasterId: parseInt(formData.subMasterId, 10), // Integer
      subProviderCatId: parseInt(formData.subProviderCatId, 10), // Integer
      subProviderSubCatId: parseInt(formData.subProviderSubCatId, 10), // Integer
      providerId: parseInt(providerId, 10), // Integer
      sub_providerNumber: formData.sub_providerNumber, // String
    };

    console.log('Submitting form data:', payload);

    try {
      const response = await axios.post(addSubProvider, payload);
      console.log('API Response:', response.data);
      setSnackbarMessage('Sub Provider Added !!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setFormData({
        subProviderName: '',
        subProviderAge: '',
        subMasterId: '',
        subProviderCatId: '',
        subProviderSubCatId: '',
        providerId: providerId, // Keep providerId from the cookie
        sub_providerNumber: '',
      });
    } catch (error) {
      console.error('Error adding sub provider:', error);
      setSnackbarMessage('Some error occurred');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };


  return (
    <div className="bg-gray-100 flex justify-center h-screen">
      <div className="p-4 sm:ml-64 mt-10 max-w-xl">
        <div className="p-4 rounded-lg dark:border-gray-700 mt-10">
          <div className="flex items-center justify-center h-auto mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <form
              onSubmit={handleSubmit}
              className="border shadow-lg rounded-xl p-5 max-w-xl mx-auto"
            >
              <p className="text-2xl text-center font-bold text-gray-600 pb-4">
                Add Sub Provider
              </p>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Sub Provider Name
                  </label>
                  <input
                    type="text"
                    name="subProviderName"
                    value={formData.subProviderName}
                    onChange={handleInputChange}
                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    placeholder="Enter sub provider name"
                    required
                  />
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Sub Provider Age
                  </label>
                  <input
                    type="number"
                    name="subProviderAge"
                    value={formData.subProviderAge}
                    onChange={handleInputChange}
                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    placeholder="Enter sub provider age"
                    required
                  />
                </div>
              </div>


              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Master Service
                  </label>
                  <select
                    name="subMasterId"
                    value={formData.subMasterId}
                    onChange={(e) => {
                      handleInputChange(e);
                      fetchCategories(e.target.value);
                    }}
                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    required
                  >
                    <option value="" disabled>
                      Select Master Service
                    </option>
                    {masterServices.map((service) => (
                      <option key={service.id} value={service.masterId}>
                        {service.masterName}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Category
                  </label>
                  <select
                    name="subProviderCatId"
                    value={formData.subProviderCatId}
                    onChange={(e) => {
                      handleInputChange(e);
                      fetchSubCategories(e.target.value);
                    }}
                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    required
                  >
                    <option value="">Select a Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.cat_id}>
                        {category.cat_name}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Sub Category
                  </label>
                  <select
                    name="subProviderSubCatId"
                    value={formData.subProviderSubCatId}
                    onChange={handleInputChange}
                    className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    required
                  >
                    <option value="">Select a Subcategory</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.sub_cat_id}>
                        {subCategory.sub_cat_name}
                      </option>
                    ))}
                  </select>

                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <label className="block text-sm font-medium text-gray-500 mb-1">
                    Provider Number
                  </label>
                  <input
                    type="number"
                    name="sub_providerNumber"
                    value={formData.sub_providerNumber}
                    onChange={handleInputChange}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 outline-none"
                    placeholder=" "
                    required
                  />

                </div>
              </div>

              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          autoHideDuration={3000} // 3 seconds
          onClose={handleClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default AddEmployee;
