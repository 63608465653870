// import React, { useState, useEffect } from 'react'
// import { IoCloudDone } from "react-icons/io5";
// import { MdOutlinePendingActions } from "react-icons/md";
// import { IoIosPersonAdd } from "react-icons/io";
// import { MdPending } from "react-icons/md";
// import { GrMoney } from "react-icons/gr";
// import { getBookingRequest, getDashboardFigures, acceptBookingRequest, getSubProviderList } from '../Service/provider-api-path.js'
// import axios from 'axios';
// import './ProviderNavbar.css'
// import Cookies from 'js-cookie'
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import { Vortex } from 'react-loader-spinner';
// import { Skeleton } from 'primereact/skeleton';

// const ProviderDashboard = () => {
//   const [bookingData, setBookingData] = useState([])
//   const [subcatid, setSubcatid] = useState(0)

//   const [dashbaordFigures, setDashboardFigutres] = useState([])
//   const [providerList, setProviderList] = useState([])
//   console.log("Provider daTA", providerList)
//   const [isActionDone, setIsActionDone] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [skeletonLoading, setSkeletonLoading] = useState(true);
//   const [isAccepted, setIsAccepted] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [loadingStates, setLoadingStates] = useState({}); // Keyed by booking ID

//   const id = Cookies.get("providerid")
//   // console.log("ID",id)
//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') return;
//     setSnackbarOpen(false);
//   };
//   const provider_id = Cookies.get('providerid')
//   // Function to toggle button status
//   const toggleStatus = () => {
//     setIsAccepted((prevState) => !prevState);
//   };
//   const fetchdashboardFigures = async () => {
//     try {
//       const response = await axios.get(`${getDashboardFigures}?provider_id=${id}`);
//       console.log('Dashboard figures', response.data.result);
//       setDashboardFigutres(response.data.result);
//     } catch (error) {
//       console.error('Error fetching dashboard data', error);
//     }
//   };


//   const fetchSubProviders = async (bookingId) => {
//     try {
//       const response = await axios.get(`${getSubProviderList}?providerId=${id}&sub_cat_id=${subcatid}`);
//       setBookingData((prevBookingData) =>
//         prevBookingData.map((booking) =>
//           booking.BookingID === bookingId
//             ? {
//               ...booking,
//               providerList: response.data.data, // Update only the matching booking
//             }
//             : booking
//         )
//       );
//     } catch (error) {
//       console.error("Error fetching providers:", error);
//     }
//   };

//   const fetchBookingData = async () => {
//     try {
//       const response = await axios.get(getBookingRequest + `?provider_id=${id}`);
//       console.log('Bookings', response.data.result);
//       setBookingData(response.data.result);

//       // Check if response.data.result is an array and has items
//       if (Array.isArray(response.data.result) && response.data.result.length > 0) {
//         console.log("sub cat id", typeof response.data.result[0].sun_cat_id);
//         setSubcatid(response.data.result[0].sun_cat_id); // Properly access the first item's sun_cat_id
//       }
//     } catch (error) {
//       console.error('Error fetching booking data:', error);
//     } finally {
//       setSkeletonLoading(false); // Stop loading once the API call is complete
//     }
//   };


//   const handleAction = async (booking, status) => {
//     try {
//       setLoadingStates((prev) => ({ ...prev, [booking.BookingID]: true })); // Set loading for specific bookingId

//       const payload = {
//         provider_id: id,
//         user_id: booking.user_id,
//         AcceptanceStatus: status,
//         sub_cat_id: booking.sun_cat_id,
//         Booking_id: booking.BookingID,
//         sub_providerId: booking.selectedEmployee || null,
//         sub_providerName: booking.selectedEmployeeName || "",
//         sub_providerNumber: booking.selectedEmployeeNumber || "",
//         price: booking.Earnings,
//       };

//       const response = await axios.post(acceptBookingRequest, payload);

//       if (response.data.result.status === true || response.data.result.status === false) {
//         setSnackbarMessage(status === 1 ? "Accepted" : "Declined");
//         setSnackbarSeverity("success");
//         setSnackbarOpen(true);
//         fetchBookingData(); // Refresh booking data
//       }
//     } catch (error) {
//       console.error("Error updating booking:", error);
//     } finally {
//       setLoadingStates((prev) => ({ ...prev, [booking.BookingID]: false })); // Reset loader for this bookingId
//     }
//   };


//   const handleEmployeeSelect = (bookingId, event) => {
//     const selectedEmployeeId = event.target.value;
//     const selectedEmployeeName = event.target.options[event.target.selectedIndex]?.getAttribute("data-name");
//     const selectedEmployeeNumber = event.target.options[event.target.selectedIndex]?.getAttribute("data-number");

//     setBookingData((prevBookingData) =>
//       prevBookingData.map((booking) =>
//         booking.BookingID === bookingId
//           ? {
//             ...booking,
//             selectedEmployee: selectedEmployeeId,
//             selectedEmployeeName,
//             selectedEmployeeNumber,
//           }
//           : booking
//       )
//     );
//   };




//   const handleDecline = () => {
//     setIsActionDone("declined");
//     setSnackbarMessage("Declined");
//     setSnackbarSeverity("error");
//     setSnackbarOpen(true);
//     // Add additional logic for declining here
//   };

//   useEffect(() => {
//     fetchdashboardFigures();
//     fetchBookingData();
//   }, []);


//   return (
//     <div className='bg-gray-100 h-screen'>
//       <div class="p-4 sm:ml-64 bg-gray-100">
//         <div class="p-1  border-gray-200  rounded-lg dark:border-gray-700 mt-14">

//           <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">

//             <div class="flex items-center justify-between p-4 rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-purple-500 text-white">
//               <div>
//                 <h3 class="text-lg font-semibold">Total Booking </h3>
//                 <p class="text-2xl font-bold">{dashbaordFigures.totalBookings}</p>
//                 <p class="text-sm">Jan - Dec 2024</p>
//               </div>
//               <div>
//                 <IoIosPersonAdd color='white' size={30} />
//               </div>
//             </div>


//             <div class="flex items-center justify-between p-4 rounded-lg shadow-md bg-gradient-to-r from-pink-500 to-red-500 text-white">
//               <div>
//                 <h3 class="text-lg font-semibold"> Bookings Complete</h3>
//                 <p class="text-2xl font-bold">{dashbaordFigures.completedBookings}</p>
//                 <p class="text-sm">Jan-Dec 2024</p>
//               </div>
//               <div>
//                 <IoCloudDone color='white' size={30} />
//               </div>
//             </div>


//             <div class="flex items-center justify-between p-4 rounded-lg shadow-md bg-gradient-to-r from-orange-400 to-yellow-400 text-white">
//               <div>
//                 <h3 class="text-lg font-semibold">Pending Bookings</h3>
//                 <p class="text-2xl font-bold">{dashbaordFigures.pendingBookings}</p>
//                 <p class="text-sm">Dec 2024</p>
//               </div>
//               <div>
//                 <MdOutlinePendingActions color='white' size={30} />
//               </div>
//             </div>


//             <div class="flex items-center justify-between p-4 rounded-lg shadow-md bg-gradient-to-r from-blue-600 to-blue-400 text-white">
//               <div>
//                 <h3 class="text-lg font-semibold">Total Earnings</h3>
//                 <p class="text-2xl font-bold">{dashbaordFigures.totalEarnings}</p>
//                 <p class="text-sm">Jan - Dec 2024</p>
//               </div>
//               <div>
//                 <GrMoney color='white' size={30} />
//               </div>
//             </div>
//           </div>


//           <div className="flex items-center py-5 shadow-xl bg-white justify-center h-50 mb-4 rounded dark:bg-gray-800">
//             <div className="relative overflow-x-auto max-w-sm md:max-w-full shadow-md sm:rounded-lg">
//               {skeletonLoading ? <>
//                 <table className="min-w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
//                   <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                     <tr>
//                       <th scope="col" className="px-6 py-3">Booking ID</th>
//                       <th scope="col" className="px-6 py-3">Customer Name</th>
//                       <th scope="col" className="px-6 py-3">Booking Date</th>
//                       <th scope="col" className="px-6 py-3">Appointment Date</th>
//                       <th scope="col" className="px-6 py-3">Work Status</th>
//                       <th scope="col" className="px-6 py-3">Price</th>
//                       <th scope="col" className="px-6 py-3 text-center">Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {/* Render 5 skeleton rows */}
//                     {Array.from({ length: 5 }).map((_, index) => (
//                       <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-24 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-32 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-28 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-28 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-20 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-16 mx-auto"></div>
//                         </td>
//                         <td className="px-6 py-4">
//                           <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-20 mx-auto"></div>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </> : <>
//                 <table className="min-w-full text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
//                   <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                     <tr>
//                       <th scope="col" className="px-6 py-3">Booking ID</th>
//                       <th scope="col" className="px-6 py-3">Customer Name</th>
//                       <th scope="col" className="px-6 py-3">Booking Date</th>
//                       <th scope="col" className="px-6 py-3">Appointment Date</th>
//                       <th scope="col" className="px-6 py-3">Work Status</th>
//                       <th scope="col" className="px-6 py-3">Price</th>
//                       <th scope="col" className="px-6 py-3 text-center">Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {bookingData.length > 0 ? (
//                       bookingData.map((booking) => (
//                         <tr
//                           key={booking.BookingID}
//                           className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
//                         >
//                           <th
//                             scope="row"
//                             className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
//                           >
//                             {booking.BookingID}
//                           </th>
//                           <td className="px-6 py-4">{booking.CustomerName}</td>
//                           <td className="px-6 py-4">
//                             {new Date(booking.Booking_Date).toLocaleDateString('en-US', {
//                               year: 'numeric',
//                               month: 'long',
//                               day: 'numeric',
//                             })}
//                           </td>
//                           <td className="px-6 py-4">{booking.Appointment}</td>
//                           <td className="px-6 py-4">{booking.STATUS}</td>
//                           <td className="px-6 py-4">${booking.Earnings}</td>
//                           <td className="px-6 py-4 flex flex-col items-center">
//                             {/* Dropdown for selecting an employee */}
//                             <select
//                               className="mb-2 px-4 py-2 border rounded-md text-sm"
//                               value={booking.selectedEmployee || ''}
//                               onClick={() => fetchSubProviders(booking.BookingID)} // Pass bookingId to fetch specific providers
//                               onChange={(e) => handleEmployeeSelect(booking.BookingID, e)} // Use bookingId to isolate selection
//                             >
//                               <option value="">Select Employee</option>
//                               {booking.providerList?.map((employee, index) => (
//                                 <option
//                                   key={`${booking.BookingID}-${index}`} // Ensure uniqueness with bookingId
//                                   value={employee.sub_providerId}
//                                   data-name={employee.sub_Name}
//                                   data-number={employee.sub_providerNumber}
//                                 >
//                                   {employee.sub_Name}
//                                 </option>
//                               ))}
//                             </select>



//                             {/* Accept/Decline Buttons */}
//                             {booking.AcceptanceStatus === 0 || booking.AcceptanceStatus === 2 ? (
//                               <button
//                                 type="button"
//                                 className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 flex justify-center items-center"
//                                 onClick={() => handleAction(booking, 1)}
//                                 disabled={!booking.selectedEmployee || loadingStates[booking.id]} // Disable only this button
//                               >
//                                 {loadingStates[booking.id] ? (
//                                   <Vortex
//                                     visible={true}
//                                     height="20"
//                                     width="20"
//                                     ariaLabel="vortex-loading"
//                                     colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
//                                   />
//                                 ) : (
//                                   "Accept"
//                                 )}
//                               </button>


//                             ) : (
//                               <button
//                                 type="button"
//                                 className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 flex justify-center items-center"
//                                 onClick={() => handleAction(booking, 2)}
//                                 disabled={loading}
//                               >
//                                 {loading ? (
//                                   <Vortex
//                                     visible={true}
//                                     height="20"
//                                     width="20"
//                                     ariaLabel="vortex-loading"
//                                     colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
//                                   />
//                                 ) : (
//                                   'Decline'
//                                 )}
//                               </button>
//                             )}
//                           </td>
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td
//                           colSpan="7"
//                           className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
//                         >
//                           No bookings available.
//                         </td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>

//               </>}


//             </div>
//           </div>
//         </div>
//       </div>

//       <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
//         <Alert
//           autoHideDuration={3000} // 3 seconds
//           onClose={handleClose}
//           severity={snackbarSeverity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   )
// }

// export default ProviderDashboard


// ============================================================================

import React, { useState, useEffect } from 'react'
import { IoCloudDone } from "react-icons/io5";
import { MdOutlinePendingActions } from "react-icons/md";
import { IoIosPersonAdd } from "react-icons/io";
import { MdPending } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { getBookingRequest, getDashboardFigures, acceptBookingRequest, getSubProviderList } from '../Service/provider-api-path.js'
import axios from 'axios';
import './ProviderNavbar.css'
import Cookies from 'js-cookie'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Vortex } from 'react-loader-spinner';
import { Skeleton } from 'primereact/skeleton';

const ProviderDashboard = () => {


  const [bookingData, setBookingData] = useState([])
  const [subcatid, setSubcatid] = useState(0)

  const [dashbaordFigures, setDashboardFigutres] = useState([])
  const [providerList, setProviderList] = useState([])
  console.log("Provider daTA", providerList)
  const [isActionDone, setIsActionDone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadingStates, setLoadingStates] = useState({}); // Keyed by booking ID

  const id = Cookies.get("providerid")
  // console.log("ID",id)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };
  const provider_id = Cookies.get('providerid')
  // Function to toggle button status
  const toggleStatus = () => {
    setIsAccepted((prevState) => !prevState);
  };
  const fetchdashboardFigures = async () => {
    try {
      const response = await axios.get(`${getDashboardFigures}?provider_id=${id}`);
      console.log('Dashboard figures', response.data.result);
      setDashboardFigutres(response.data.result);
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    }
  };


  const fetchSubProviders = async (bookingId) => {
    try {
      const response = await axios.get(`${getSubProviderList}?providerId=${id}&sub_cat_id=${subcatid}`);
      console.log("EMPLOYESS ",response.data)
      setBookingData((prevBookingData) =>
        prevBookingData.map((booking) =>
          booking.BookingID === bookingId
            ? {
              ...booking,
              providerList: response.data.data, 
            }
            : booking
        )
      );
    } catch (error) {
      console.error("Error fetching providers:", error);
    }
  };





  const fetchBookingData = async () => {
    try {
      const response = await axios.get(getBookingRequest + `?provider_id=${id}`);
      console.log('Bookings', response.data.result);
      setBookingData(response.data.result);

      // Check if response.data.result is an array and has items
      if (Array.isArray(response.data.result) && response.data.result.length > 0) {
        console.log("sub cat id",  response.data.result[0].sun_cat_id);
        setSubcatid(response.data.result[0].sub_cat_id); // Properly access the first item's sun_cat_id
      }
    } catch (error) {
      console.error('Error fetching booking data:', error);
    } finally {
      setSkeletonLoading(false); // Stop loading once the API call is complete
    }
  };


  const handleAction = async (booking, status) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [booking.BookingID]: true })); // Set loading for specific bookingId
console.log("INSIDE ACTION",booking.providerList)
      const payload = {
        provider_id: id,
        user_id: booking.user_id,
        price:booking.Earnings,
        AcceptanceStatus: status,
        sub_cat_id: JSON.stringify(booking.sub_cat_id),
        Booking_id: booking.BookingID,
        sub_providerId: booking.selectedEmployee || null,
        sub_providerName: booking.selectedEmployeeName || "",
        sub_providerNumber: booking.selectedEmployeeNumber || "",
      };

      const response = await axios.post(acceptBookingRequest, payload);

      if (response.data.result.status === true || response.data.result.status === false) {
        setSnackbarMessage(status === 1 ? "Accepted" : "Declined");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchBookingData(); // Refresh booking data
      }
    } catch (error) {
      console.error("Error updating booking:", error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [booking.BookingID]: false })); // Reset loader for this bookingId
    }
  };


  const handleEmployeeSelect = (bookingId, event) => {
    const selectedEmployeeId = event.target.value;
    const selectedIndex = event.target.selectedIndex;
    const selectedEmployeeName = event.target.options[selectedIndex]?.getAttribute("data-name");
    const selectedEmployeeNumber = event.target.options[selectedIndex]?.getAttribute("data-number");
  
    console.log('Selected Index:', selectedIndex);
    console.log('Selected Employee ID:', selectedEmployeeId);
    console.log('Selected Employee Name:', selectedEmployeeName);
    console.log('Selected Employee Number:', selectedEmployeeNumber);
  
    setBookingData((prevBookingData) =>
      prevBookingData.map((booking) =>
        booking.BookingID === bookingId
          ? {
            ...booking,
            selectedEmployee: selectedEmployeeId,
            selectedEmployeeName,
            selectedEmployeeNumber,
          }
          : booking
      )
    );
  };
  




  const handleDecline = () => {
    setIsActionDone("declined");
    setSnackbarMessage("Declined");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
    // Add additional logic for declining here
  };

  useEffect(() => {
    fetchdashboardFigures();
    fetchBookingData();
  }, []);


  return (
    <div className='bg-gray-100 h-screen'>
      <div class="p-4 sm:ml-64 bg-gray-100">
      <div className=" border-gray-200 rounded-lg dark:border-gray-700 mt-14">
      {/* Cards Section */}
      <div className="grid  grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
        <div className="flex items-center justify-between p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-500 to-purple-500 text-white">
          <div>
            <h3 className="text-lg font-semibold">Total Booking</h3>
            <p className="text-2xl font-bold">{dashbaordFigures.totalBookings}</p>
            <p className="text-sm">Jan - Dec 2024</p>
          </div>
          <div>
            <IoIosPersonAdd color="white" size={30} />
          </div>
        </div>

        <div className="flex items-center justify-between p-4 rounded-lg shadow-lg bg-gradient-to-r from-pink-500 to-red-500 text-white">
          <div>
            <h3 className="text-lg font-semibold">Bookings Complete</h3>
            <p className="text-2xl font-bold">{dashbaordFigures.completedBookings}</p>
            <p className="text-sm">Jan - Dec 2024</p>
          </div>
          <div>
            <IoCloudDone color="white" size={30} />
          </div>
        </div>

       
        <div className="flex items-center justify-between p-4 rounded-lg shadow-lg bg-gradient-to-r from-orange-400 to-yellow-400 text-white">
          <div>
            <h3 className="text-lg font-semibold">Pending Bookings</h3>
            <p className="text-2xl font-bold">{dashbaordFigures.pendingBookings}</p>
            <p className="text-sm">Dec 2024</p>
          </div>
          <div>
            <MdOutlinePendingActions color="white" size={30} />
          </div>
        </div>

      
        <div className="flex items-center justify-between p-4 rounded-lg shadow-lg bg-gradient-to-r from-blue-600 to-blue-400 text-white">
          <div>
            <h3 className="text-lg font-semibold">Total Earnings</h3>
            <p className="text-2xl font-bold">{dashbaordFigures.totalEarnings}</p>
            <p className="text-sm">Jan - Dec 2024</p>
          </div>
          <div>
            <GrMoney color="white" size={30} />
          </div>
        </div>
      </div>

      {/* Table Section */}
     
        <div className="relative overflow-x-auto w-[19rem] md:w-full shadow-md sm:rounded-lg pt-3">
          {skeletonLoading ? (
            <table className=" w-[19rem] text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">Booking ID</th>
                  <th scope="col" className="px-6 py-3">Customer Name</th>
                  <th scope="col" className="px-6 py-3">Booking Date</th>
                  <th scope="col" className="px-6 py-3">Appointment Date</th>
                  <th scope="col" className="px-6 py-3">Work Status</th>
                  <th scope="col" className="px-6 py-3">Price</th>
                  <th scope="col" className="px-6 py-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4">
                      <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-24 mx-auto"></div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-32 mx-auto"></div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-28 mx-auto"></div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="h-4 bg-gray-300 rounded dark:bg-gray-600 w-20 mx-auto"></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <table className="w-[19rem]  md:w-full border text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">Booking ID</th>
                  <th scope="col" className="px-6 py-3">Customer Name</th>
                  <th scope="col" className="px-6 py-3">Booking Date</th>
                  <th scope="col" className="px-6 py-3">Service</th>
                  <th scope="col" className="px-6 py-3">Appointment Date</th>
                  <th scope="col" className="px-6 py-3">Work Status</th>
                  <th scope="col" className="px-6 py-3">Price</th>
                  <th scope="col" className="px-6 py-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {bookingData.length > 0 ? (
                  bookingData.map((booking) => (
                    <tr
                      key={booking.BookingID}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {booking.BookingID}
                      </th>
                      <td className="px-6 py-4">{booking.CustomerName}</td>
                      <td className="px-6 py-4">
                            {new Date(booking.Booking_Date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </td>
                      <td className="px-6 py-4">{booking.sub_cat_name}</td>

                      
                      <td className="px-6 py-4">{booking.Appointment}</td>
                      <td className="px-6 py-4">{booking.STATUS}</td>
                      <td className="px-6 py-4">${booking.Earnings}</td>
                      <td className="px-6 py-4 flex flex-col items-center">
                        <select
                          className="mb-2 px-4 py-2 border rounded-md text-sm"
                          value={booking.selectedEmployee || ''}
                          onClick={() => fetchSubProviders(booking.BookingID)}
                          onChange={(e) => handleEmployeeSelect(booking.BookingID, e)}
                        >
                          <option value="">Select Employee</option>
                          {booking.providerList?.map((employee, index) => (
                            <option 
                            key={`${booking.BookingID}-${index}`} 
                            value={employee.sub_providerId} 
                            data-name={employee.sub_Name} 
                            data-number={employee.sub_providerNumber} // Ensure this matches the correct property in your employee object
                          >
                            {employee.sub_Name}
                          </option>
                        ))}
                        </select>
                        <button
                          type="button"
                          className="text-white  bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                          onClick={() => handleAction(booking, 1)}
                        >
                          Accept
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
                    >
                      No bookings available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
  
 


{/* <div className="flex flex-col items-center py-5 bg-white justify-center h-auto mb-4 rounded dark:bg-gray-800">
  <div className="relative overflow-x-auto mt-6 w-full max-w-7xl px-4 sm:px-6 lg:px-8">
    <table className="min-w-full text-sm text-center text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th className="px-4 py-3">Booking ID</th>
          <th className="px-4 py-3">Customer Name</th>
          <th className="px-4 py-3">Booking Date</th>
          <th className="px-4 py-3">Appointment Date</th>
          <th className="px-4 py-3">Work Status</th>
          <th className="px-4 py-3">Price</th>
          <th className="px-4 py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        {bookingData.length > 0 ? (
          bookingData.map((booking) => (
            <tr
              key={booking.BookingID}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td className="px-4 py-3">{booking.BookingID}</td>
              <td className="px-4 py-3">{booking.CustomerName}</td>
              <td className="px-4 py-3">
                {new Date(booking.Booking_Date).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </td>
              <td className="px-4 py-3">{booking.Appointment}</td>
              <td className="px-4 py-3">{booking.STATUS}</td>
              <td className="px-4 py-3">${booking.Earnings}</td>
              <td className="px-4 py-3">
                <button
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded text-sm"
                >
                  Accept
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="px-4 py-3 text-center text-gray-500">
              No bookings available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
</div> */}

{/* 
<div className="flex flex-col items-center py-5 bg-white justify-center h-auto mb-4 rounded dark:bg-gray-800">
  <div className="relative overflow-x-auto mt-6 w-full max-w-7xl px-4 sm:px-6 lg:px-8">
    <table className="min-w-full text-sm text-center text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">Booking ID</th>
                      <th scope="col" className="px-6 py-3">Customer Name</th>
                      <th scope="col" className="px-6 py-3">Booking Date</th>
                      <th scope="col" className="px-6 py-3">Service</th>

                      <th scope="col" className="px-6 py-3">Appointment Date</th>
                      <th scope="col" className="px-6 py-3">Work Status</th>
                      <th scope="col" className="px-6 py-3">Price</th>
                      <th scope="col" className="px-6 py-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingData.length > 0 ? (
                      bookingData.map((booking) => (
                        <tr
                          key={booking.BookingID}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {booking.BookingID}
                          </th>
                          <td className="px-6 py-4">{booking.CustomerName}</td>
                          <td className="px-6 py-4">
                            {new Date(booking.Booking_Date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </td>
                          <td className="px-6 py-4">{booking.sub_cat_name}</td>

                          <td className="px-6 py-4">{booking.Appointment}</td>
                          <td className="px-6 py-4">{booking.STATUS}</td>
                          <td className="px-6 py-4">${booking.Earnings}</td>
                          <td className="px-6 py-4 flex flex-col items-center">
                         
                            <select
                              className="mb-2 px-4 py-2 border rounded-md text-sm"
                              value={booking.selectedEmployee || ''}
                              onClick={() => fetchSubProviders(booking.BookingID)} // Pass bookingId to fetch specific providers
                              onChange={(e) => handleEmployeeSelect(booking.BookingID, e)} // Use bookingId to isolate selection
                            >
                              <option value="">Select Employee</option>
                              {booking.providerList?.map((employee, index) => (
                                <option
                                  key={`${booking.BookingID}-${index}`} // Ensure uniqueness with bookingId
                                  value={employee.sub_providerId}
                                  data-name={employee.sub_Name}
                                  data-number={employee.sub_providerNumber}
                                >
                                  {employee.sub_Name}
                                </option>
                              ))}
                            </select>



                        
                            {booking.AcceptanceStatus === 0 || booking.AcceptanceStatus === 2 ? (
                              <button
                                type="button"
                                className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 flex justify-center items-center"
                                onClick={() => handleAction(booking, 1)}
                                disabled={!booking.selectedEmployee || loadingStates[booking.id]} // Disable only this button
                              >
                                {loadingStates[booking.id] ? (
                                  <Vortex
                                    visible={true}
                                    height="20"
                                    width="20"
                                    ariaLabel="vortex-loading"
                                    colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
                                  />
                                ) : (
                                  "Accept"
                                )}
                              </button>


                            ) : (
                              <button
                                type="button"
                                className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 flex justify-center items-center"
                                onClick={() => handleAction(booking, 2)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <Vortex
                                    visible={true}
                                    height="20"
                                    width="20"
                                    ariaLabel="vortex-loading"
                                    colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
                                  />
                                ) : (
                                  'Decline'
                                )}
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="7"
                          className="px-6 py-4 text-center text-gray-500 dark:text-gray-400"
                        >
                          No bookings available.
                        </td>
                      </tr>
                    )}
                  </tbody>
    </table>
  </div>
</div> */}


          
        </div>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          autoHideDuration={3000} // 3 seconds
          onClose={handleClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ProviderDashboard
