



import React, { useEffect, useState, useSyncExternalStore } from "react";
import { ReactTyped } from "react-typed";
import { Bell, MapPin, Search, ShoppingCart, User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
// import Button from '@mui/material/Button';

import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import { logout } from "../Service/api-path";
import { getNotification } from "../Service/api-path";
import logo from "../assets/oys_logo.png"
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaUserPlus } from "react-icons/fa";

import {getCartItemList} from '../Service/api-path'

function Navbar({currentAddress}) {

    var user_id = localStorage.getItem("user_id");
    console.log("USERID",user_id)
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [settings, setSettings] = useState([]);
    console.log(settings,"settings")
    const [mssageModel, setMessageModel] = useState(false);
    const [usermessage, setUserMessage] = useState([]);
    const [openAccountModal, setOpenAccountModal] = useState(false); 
    const [openModal, setOpenModal] = useState(false);
    const [userImage, setUserImage] = useState(null);
const [cartCount,setCartCount]=useState('')

console.log(cartCount,"===cardcount-----")
    useEffect(() => {
        // Check if the image exists in localStorage
        const storedImage = localStorage.getItem("profilePic");
        if (storedImage) {
          setUserImage(storedImage);
        }
      }, []);
    
      

    const getUserNotify = async () => {
        const userId = localStorage.getItem("user_id");
        if (userId) {
            const response = await getNotification();
            console.log('response: ', response);
            setUserMessage(response?.data?.message);
        }
    }

    useEffect(() => {
        getUserNotify();
        const user = localStorage.getItem("name");
        console.log('user: ', user);
        const token = localStorage.getItem("token");

        if (user && token) {
            setSettings([
                // user,
                <strong key="user">User : {user.toUpperCase()}</strong>,
                "User Profile","Order History","Saved Addresses","Switch Account","Logout"]);
        } else {
            setSettings(["Login", "Provider"]);
        }
    }, []);

    const handleLogout = async () => {
        try {
            const user_id = localStorage.getItem("user_id");
            const response = await logout({ user_id, type: "signIn" });
            // console.log('response: ', response);
            localStorage.removeItem("name");
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("profilePic")
            setSettings(["Login"]);
            navigate("/login");
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    const toggleMessageModal = (closeModal) => {
        console.log('closeModal: ', closeModal);
        setMessageModel(closeModal ? false : !mssageModel);
    };

    const handelNavigate = () => {
        navigate("/cart");
    }
    const handelNavigateHomePage = () => {
        navigate("/");
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleAccountModalOpen = () => {
        setOpenAccountModal(true);
        setOpenModal(true)
    };

    const handleAccountModalClose = ()=>{
        setOpenAccountModal(false)
    }


  const GetCardList = async () => {
    console.log("GET CART ITEM USERID",user_id)
   const response = await getCartItemList({ user_id });
   setCartCount(response.data.result.length)
   console.log("length" ,response.data.result.length)
    console.log('cart: ', response?.data?.result);
  }

  

  
  useEffect(()=>{
    GetCardList()
  },[cartCount])

    return (
        <nav onClick={() => toggleMessageModal(true)} className="sticky top-0 z-50 flex items-center justify-between px-4 py-4 bg-white shadow-md">
            {/* Left Section */}
            <div onClick={handelNavigateHomePage} className="flex cursor-pointer items-center space-x-4">
                <div className="flex items-center space-x-2">
                    <div className="bg-black text-white w-8 h-8 flex items-center justify-center rounded-md font-bold">
                        <img src={logo} alt="" />
                    </div>
                    <span className="text-lg font-bold hidden sm:inline font-urbanist">
                        OnYour Service
                    </span>
                </div>
            </div>

            {/* Middle Section */}
            <div className="hidden md:flex items-center space-x-4">
                {/* Location */}
                <div className="flex items-center space-x-2 border px-4 py-2 rounded-md">
                    <MapPin className="text-gray-500 w-5 h-5" />
                    <input
                        type="text"
                        // placeholder={currentAddress}
                        value={currentAddress}
                        // onChange={(e) => setCurrentAddress(e.target.value)}
                        className="outline-none text-sm text-gray-700 font-medium w-52 transition-opacity duration-500"
                    />
                </div>

                {/* Search */}
                <div className="flex items-center space-x-2 border px-4 py-2 rounded-md">
                    <Search className="text-gray-500 w-5 h-5" />
                    <ReactTyped
                        strings={[
                            "Search for \"products\"",
                            "Search for \"categories\"",
                            "Search for \"brands\"",
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        attr="placeholder"
                        loop
                    >
                        <input
                            type="text"
                            className="outline-none text-sm text-gray-700 font-medium w-52 transition-opacity duration-500"
                        />
                    </ReactTyped>
                </div>
            </div>

            {/* Right Section */}
            <div className="flex items-center space-x-8">
                <div className="relative">
                    <Bell onClick={(e) => {
                        e.stopPropagation()
                        toggleMessageModal()
                    }} className="cursor-pointer w-5 h-5" />
                    <span className="absolute top-[-12px] right-[-8px] bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
                        {usermessage.length}
                    </span>
                </div>
                <div className="relative">
                    <ShoppingCart onClick={handelNavigate} className="cursor-pointer w-5 h-5" />
                    <span className="absolute top-[-12px] right-[-12px] bg-red-500 text-white text-xs w-5 h-5 flex items-center justify-center rounded-full md:top-[-10px] md:right-[-10px] md:w-5 md:h-5 lg:top-[-14px] lg:right-[-8px] lg:w-5 lg:h-5">
                        {cartCount?cartCount:0}
                    </span>

                </div>
                {/* <User className="cursor-pointer w-5 h-5" /> */}
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            {/* <Avatar /> */}
                            {userImage ? (
          <Avatar src={userImage} /> // Show the user's image if available
        ) : (
          <Avatar /> // Show the default avatar if no image is available
        )}
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: "px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings?.map((setting) => (
                            <MenuItem
                                key={setting}
                                onClick={() => {
                                    if (setting === "Logout") {
                                        handleLogout();
                                    } else if (setting === "Login") {
                                        navigate("/register");
                                        localStorage.removeItem("name");
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("user_id");
                                        localStorage.removeItem("profilePic") ;     
                                     }
                                     else if (setting === "Switch Account")
                                    {
                                        handleAccountModalOpen()
                                    }
                                    else if (setting === "User Profile")
                                        {
                                            navigate("/user-profile")
                                        }
                                        else if (setting === "Order History")
                                            {
                                                navigate("/userOrder-History")
                                            }

                                            else if (setting === "Saved Addresses")
                                                {
                                                    navigate("/saved-Addresses")
                                                }
                                    else if (setting === "Provider") {
                                        navigate("/provider-register");
                                    }
                                    handleCloseUserMenu();
                                }}
                            >
                                <Typography className="cursor-pointer" sx={{ textAlign: "center" }}>
                                    {setting}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>


            </div>

            
            {mssageModel && (
                <div className="fixed z-50 top-0 right-0 mt-16 mr-4">
                    <div className="w-96 p-4 bg-white rounded-lg shadow-lg">
                        <h2 className="text-lg font-bold mb-4">Messages</h2>
                        <ul className="max-h-64 overflow-y-auto">
                            {usermessage.length > 0 ? (
                                usermessage?.map((msg, index) => (
                                    <li key={index} className="py-2 border-b text-gray-700">
                                        {msg.content}
                                    </li>
                                ))
                            ) : (
                                <li className="text-gray-500">No new messages</li>
                            )}
                        </ul>
                    </div>
                </div>
            )}

            {/* Mobile Dropdown Menu */}
            <div
                id="mobile-menu"
                className="hidden md:hidden absolute top-16 left-0 w-full bg-white shadow-lg p-4"
            >
                {/* Location */}
                <div className="flex items-center space-x-2 mb-4 border px-4 py-2 rounded-md">
                    <MapPin className="text-gray-500 w-5 h-5" />
                    <input
                        type="text"
                        // placeholder={currentAddress}
                        value={currentAddress}
                        // onChange={(e) => setCurrentAddress(e.target.value)}
                        className="outline-none text-sm text-gray-700 font-medium w-full"
                    />
                </div>

                {/* Search */}
                <div className="flex items-center space-x-2 border px-4 py-2 rounded-md">
                    <Search className="text-gray-500 w-5 h-5" />
                    <ReactTyped
                        strings={[
                            "Search for \"products\"",
                            "Search for \"categories\"",
                            "Search for \"brands\"",
                        ]}
                        typeSpeed={40}
                        backSpeed={50}
                        attr="placeholder"
                        loop
                    >
                        <input
                            type="text"
                            className="outline-none text-sm text-gray-700 font-medium w-full"
                        />
                    </ReactTyped>
                </div>
                

                {/* Right Section */}
                <div className="flex justify-between mt-4">
                    <Bell className="text-black w-6 h-6" />
                    <ShoppingCart className="text-gray-500 w-6 h-6" />
                    <User className="text-gray-500 w-6 h-6" />
                </div>
            </div>


            <Modal
        show={openModal}
        size="md"
       onClick={() => setOpenModal(false)}
       popup
         className="flex justify-center items-center lg:mt-36"
>
  {/* <Modal.Header /> */}
  {/* <Modal.Body> */}
  <div class="fixed inset-0 z-40 min-h-full overflow-y-auto overflow-x-hidden transition flex items-center">
    <div aria-hidden="true" class="fixed inset-0 w-full h-full bg-black/50 cursor-pointer">
    </div>

    <div class="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
        <div
            class="w-full py-2 bg-white cursor-default pointer-events-auto dark:bg-gray-800 relative rounded-xl mx-auto max-w-sm">

            <button tabindex="-1" type="button" class="absolute top-2 right-2 rtl:right-auto rtl:left-2">
                <svg title="Close" tabindex="-1" class="h-4 w-4 cursor-pointer text-gray-400"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">
                    Close
                </span>
            </button>



            <div class="space-y-2 p-2">
                <div class="p-4 space-y-2 text-center dark:text-white">
                    <h2 class="text-xl font-bold tracking-tight" id="page-action.heading">
                        Switch Account
                    </h2>

                    {/* <div className="flex justify-center items-center">
                    <FaUserPlus size={30} color="gray" />

                     </div> */}
                    <p class="text-gray-500">
                     Would you like to switch account ?
                      </p>

                </div>
            </div>

            <div class="space-y-2">
                <div aria-hidden="true" class="border-t dark:border-gray-700 px-2"></div>
                <div class="px-6 py-2">
                    <div class="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                        <button type="button" onClick={()=>navigate("/login")}
                                class="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-white shadow focus:ring-white border-transparent bg-blue-500 hover:bg-blue-300 focus:bg-blue-600 focus:ring-offset-blue-700">
                                <span class="flex items-center gap-1">
                                    <span class="">
                                       LogIn To Another Account
                                    </span>
                                </span>
                            </button>

                        <button type="submit" onClick={()=>navigate("/provider-login")}
                                class="inline-flex items-center justify-center py-1 gap-1 font-medium rounded-lg border transition-colors outline-none focus:ring-offset-2 focus:ring-2 focus:ring-inset dark:focus:ring-offset-0 min-h-[2.25rem] px-4 text-sm text-white shadow focus:ring-white border-transparent bg-green-400 hover:bg-green-300 focus:bg-green-600 focus:ring-offset-green-700">

                                <span class="flex items-center gap-1">
                                    <span class="">
                                        LogIn As Provider
                                    </span>
                                </span>

                            </button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
  {/* </Modal.Body> */}
            </Modal>

    
        </nav>
    );
}

export default Navbar;
