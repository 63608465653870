import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { FaTrash } from "react-icons/fa";

function EmployeTable({ employees, loading, handleDelete }) {
    return (
        <div className="mt-3 p-4 rounded-lg bg-white shadow-md md:max-h-[70%] overflow-y-auto">
            <div className="flex justify-between">
                <div>
                    <h2 className="text-xl font-bold mb-4 text-center">Employee List</h2>
                </div>
                <div>
                    <Tooltip title="If Edit is required, then delete the sub provider then add again.">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full border-collapse border border-gray-200">
                    <thead className="bg-gray-200 sticky top-0 z-10">
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2 text-left">#</th>
                            <th className="border border-gray-300 p-2 text-left">Name</th>
                            <th className="border border-gray-300 p-2 text-left">Mobile No.</th>
                            <th className="border border-gray-300 p-2 text-left">Age</th>
                            <th className="border border-gray-300 p-2 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5" className="border border-gray-300 p-2 text-center">Loading...</td>
                            </tr>
                        ) : employees.length > 0 ? (
                            employees.map((emp, index) => (
                                <tr key={`${emp.id}-${index}`} className="hover:bg-gray-100 transition-colors duration-150">
                                    <td className="border border-gray-300 p-2">{index + 1}</td>
                                    <td className="border border-gray-300 p-2">{emp.sub_Name}</td>
                                    <td className="border border-gray-300 p-2">{emp.sub_providerNumber}</td>
                                    <td className="border border-gray-300 p-2">{emp.sub_Age}</td>
                                    <td className="border border-gray-300 p-2 text-center">
                                        <button
                                            className="text-red-500 hover:text-red-700"
                                            onClick={() => handleDelete(emp)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="border border-gray-300 p-2 text-center">No employees found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default EmployeTable;