
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home.js';
import AddCart from './pages/AddCart.js';
import ServiceProvider from './pages/ServiceProvider.js';
import HandleRegister from './components/register/HandleRegister.jsx';
import LoginPage from './components/login/Login.jsx';
import ForgotPassword from './components/login/ForgotPassword.jsx';
import ResetPassword from './components/login/ResetPassword.jsx';
import ProviderRegister from './components/ProviderRegister/ProviderRegister.js';
import ProviderLogin from './components/ProviderLogin/ProviderLogin.js';
import ProviderVerifyOTP from './components/ProviderVerifyOTP/ProviderVerifyOTP.js';
import ProviderLayout from './components/ProviderLayout.js';
import ProviderDashboard from './pages/ProviderDashboard.js';
import AddEmployee from './components/AddEmployee.js';
import ServiceDetails from './components/ServiceDetails/ServiceDetails.js';
import EmployeList from './components/EmployeList/EmployeList.js';
import OrderHistory from './components/OrderHistory/OrderHistory.js';
import Promo from './pages/Promo.js';
import Cookie from 'js-cookie';
import GoogleMapComponent from './components/GoogleMap.js';
import ProviderResetPassword from './components/ProviderRegister/ProviderResetPassword.js';
import UserProfile from './components/User-Profile/UserProfile.js';
import SavedAddress from './components/User-Profile/SavedAddress.js';
// import OrderHistory from './components/User-Profile/OrderHistory.js';
import UserOrderHistory from './components/User-Profile/UserOrderHistory.js';
// Helper Function to Get Authentication Status
const getAuthStatus = () => {
  const userId = localStorage.getItem('user_id'); // Check user ID
  const providerId = Cookie.get('providerid'); // Check provider ID
  const lastLogin = localStorage.getItem('lastLogin'); // Check last login type

  console.log("USERID:", userId, "PROVIDERID:", providerId, "LASTLOGIN:", lastLogin);

  if (!userId && !providerId) {
    // If neither user nor provider is logged in, return null
    return null;
  }

  return { userId, providerId, lastLogin };
};

// Login Handler
const loginHandler = (type, id) => {
  if (type === 'user') {
    localStorage.setItem('user_id', id);
    localStorage.setItem('lastLogin', 'user'); // Update last login type
  } else if (type === 'provider') {
    Cookie.set('providerid', id);
    localStorage.setItem('lastLogin', 'provider'); // Update last login type
  }
};

// Private Route Component
const PrivateRoute = ({ children, allowedFor }) => {
  const auth = getAuthStatus();

  if (!auth) {
    // If no authentication data, redirect to /promo
    return <Navigate to="/promo" />;
  }

  const { userId, providerId, lastLogin } = auth;

  // If only userId exists, block access to provider routes
  if (allowedFor === 'provider' && !providerId) {
    return <Navigate to="/" />;
  }

  // If only providerId exists, block access to user routes
  if (allowedFor === 'user' && !userId) {
    return <Navigate to="/provider-homepage" />;
  }

  // If `lastLogin` is set, prioritize it for redirection
  if (lastLogin === 'provider' && allowedFor === 'user') {
    return <Navigate to="/provider-homepage" />;
  }
  if (lastLogin === 'user' && allowedFor === 'provider') {
    return <Navigate to="/" />;
  }

  // Allow access to the route
  return children;
};

function App() {
  const [currentAddress, setCurrentAddress] = useState('');

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          const address = data.address;
          let currentAddress = '';
          if (address?.town && address.house_number) {
            currentAddress = `${address.house_number}, ${address.town}, ${address.state}, ${address.country}`;
          } else if (address?.state_district && address?.suburb) {
            currentAddress = `${address.suburb}, ${address.state}, ${address.country}`;
          } else if (address?.state_district && address?.city) {
            currentAddress = `${address.city}, ${address.state}`;
          } else if (address?.city && address?.country) {
            currentAddress = `${address.city}, ${address.country}`;
          }
          setCurrentAddress(currentAddress);
        })
        .catch((error) => console.error('Error fetching location:', error));
    });
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/promo" element={<Promo />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<HandleRegister />} />
        <Route path="/login/forgot-password" element={<ForgotPassword />} />
        <Route path="/login/reset-password" element={<ResetPassword />} />
        <Route path="/provider-register" element={<ProviderRegister />} />
        <Route path="/provider-login" element={<ProviderLogin />} />
        <Route path="/otp-verification" element={<ProviderVerifyOTP />} />
        <Route path='/provider/reset-password' element={<ProviderResetPassword/>}/>

        {/* User Routes */}
        {/* <Route
          path="/"
          element={
            <PrivateRoute allowedFor="user">
              <GoogleMapComponent currentAddress={currentAddress} />
            </PrivateRoute>
          }
        /> */}


<Route
          path="/"
          element={
            <PrivateRoute allowedFor="user">
              <Home currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cart"
          element={
            <PrivateRoute allowedFor="user">
              <AddCart currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />

     <Route
          path="/user-profile"
          element={
            <PrivateRoute allowedFor="user">
              <UserProfile currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />

<Route
          path="/userOrder-History"
          element={
            <PrivateRoute allowedFor="user">
              <UserOrderHistory currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />

<Route
          path="/saved-Addresses"
          element={
            <PrivateRoute allowedFor="user">
              <SavedAddress currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />
        <Route
          path="/service-provider/:cat_id"
          element={
            <PrivateRoute allowedFor="user">
              <ServiceProvider currentAddress={currentAddress} />
            </PrivateRoute>
          }
        />

        {/* Provider Routes */}
        <Route
          element={
            <PrivateRoute allowedFor="provider">
              <ProviderLayout />
            </PrivateRoute>
          }
        >
          <Route path="/provider-homepage" element={<ProviderDashboard />} />
          <Route path="/add-employee" element={<AddEmployee />} />
          <Route path="/employee-list" element={<EmployeList />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/provider-order-history" element={<OrderHistory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


