import React, { use, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { addSubCatProvider, deleteCategory, deleteSubCategory, getCategoryToLeftAdd, getProviderServideDetails, getSubCatList } from '../../Service/provider-api-path';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import { Button } from 'flowbite-react';
import { Plus } from 'lucide-react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Snackbar } from "@mui/material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ServiceDetails = () => {
    const [names, setnames] = useState([]);
    const [names1, setnames1] = useState([]);
    const [names2, setnames2] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const location = useLocation();
    const provider_id = Cookies.get('providerid');
    const [changeState, setChangeState] = useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingComponent, setLoadingComponent] = useState(false);
    const [personName1, setPersonName1] = React.useState([]);
    const [personName2, setPersonName2] = React.useState([]);

    const handleChangeCat = (event) => {
        setPersonName2([]);
        const {
            target: { value },
        } = event;
        setPersonName1(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeSubCat = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName2(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const fetchServiceDetails = async () => {

        if (location.pathname === '/service-details') {
            setLoadingComponent(true);
            try {
                const response = await axios.get(`${getProviderServideDetails}?provider_id=${provider_id}`);
                console.log('response: ', response.data);
                const transformedData = response.data.result.reduce((acc, item) => {
                    if (!acc[item.cat_id]) {
                        acc[item.cat_id] = {
                            masterId: item.masterId,
                            categoryname: item.categoryname,
                            cat_id: item.cat_id,
                            subcategories: []
                        };
                    }
                    acc[item.cat_id].subcategories.push({
                        sub_cat_id: item.sub_cat_id,
                        subcategoryname: item.subcategoryname
                    });

                    return acc;
                }, {});
                console.log(transformedData)
                const accordionData = Object.values(transformedData);
                setServiceData(accordionData);
                setLoadingComponent(false);
                console.log(accordionData);
            } catch (error) {
                setLoadingComponent(false);
                console.error('Error fetching service details:', error);
            }
        }
    };

    const fetchLeftCategory = async () => {
        try {
            const response = await axios.get(`${getCategoryToLeftAdd}?provider_id=${provider_id}`);
            console.log('response: ', response);
            const categories = response.data.result.map(item => ({
                label: item.category_name,
                value: `${item.category_name}-${item.cat_id}`,
                master_id: item.master_id,
                cat_id: item.cat_id
            }));

            setnames1(categories);
        } catch (error) {
            console.error('Error fetching categories: ', error);
        }

    }

    useEffect(() => {
        fetchServiceDetails();
        fetchLeftCategory();
    }, [location.pathname, provider_id, changeState]);

    const handeleGetSubCategoryforCat = async (category, cat_id) => {
        console.log('cat_id: ', cat_id);
        console.log('category: ', category.cat_id);
        try {
            const response = await axios.get(`${getSubCatList}?cat_id=${category.cat_id || cat_id}`);
            console.log('response: ', response.data.result);
            const existingSubCatIds = category.subcategories.map((sub) => sub.sub_cat_id);
            const subcategories = response.data.result.filter(
                (item) => !existingSubCatIds.includes(item.sub_cat_id)
            );

            if (subcategories.length === 0) {
                setnames([{ sub_cat_id: "none", sub_cat_name: "No Other Subcategories Available" }]);
            }
            else {
                setnames(subcategories);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handelGetSubCat = async (category) => {
        console.log('category: ', category);
        try {
            const response = await axios.get(`${getSubCatList}?cat_id=${category.cat_id}`);
            console.log('response: ', response.data.result);
            const SubCats = response.data.result.map((sub) => {
                return {
                    masterId: sub.masterId,
                    sub_cat_id: sub.sub_cat_id,
                    sub_cat_name: sub.sub_cat_name
                };
            });
            console.log('SubCats: ', SubCats);
            setnames2(SubCats);
        }
        catch (error) {
            console.error('Error fetching subcategories:', error
            );
        }
    }

    const handleAdd = async (category) => {
        console.log('category: ', category);
        setLoading(true);
        const selectedSubcategories = names
            .filter((item) => personName.includes(item.sub_cat_name))
            .map((item) => item.sub_cat_id);

        console.log('selectedSubcategories: ', selectedSubcategories);
        console.log('category: ', category.cat_id);
        console.log(provider_id)
        if (names.length === 1 && names[0].sub_cat_id === "none") {
            alert("No subcategories available to add");
            setLoading(false);
            return;
        }
        if (selectedSubcategories.length === 0) {
            alert('Please select at least one subcategory to add');
            setLoading(false);
            return;
        }
        const payload = {
            provider_id,
            cat_id: category.cat_id,
            sub_cat_id: selectedSubcategories
        };
        console.log('payload: ', payload);

        try {
            const repsonse = await axios.post(`${addSubCatProvider}`, payload);
            console.log('repsonse: ', repsonse);
            if (repsonse.status === 200) {
                setMessage(repsonse.data.message);
                handleClick();
                setChangeState(!changeState);
                handeleGetSubCategoryforCat(category);
                setTimeout(() => {
                    setPersonName([]);
                    setLoading(false);
                }, 2000);

            }
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const handleDelete = async (cat_id, sub_cat_id) => {
        const provider_id = Cookies.get('providerid');
        console.log(` fprvider ${provider_id} Deleting subcategory ${sub_cat_id} in category ${cat_id}`);
        try {
            const response = await axios.delete(`${deleteSubCategory}?provider_id=${provider_id}&cat_id=${cat_id}&sub_cat_id=${sub_cat_id}`)
            if (response.status === 200) {
                handeleGetSubCategoryforCat({ cat_id });
                setChangeState(!changeState);
                setMessage(response.data.message);
                handleClick();
            }
        } catch (error) {
            console.error('Error deleting subcategory:', error);
        }
    };

    const handleAddCategory = async () => {
        setLoading(true);
        // console.log(personName1, personName2);
        const selectedCategory = names1.find(name => name.value === personName1[0]);
        if (!selectedCategory) {
            alert('Please select a category');
            setLoading(false);
            return;
        }

        const selectedSubcategories = names2
            .filter((item) => personName2.includes(item.sub_cat_name))
            .map((item) => item.sub_cat_id);

        if (selectedSubcategories.length === 0) {
            alert('Please select at least one subcategory');
            setLoading(false);
            return;
        }

        const payload = {
            provider_id,
            cat_id: selectedCategory.cat_id,
            sub_cat_id: selectedSubcategories,
            masterId: selectedCategory.master_id
        };
        console.log('payload: ', payload);
        try {
            const response = await axios.post(`${addSubCatProvider}`, payload);
            console.log('response: ', response);
            if (response.status === 200) {
                setLoading(false);
                setMessage(response.data.message);
                handleClick();
                setChangeState(!changeState);
                setTimeout(() => {
                    setPersonName1([]);
                    setPersonName2([]);
                }, 2000);
            }
        } catch (error) {
            setLoading(false);
            const errorMessage = error.response?.data?.message || 'Error adding category'
            setMessage(errorMessage);
            handleClick();
            console.error('Error adding category:', error);
        }
    }

    const handelDeleteCategory = async (cat_id, master_id) => {
        console.log('master_id: ', master_id);
        console.log('cat_id: ', cat_id);
        try {
            const response = await axios.delete(`${deleteCategory}?provider_id=${provider_id}&cat_id=${cat_id}&master_id=${master_id}`);
            console.log('response: ', response);
            if (response.status === 200) {
                setMessage(response.data.message);
                handleClick();
                setChangeState(!changeState);
            }
        } catch (error) {
            console.error('Error deleting category:', error);
            setMessage('Error deleting category');
            handleClick();
        }
    }

    return (
        <div className="bg-gray-50 flex justify-center min-h-screen py-10">
            <div className="p-4 sm:ml-64 mt-10 max-w-3xl w-full">
                <div className="p-6 rounded-lg space-y-2 dark:border-gray-700 mt-10 bg-white shadow-xl">
                    <Typography variant="h5" className="text-center mb-10 font-semibold text-gray-800">
                        {provider_id ? 'Edit Your Service Details' : 'You are not logged in'}
                    </Typography>
                    <div className='flex justify-center items-center'>
                        <div>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-single-checkbox-label">Add</InputLabel>
                                <Select
                                    labelId="demo-single-checkbox-label"
                                    id="demo-single-checkbox"
                                    value={personName1}
                                    onChange={handleChangeCat}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected || ''}
                                    MenuProps={MenuProps}
                                >
                                    {names1?.map((name1) => (
                                        <MenuItem key={name1.value} value={name1.value}>
                                            <ListItemText onClick={() => handelGetSubCat(name1)} primary={name1.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div>
                            <FormControl sx={{ m: 1, width: 200 }}>
                                <InputLabel id="demo-multiple-checkbox-label" >Add</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={personName2}
                                    onChange={handleChangeSubCat}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {names2?.map((name2) => (
                                        <MenuItem key={name2.sub_cat_id} value={name2.sub_cat_name}>
                                            <Checkbox checked={personName2.includes(name2.sub_cat_name)} />
                                            <ListItemText primary={name2.sub_cat_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <Stack direction="row" spacing={2} className='bg-blue-500 p-2 rounded-lg'>
                                <Button variant="contained" onClick={handleAddCategory}>{loading ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress size={24} />
                                    </Box>
                                ) : (
                                    "ADD Category"
                                )}
                                </Button>
                            </Stack>
                        </div>
                    </div>

                    <div>
                        {loadingComponent ? (<div className="flex justify-center items-center h-[50%]">
                            <CircularProgress />
                        </div>) : (serviceData?.map((category) => (
                            <div className='flex items-center justify-center'>
                                <IconButton
                                    onClick={() => handelDeleteCategory(category.cat_id, category.masterId)}
                                    color="error"
                                    size="small"
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <Accordion sx={{ width: "100%" }} onClick={() => handeleGetSubCategoryforCat(category)} key={category.cat_id} className="mb-4 rounded-lg border border-gray-200 shadow-sm">
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls={`panel-${category.cat_id}-content`}
                                        id={`panel-${category?.cat_id}-header`}
                                    >

                                        <div className="flex justify-between w-full items-center">
                                            <Typography component="span" className="font-bold font-mono text-gray-900">
                                                {category?.categoryname}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails className='md:mt-[-30px]'>
                                        <Typography component="div">
                                            <ul className="list-disc ml-6 space-y-2">
                                                <div className='flex justify-end items-center'>
                                                    <div>
                                                        <FormControl sx={{ m: 1, width: 200 }}>
                                                            <InputLabel
                                                                id="demo-multiple-checkbox-label">Add</InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-checkbox-label"
                                                                id="demo-multiple-checkbox"
                                                                multiple
                                                                value={personName}
                                                                onChange={handleChange}
                                                                input={<OutlinedInput label="Tag" />}
                                                                renderValue={(selected) => selected.join(', ')}
                                                            >
                                                                {names?.map((name) => (
                                                                    <MenuItem key={name.sub_cat_id} value={name.sub_cat_name}>
                                                                        <Checkbox checked={personName.includes(name.sub_cat_name)} />
                                                                        <ListItemText primary={name.sub_cat_name} />
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                    </div>
                                                    <div>
                                                        <Stack direction="row" spacing={2} className='bg-blue-500 p-2 rounded-lg'>
                                                            <Button onClick={() => handleAdd(category)} variant="contained">{loading ? (
                                                                <Box sx={{ display: 'flex' }}>
                                                                    <CircularProgress size={24} />
                                                                </Box>
                                                            ) : (
                                                                "ADD"
                                                            )}
                                                            </Button>
                                                        </Stack>
                                                    </div>
                                                </div>

                                                {category?.subcategories?.map((subcategory) => (
                                                    <li
                                                        key={subcategory.sub_cat_id}
                                                        className="flex justify-between items-center border p-2 cursor-pointer hover:bg-gray-100 rounded-lg transition ease-in-out delay-150 bg-gray-200 hover:-translate-y-1 hover:scale-130 duration-300"
                                                    >
                                                        <span className="text-gray-600">
                                                            {subcategory.subcategoryname}
                                                        </span>
                                                        <IconButton
                                                            onClick={() => handleDelete(category.cat_id, subcategory.sub_cat_id)}
                                                            color="error"
                                                            size="small"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                        ))}

                    </div>
                </div>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
            // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </div>
    );
};

export default ServiceDetails;
