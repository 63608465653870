// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import Cookies from 'js-cookie';
// import { getCurrentCategory, getCurrentMasterCategory, getSubCurrentCategory, getSubProviderListTable, deleteSubProvider } from "../../Service/provider-api-path";
// import axios from "axios";
// import EmployeTable from "./EmployeTable";
// import { Snackbar } from "@mui/material";

// const EmployeList = () => {
//     const local = useLocation();
//     const [open, setOpen] = useState(false);
//     const [message, setMessage] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [matserName, setMasterName] = useState([]);
//     const [category, setCategory] = useState([]);
//     const [subCategory, setSubCategory] = useState([]);
//     const [location, setLocation] = useState("");
//     const [employees, setEmployees] = useState([]);
//     const provider_id = Cookies.get('providerid');

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpen(false);
//     };
//     const handleClick = () => {
//         setOpen(true);
//     };

//     const handelGetMasterCategory = async () => {
//         try {
//             const response = await axios.get(`${getCurrentMasterCategory}?provider_id=${provider_id}`);
//             setMasterName(response.data.result);
//         } catch (error) {
//             console.log('error: ', error);
//         }
//     };

//     const handleDelete = async (subprovider) => {
//         setLoading(true);
//         try {
//             const response = await axios.delete(`${deleteSubProvider}?sub_provider_id=${subprovider.sub_providerId}`);
//             if (response.data) {
//                 setEmployees((prev) => prev.filter((emp) => emp.sub_providerId !== subprovider.sub_providerId));
//                 setMessage(response.data.message);
//                 handleClick();
//                 setLoading(false);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setLoading(false);
//             const errorMessage = error.response?.data?.message || 'Error While Deleting Employee'
//             setMessage(errorMessage);
//             handleClick();
//         }
//     };

//     useEffect(() => {
//         handelGetMasterCategory();
//     }, [local.pathname]);

//     const handelChangeMaster = async (selectedMasterName) => {
//         const selectedMaster = matserName.find(item => item.masterName === selectedMasterName);
//         if (selectedMaster) {
//             const response = await axios.get(`${getCurrentCategory}?provider_id=${provider_id}&masterId=${selectedMaster.masterId}`);
//             setCategory(response.data.result);
//             setSubCategory([]);
//             setLocation("");
//         }
//     };

//     const handelgetSubCategory = async (selectedValue) => {
//         try {
//             const { master_id, cat_id } = JSON.parse(selectedValue);
//             const response = await axios.get(`${getSubCurrentCategory}?provider_id=${provider_id}&masterId=${master_id}&catId=${cat_id}`);
//             setSubCategory(response.data.result);
//             setLocation("");
//             setLoading(false);
//         } catch (error) {
//             console.error('Error parsing JSON:', error);
//         }
//     };

//     const handleSubmit = async () => {
//         setLoading(true);
//         if (!category.length || !subCategory.length || !location) {
//             alert('Please select MasterCategory, Category, and SubCategory!');
//             return;
//         }
//         try {
//             const { providerId, masterId, cat_id, sub_cat_id } = JSON.parse(location);
//             const response = await axios.get(`${getSubProviderListTable}?provider_id=${providerId}&masterId=${masterId}&catId=${cat_id}&subCatId=${sub_cat_id}`);
//             setEmployees(response.data.result);
           
//             setLoading(false);
//         } catch (error) {
//             setLoading(false);
//             setEmployees([]);
//             console.error('Error:', error);
//         }
//     };

//     return (
//         <div className="bg-gray-100 flex justify-center h-screen">
//             <div className="p-4 sm:ml-64 mt-12 w-full">
//                 <div className="p-4 rounded-lg bg-white shadow-md w-full">
//                     <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
//                         <select
//                             className="border rounded p-2 w-full"
//                             onChange={(e) => handelChangeMaster(e.target.value)}
//                         >
//                             <option value="">Select MasterCategory</option>
//                             {matserName?.map((item, index) => (
//                                 <option key={index} value={item.masterName}>{item.masterName}</option>
//                             ))}
//                         </select>
//                         <select
//                             className="border rounded p-2 w-full"
//                             onChange={(e) => handelgetSubCategory(e.target.value)}
//                         >
//                             <option value="">Select Category</option>
//                             {category?.map((item, index) => (
//                                 <option key={index} value={JSON.stringify({ master_id: item.master_id, cat_id: item.cat_id })}>
//                                     {item.cat_name}
//                                 </option>
//                             ))}
//                         </select>
//                         <select
//                             className="border rounded p-2 w-full"
//                             value={location}
//                             onChange={(e) => setLocation(e.target.value)}
//                         >
//                             <option value="">Select SubCategory</option>
//                             {subCategory?.map((item, index) => (
//                                 <option key={index} value={JSON.stringify({ providerId: item.providerId, masterId: item.masterId, cat_id: item.cat_id, sub_cat_id: item.sub_cat_id })}>{item.sub_cat_name}</option>
//                             ))}
//                         </select>
//                     </div>
//                     <div className="text-center mt-4">
//                         <button onClick={handleSubmit}
//                             className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
//                         >
//                             {loading ? 'Loading...' : 'Submit'}
//                         </button>
//                     </div>
//                 </div>

//                 {/* Table Section */}
//                 <EmployeTable employees={employees} loading={loading} handleDelete={handleDelete} />
//             </div>
//             <Snackbar
//                 open={open}
//                 autoHideDuration={3000}
//                 onClose={handleClose}
//                 message={message}
//             // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//             />
//         </div>
//     );
// };

// export default EmployeList;


// ==============================================================



import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { getCurrentCategory, getCurrentMasterCategory, getSubCurrentCategory, getSubProviderListTable, deleteSubProvider } from "../../Service/provider-api-path";
import axios from "axios";
import EmployeTable from "./EmployeTable";
import { Snackbar } from "@mui/material";

const EmployeList = () => {
    const local = useLocation();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [matserName, setMasterName] = useState([]);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [location, setLocation] = useState("");
    const [employees, setEmployees] = useState([]);
    const provider_id = Cookies.get('providerid');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    };

    const handelGetMasterCategory = async () => {
        try {
            const response = await axios.get(`${getCurrentMasterCategory}?provider_id=${provider_id}`);
            setMasterName(response.data.result);

            // Automatically call getSubProviderListTable with the first masterId
            if (response.data.result?.length > 0) {
                const firstMasterId = response.data.result[0].masterId;
                await fetchSubProviderListTable(firstMasterId);
            }
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const fetchSubProviderListTable = async (masterId, catId = "", subCatId = "") => {
        try {
            setLoading(true);
            const response = await axios.get(`${getSubProviderListTable}?provider_id=${provider_id}&masterId=${masterId}&catId=${catId}&subCatId=${subCatId}`);
            setEmployees(response.data.result || []);
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setEmployees([]);
            setLoading(false);
        }
    };

    const handleDelete = async (subprovider) => {
        setLoading(true);
        try {
            const response = await axios.delete(`${deleteSubProvider}?sub_provider_id=${subprovider.sub_providerId}`);
            if (response.data) {
                setEmployees((prev) => prev.filter((emp) => emp.sub_providerId !== subprovider.sub_providerId));
                setMessage(response.data.message);
                handleClick();
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            const errorMessage = error.response?.data?.message || 'Error While Deleting Employee';
            setMessage(errorMessage);
            handleClick();
        }
    };

    useEffect(() => {
        handelGetMasterCategory();
    }, [local.pathname]);

    const handelChangeMaster = async (selectedMasterName) => {
        const selectedMaster = matserName.find(item => item.masterName === selectedMasterName);
        if (selectedMaster) {
            const response = await axios.get(`${getCurrentCategory}?provider_id=${provider_id}&masterId=${selectedMaster.masterId}`);
            setCategory(response.data.result);
            setSubCategory([]);
            setLocation("");

            // Fetch sub-provider list for the selected master category
            await fetchSubProviderListTable(selectedMaster.masterId);
        }
    };

    const handelgetSubCategory = async (selectedValue) => {
        try {
            const { master_id, cat_id } = JSON.parse(selectedValue);
            const response = await axios.get(`${getSubCurrentCategory}?provider_id=${provider_id}&masterId=${master_id}&catId=${cat_id}`);
            setSubCategory(response.data.result);
            setLocation("");
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            const parsedLocation = location ? JSON.parse(location) : {};
            const masterId = parsedLocation.masterId || matserName[0]?.masterId;
            const catId = parsedLocation.cat_id || "";
            const subCatId = parsedLocation.sub_cat_id || "";
    
            // Call the API with available parameters
            await fetchSubProviderListTable(masterId, catId, subCatId);
        } catch (error) {
            console.error("Error in handleSubmit:", error);
        }
    };
    

    return (
        <div className="bg-gray-100 flex justify-center h-screen">
            <div className="p-4 sm:ml-64 mt-12 w-full">
                <div className="p-4 rounded-lg bg-white shadow-md w-full">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <select
                            className="border rounded p-2 w-full"
                            onChange={(e) => handelChangeMaster(e.target.value)}
                        >
                            <option value="">Select MasterCategory</option>
                            {matserName?.map((item, index) => (
                                <option key={index} value={item.masterName}>{item.masterName}</option>
                            ))}
                        </select>
                        <select
                            className="border rounded p-2 w-full"
                            onChange={(e) => handelgetSubCategory(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {category?.map((item, index) => (
                                <option key={index} value={JSON.stringify({ master_id: item.master_id, cat_id: item.cat_id })}>
                                    {item.cat_name}
                                </option>
                            ))}
                        </select>
                        <select
                            className="border rounded p-2 w-full"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        >
                            <option value="">Select SubCategory</option>
                            {subCategory?.map((item, index) => (
                                <option key={index} value={JSON.stringify({ providerId: item.providerId, masterId: item.masterId, cat_id: item.cat_id, sub_cat_id: item.sub_cat_id })}>{item.sub_cat_name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="text-center mt-4">
                        <button onClick={handleSubmit}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            {loading ? 'Loading...' : 'Submit'}
                        </button>
                    </div>
                </div>

                {/* Table Section */}
                <EmployeTable employees={employees} loading={loading} handleDelete={handleDelete} />
            </div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
            />
        </div>
    );
};

export default EmployeList;