import React from 'react'
import Register from './Register'

const HandleRegister = ({currentAddress}) => {
  return (
    <>
    <div>
      <Register currentAddress={currentAddress} />
    </div>
    </>
  )
}

export default HandleRegister