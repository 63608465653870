


import React, { useState, useRef, useEffect } from "react";
import { FaGoogle, FaFacebook, FaApple, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { googleLogin, registerApi, verifyEmailOTP, verifyPhoneOTP,API_URL} from "../../Service/api-path";
import { CircularProgress, Snackbar } from "@mui/material";
import { RefreshCw } from "lucide-react";
import Navbar from "../Navbar";
import HeaderBanner from "../Home/HeaderBanner/HeaderBanner";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { FaUserPlus } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { FaLocationArrow } from "react-icons/fa";
import Modal from "react-modal";
Modal.setAppElement("#root");


const Register = ({ currentAddress }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isPhone, setIsPhone] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [Address,setAddress]=useState("")
  
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  console.log(Address,"=adress")
  
  
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false); // Disable email after signup
  const [isOtpStage, setIsOtpStage] = useState(false); // Handle OTP stage
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);
  const [showLogin, setShowLogin] = useState(false);
  const [profile, setProfile] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  console.log(profilePic,"====pic=====")

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const handleMapOpen = () => setIsOpen(true);
  const handleMapClose = () => setIsOpen(false);
  const handleMapLoad = (map) => setMap(map);

  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places?.length > 0) {
        const { geometry, formatted_address } = places[0];
        if (geometry && geometry.location) {
          const position = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          setMarkerPosition(position);
          setLatitude(position.lat);
          setLongitude(position.lng);
          setAddress(formatted_address);
        }
      }
    }
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const address = await reverseGeocode(latitude, longitude);
          setAddress(address);
          setLatitude(latitude);
          setLongitude(longitude);
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        (error) => console.error(error.message)
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.results?.[0]?.formatted_address || "";
    } catch (error) {
      console.error("Error reverse geocoding:", error);
      return "";
    }
  };
  
const handleGoogleLoginSuccess = async (credentialResponse) => {
  try {
      const { credential } = credentialResponse;
      const res = await axios.get(
          `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${credential}`
      );

      console.log("Google Profile:", res.data);
      setProfile(res.data);

      const { email, name, picture } = res.data;
      let profilePic = picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(name || email[0])}&background=random`;

      // Check if user already exists
      const checkUserResponse = await axios.post(`${API_URL}/api/user-auth/google-login`, {
          email,
      });

      console.log("Check User Response:", checkUserResponse.data);

      if (checkUserResponse.data.userExists && checkUserResponse.data.userExists.length > 0) {
          const user_id = checkUserResponse.data.userExists[0].id;
    console.log(user_id,"===userid====")
          // console.log("User already exists. Redirecting to home...");
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("profilePic", profilePic);
          localStorage.setItem("token", credential); // ✅ Store Google Token


          navigate("/");
          return;
      }

      // If user does not exist, save to database
      const saveResponse = await axios.post(`${API_URL}/api/user-auth/google-auth`, {
          email,
          name,
      });

      console.log("Save Response:", saveResponse.data);

      if (saveResponse.data.success) {
          console.log("User saved successfully. Fetching user ID...");

          // Fetch user ID after saving
          const newUserResponse = await axios.post(`${API_URL}/api/user-auth/google-login`, {
              email,
          });

          console.log("New User Response:", newUserResponse.data);

          if (newUserResponse.data.userExists && newUserResponse.data.userExists.length > 0) {
              const userId = newUserResponse.data.userExists[0].id;

              // Store in localStorage
              localStorage.setItem("user_id", userId);
              localStorage.setItem("email", email);
              localStorage.setItem("name", name);
              localStorage.setItem("profilePic", profilePic);
              localStorage.setItem("token", credential); // ✅ Store Google Token Here


              navigate("/");
          }
      }
  } catch (err) {
      if (err.response) {
          console.error("Server Error:", err.response.status, err.response.data);
      } else {
          console.error("Network/Request Error:", err.message);
      }
  }
};



const handleFileChange = (e) => {
    const file = e.target.files[0];  // Get the first file selected by the user
    setProfilePic(file);  // Update the state with the selected file
  };
  

  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") {
      console.log("User cancelled login or did not fully authorize.");
    } else {
      console.log("Facebook Login Successful!");
      console.log("User Details:", response);

      const userData = {
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
      };

      console.log("User Data:", userData);
    }
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor
      (Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = '';
    for (let i = 0; i < 5; i++) {
      captcha += generateRandomChar(48, 57); // Generate a random digit (ASCII 48-57)
    }
    return captcha;
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = '20px Roboto Mono';
      ctx.fillStyle = textColors[Math.floor(
        Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput('');
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };




const handleSignup = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  // Validate captcha
  if (!userInput) {
    setIsLoading(false);
    alert("Please enter captcha");
    return;
  }

  if (userInput !== captchaText) {
    setIsLoading(false);
    alert("Incorrect captcha. Please try again.");
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
    return;
  }
  setIsCaptchaVerified(true);

  // Prepare form-data
  const formData = new FormData();
  formData.append("name", name);
  formData.append("email", !isPhone ? inputValue : ""); // Conditionally add email
  formData.append("phone", isPhone ? inputValue : ""); // Conditionally add phone
  formData.append("password", !isPhone ? password : ""); // Only if email signup
  formData.append("provider", isPhone ? "phone" : "email"); // Dynamic provider
  formData.append("Address", Address);
  formData.append("latitude", latitude);
  formData.append("longitude", longitude);
  if (profilePic) formData.append("profilePic", profilePic); // Append profile picture to formData
    

 

  try {
    // Send POST request with form-data
    const response = await axios.post(`${API_URL}/api/user-auth/register`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    console.log(response.data);
    if (response.status === 201) {
      setIsLoading(false);
      setEmailDisabled(true); // Disable email input
      setIsOtpStage(true); // Show OTP input field
      setMessage(response.data.msg);
      handleClick();
    }
  } catch (error) {
    setIsLoading(false);
    console.error("Registration error:", error);
    setMessage(error.response?.data?.msg || "Registration failed");
    handleClick();
  } finally {
    setIsLoading(false);
  }
};


  const handleOtpVerification = async () => {
    console.log(otp, inputValue)
    console.log(isPhone);
    try {
      const endpoint = isPhone ? verifyPhoneOTP : verifyEmailOTP;
      // console.log('endpoint: ', endpoint);
      const payload = {
        otp,
        email: !isPhone ? inputValue : undefined,
        phone: isPhone ? inputValue : undefined,
      };
      console.log('payload: ', payload);
      const response = await endpoint(payload);
      console.log('response: ', response);
      setMessage(response.data.msg);
      handleClick();
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (error) {
      console.error("Verification error:", error.response?.data || error.message);
      setMessage(error.response?.data?.msg || "Invalid OTP. Please try again.");
      handleClick();
      // alert("Invalid OTP. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log('value: ', value);
    setInputValue(value);

    // Check if input is a phone number
    const phoneRegex = /^\d{10}$/;
    setIsPhone(phoneRegex.test(value));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (value.length >= 8 && /[A-Z]/.test(value) && /\d/.test(value)) {
      setPasswordStrength("strong");
    } else if (value.length >= 6) {
      setPasswordStrength("medium");
    } else {
      setPasswordStrength("weak");
    }
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    console.log('value: ', value);
    setAddress(value);
  }


  const socialLogin = (provider) => {
    console.log(`${provider} login clicked`);
    // Implement social login logic here
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <>
      <Navbar currentAddress={currentAddress} />
      <HeaderBanner />

      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-2 max-[450px]:p-4">
        <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md border-[0.2px] border-black">
          <h2 className="text-2xl font-bold text-center mb-6"> Sign Up
          </h2>

          {!isOtpStage && (
            <form onSubmit={handleSignup} encType="multipart/form-data">
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="emailPhone" className="block text-gray-700">
                  Email
                </label>
                <input
                  id="emailPhone"
                  type="text"
                  placeholder="Email"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={emailDisabled} // Disable email input after signup
                  required
                />
              </div>

              {!isPhone && (
                <div className="mb-4">
                  <label htmlFor="password" className="block text-gray-700">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={handlePasswordChange}
                      className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                    >
                      {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                    </button>
                  </div>
                  <div
                    className={`mt-2 h-2 rounded-full ${passwordStrength === "strong"
                      ? "bg-green-500"
                      : passwordStrength === "medium"
                        ? "bg-yellow-500"
                        : "bg-red-500"
                      }`}
                    style={{
                      width:
                        passwordStrength === "strong"
                          ? "100%"
                          : passwordStrength === "medium"
                            ? "70%"
                            : "40%",
                    }}
                  />
                  <span className="text-sm text-gray-500">
                    {passwordStrength && `Password is ${passwordStrength}`}
                  </span>

                  
                
                  <div className="my-2 flex items-center gap-3 mt-6">
            <label className="text-gray-700">Address</label>
            <button
              type="button" 
              onClick={handleMapOpen}
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
              Search Location
            </button>
          </div>

          {/* Address Input Field */}
          <input
            type="text"
            placeholder="Your Address"
            value={Address}
            readOnly
            className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none"
          />


<Modal isOpen={isOpen} onRequestClose={handleMapClose} className=" mt-10 modal top-60" overlayClassName="overlay">
        <h3 className="text-lg font-bold text-center border-b-2 pb-2">Search for a location</h3>

        {/* Search Box */}
        <StandaloneSearchBox onLoad={(ref) => setSearchBox(ref)} onPlacesChanged={handlePlacesChanged}>
          <input type="text" placeholder="Search for a location" className="w-full p-2 border rounded-md mt-3" />
        </StandaloneSearchBox>

        {/* Map Preview */}
        <div className="flex justify-center items-center bg-gray-200 mt-3" style={{ height: "150px" }}>
          {markerPosition ? (
            <GoogleMap
              center={markerPosition}
              zoom={12}
              mapContainerStyle={{ width: "100%", height: "150px" }}
            >
              {markerPosition && <Marker position={markerPosition} />}
            </GoogleMap>
          ) : (
            <div className="text-gray-500">Map Preview</div>
          )}
        </div>

        {/* Current Location Button */}
        <button
          className="bg-blue-500 text-white flex items-center gap-2 px-4 py-2 rounded-md mt-4"
          onClick={getCurrentLocation}
        >
          <FaLocationArrow /> Current Location
        </button>

        {/* Address Display Field */}
        <textarea
          value={Address}
          placeholder="Your Address..."
          readOnly
          className="w-full mt-3 p-2 border rounded-md"
        ></textarea>

        {/* Save Button */}
        <div className="flex justify-center mt-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={handleMapClose}>
            Save
          </button>
        </div>
      </Modal>


                  <div className="mb-4 mt-4">
      <label htmlFor="profilePic" className="block text-gray-700">
        Profile Picture
      </label>
      <input
        id="profilePic"
        type="file"
        accept="image/*"
        onChange={handleFileChange} // Update state with selected file
        className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none"
        required
      />
    </div> 


                   

                </div>
              )}

              <div className="">
                <div className="container border-gray-400 border mb-1">
                  <div className="wrapper">
                    <div className="flex justify-between items-center gap-2">
                      <canvas ref={canvasRef}
                        width="200"
                        height="70"
                        className="w-[40%] max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg  "
                      >

                      </canvas>
                      <RefreshCw className="cursor-pointer" size={16} onClick={
                        () => initializeCaptcha(
                          canvasRef.current.getContext('2d'))} />
                      <input
                        type="text"
                        id="user-input"
                        className="w-[30%] p-1 mr-1"
                        placeholder="Enter Captcha"
                        value={userInput}
                        onChange={handleUserInputChange} />
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg text-lg mb-6"
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
              </button>
            </form>
          )}

          {isOtpStage && (
            <div className="mb-4">
              <p className="text-gray-700 mb-2">
                Verification email sent to: <b>{inputValue}</b>
              </p>
              <label htmlFor="otp" className="block text-gray-700">
                Enter OTP
              </label>
              <input
                id="otp"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button
                onClick={handleOtpVerification}
                className="w-full bg-green-500 hover:bg-green-600 text-white py-2 rounded-lg mt-2"
              >
                Verify OTP
              </button>
            </div>
          )}

          {/* Social Media Options */}
          <div className="text-center text-gray-600 mb-4">Or sign up with</div>
          {/* <div className="flex justify-around mb-6"> */}
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-6">

           

        <GoogleLogin
        onSuccess={handleGoogleLoginSuccess}
        onError={() => alert("Google Login Failed!")}
        // onError={handleGoogleLoginFailure}
      />

            {/* {!showLogin && (
              <button
                onClick={handleLoginClick}
                className="bg-blue-600 text-white font-semibold py-2 px-4 rounded flex items-center justify-center space-x-2 shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
              >
                {/* Add the Facebook icon */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M22.675 0H1.325C.593 0 0 .594 0 1.326v21.348C0 23.407.593 24 1.325 24h11.49v-9.294H9.838v-3.622h2.977V8.413c0-2.949 1.796-4.556 4.415-4.556 1.252 0 2.33.093 2.644.135v3.067h-1.814c-1.426 0-1.701.678-1.701 1.67v2.188h3.406l-.445 3.622h-2.961V24h5.803c.73 0 1.324-.593 1.324-1.326V1.326C24 .594 23.407 0 22.675 0z" />
                </svg>
                <span>Facebook</span>
              </button>
            )} */} 

            {/* Show the FacebookLogin component only when triggered */}
            {/* {showLogin && (
              <FacebookLogin
                appId="1998249413935804"
                autoLoad={true}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="my-facebook-button-class"
                onFailure={(error) => console.error("Login failed", error)}
                icon="fa-facebook"
              />

            )} */}
          </div>

          <div className="text-center">
            <Link to="/login" className="text-blue-500 underline hover:text-blue-700">
              Already registered? Log in
            </Link>
          </div>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </div>
    </>
  );
};

export default Register;




