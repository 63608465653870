// import React, { useState, useEffect, useContext } from 'react'
// import logo from '../assets/oys_logo.png'

// import { CgProfile } from "react-icons/cg";
// import Cookies from 'js-cookie'
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import io from 'socket.io-client';
// import { getMasterCategory, getSubCategory, editproviderDetails, subcategoryservice, providerAuthRegister, getServiceEditData, getDataToEdit } from '../Service/provider-api-path'
// import { cleanAvailableTime, cleanDescription, formatToYYYYMMDD } from '../utils/Helpers.js'
// import '../pages/ProviderNavbar.css'
// import { NavLink } from "react-router-dom";
// import { SocketContext } from '../context/SocketContext.js';

// const Navbar = () => {
//     const { socketForProvider, createSocketConnectionForProvider } = useContext(SocketContext);
//     useEffect(() => {
//         if (!socketForProvider) {
//             createSocketConnectionForProvider();
//         }
//     }, [socketForProvider])
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const [masterServices, setMasterServices] = useState([]);

//     const [categories, setCategories] = useState([]);
//     const [selectedCategory, setSelectedCategory] = useState("");
//     const [subCategories, setSubCategories] = useState([]);
//     const [selectedSubCategory, setSelectedSubCategory] = useState("");

//     // const [fields, setFields] = useState([0]); // Default one div
//     const [price, setPrice] = useState("");


//     const [snackbarMessage, setSnackbarMessage] = useState('');
//     const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//     const [isEmployeeOpen, setIsEmployeeOpen] = useState(false);
//     const [snackbarOpen, setSnackbarOpen] = useState(false);
//     const [timeRange, setTimeRange] = useState({ startTime: "", endTime: "" });
//     const [newAvailableTime, setNewAvailableTime] = useState([]); // New slots added dynamically


//     // Function to format time in 12-hour format
//     const formatTime = (time) => {
//         const [hours, minutes] = time.split(":").map(Number);
//         const period = hours >= 12 ? "pm" : "am";
//         const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour format
//         return `${formattedHours}:${minutes.toString().padStart(2, "0")}${period}`;
//     };

//     useEffect(() => {
//         if (!socketForProvider) return;
//         socketForProvider.on('provider-booked', (data) => {
//             console.log('data: ', data.message);
//             alert(data.message);
//             setSnackbarMessage(data.message);
//         });
//         return () => {
//             socketForProvider.off('provider-booked');
//             socketForProvider.off('pushNotification');
//         };
//     }, [socketForProvider]);

//     // Add a new time slot to newAvailableTime
//     const handleAddTimeRange = () => {
//         if (timeRange.startTime && timeRange.endTime) {
//             const formattedStartTime = formatTime(timeRange.startTime);
//             const formattedEndTime = formatTime(timeRange.endTime);

//             // Add time slot only to newAvailableTime
//             const newTimeSlot = { time: `${formattedStartTime} - ${formattedEndTime}` };
//             setNewAvailableTime((prev) => [...prev, newTimeSlot]);

//             // Clear input fields
//             setTimeRange({ startTime: "", endTime: "" });
//         }
//     };

//     const navigate = useNavigate();

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') return;
//         setSnackbarOpen(false);
//     };
//     const id = Cookies.get("providerid")
//     // console.log("ID",id)

//     const [formData, setFormData] = useState({
//         name: '',
//         age: '',
//         email: '',
//         password: '',
//         phone: '',
//         DOB: '',
//         masterId: '',
//         cat_id: '',
//         sub_cat_id: '',  // Store selected sub_cat_id
//         price: '',
//         address: '',          // Stsore address
//         providerImage: null,      // Store the selected providerImage file
//         documentType: '',    // Store selected document type
//         documentNumber: '',
//         providerId: "",
//         description: '',
//         availableTime: "",
//         newAvailableTime: "",
//         images_details: "",

//     });

//     const openModal = () => setIsModalOpen(true);
//     const closeModal = () => setIsModalOpen(false);
//     const username = Cookies.get('name')
//     const email = Cookies.get('email')
//     const toggleSidebar = () => {
//         setIsSidebarOpen((prevState) => !prevState);
//     };
//     const toggleDropdown = () => {
//         setIsDropdownOpen((prevState) => !prevState);
//     };


//     const fetchMasterServices = async () => {
//         try {
//             const response = await axios.get(getMasterCategory);
//             // console.log("MATERSERVIE", response.data)
//             setMasterServices(response.data); // Full list of master services
//         } catch (error) {
//             console.error("Error fetching master services:", error);
//         }
//     };

//     const fetchCategories = async (serviceId) => {
//         try {
//             const response = await axios.get(`${getSubCategory}?masterid=${serviceId}`);

//             setCategories(response.data); // Full list of categories
//         } catch (error) {
//             console.error("Error fetching categories:", error);
//         }
//     };

//     const fetchSubCategories = async (categoryId) => {
//         console.log('categoryId: ', categoryId);
//         try {
//             const response = await axios.get(`${subcategoryservice}/${categoryId}`);
//             setSubCategories(response.data); // Full list of subcategories
//         } catch (error) {
//             console.error("Error fetching subcategories:", error);
//         }
//     };




//     const fetchEditData = async () => {
//         try {
//             const response = await axios.get(`${getDataToEdit}?provider_id=${id}`);
//             console.log("Fetch data=-=-", response.data);
//             const editData = response.data.result[0];
//             console.log('editData: ', editData);

//             // Fetch service data for dropdowns
//             const data = {
//                 masterId: editData.CategoryMasterId,
//                 cat_id: editData.CategoryCategoryId,
//                 sub_cat_id: editData.CategorySubCategoryIds,
//             };
//             console.log('data: ', data);

//             const serviceResponse = await axios.post(getServiceEditData, data);

//             // Set dropdown options
//             await fetchMasterServices();
//             await fetchCategories(editData.CategoryMasterId);
//             await fetchSubCategories(editData.CategoryCategoryId);

//             setFormData({
//                 name: editData.ProviderName,
//                 age: editData.AGE,
//                 DOB: formatToYYYYMMDD(editData.DOB),
//                 email: editData.ProviderEmail,
//                 phone: editData.ProviderPhone,
//                 address: editData.ProviderAddress,
//                 documentType: editData.ProviderDocumentType,
//                 documentNumber: editData.ProviderDocumentNumber,
//                 masterId: editData.CategoryMasterId,
//                 cat_id: editData.CategoryCategoryId,
//                 sub_cat_id: editData.CategorySubCategoryIds,
//                 price: editData.ServicePrice,
//                 // description: cleanDescription(editData.ServiceDescription),
//                 availableTime: cleanAvailableTime(editData.ServiceAvailableTime),
//                 providerId: id,
//                 // images_details: cleanDescription(editData.ServiceImagesDetails),
//                 // providerImage: null,
//             });
//         } catch (error) {
//             console.error("Error fetching edit data:", error);
//         }
//     };



//     useEffect(() => {
//         fetchEditData(); // Fetch data when component mounts
//     }, []);



//     const updateProviderDetails = async () => {
//         try {
//             // Ensure availableTime is correctly structured
//             const updatedAvailableTime = formData.availableTime.map((slot) =>
//                 typeof slot === 'string' ? { time: slot } : slot
//             );

//             // Ensure newAvailableTime is correctly structured
//             const updatedNewAvailableTime = newAvailableTime.map((slot) => ({
//                 time: slot.time || slot,
//             }));

//             // Prepare final form data
//             const updatedFormData = {
//                 ...formData,
//                 availableTime: updatedAvailableTime, // Flat JSON with "time" as the key
//                 newAvailableTime: updatedNewAvailableTime, // Same for new slots
//             };

//             // Submit data to the API
//             const response = await axios.put(editproviderDetails, updatedFormData);

//             if (response.status === 200) {
//                 setSnackbarMessage('Profile Updated !!');
//                 setSnackbarSeverity('success');
//                 setSnackbarOpen(true);

//                 setIsModalOpen(false);
//                 setNewAvailableTime([]); // Clear newAvailableTime after success
//             } else {
//                 setSnackbarMessage('Some error occurred');
//                 setSnackbarSeverity('error');
//                 setSnackbarOpen(true);
//             }
//         } catch (error) {
//             console.error("Error updating provider details:", error);
//             setSnackbarMessage("Failed to update provider details");
//             setSnackbarSeverity('error');
//             setSnackbarOpen(true);
//         }
//     };






//     const handleRemoveAvailableTime = (index) => {
//         setFormData((prev) => ({
//             ...prev,
//             availableTime: prev.availableTime.filter((_, i) => i !== index),
//         }));
//     };

//     // Remove newly added time slots
//     const handleRemoveNewTimeRange = (index) => {
//         setNewAvailableTime((prev) => prev.filter((_, i) => i !== index));
//     };


//     useEffect(() => {
//         fetchMasterServices();
//         fetchEditData()
//     }, []);



//     const handleMasterServiceChange = (e) => {
//         const masterId = e.target.value;
//         setFormData({ ...formData, masterId }); // Update masterId
//         fetchCategories(masterId); // Fetch categories for the selected master service
//     };


//     const handleCategoryChange = (event) => {
//         setFormData({ ...formData, cat_id: event.target.value });
//         const categoryId = event.target.value;
//         setSelectedCategory(categoryId);
//         fetchSubCategories(categoryId); // Fetch subcategories based on selected categoryId
//     };


//     const handleSubCategoryChange = (e) => {
//         // setFormData({ ...formData, sub_cat_id: e.target.value });
//         const selectedId = e.target.value;
//         // console.log("selectr id", selectedId)
//         setSelectedSubCategory(selectedId);

//         // Find the corresponding subcategory using the correct id field
//         const selectedSubCategory = subCategories.find(
//             (subCategory) => subCategory.sub_cat_id === parseInt(selectedId) // Use sub_cat_id for comparison
//         );
//         const sub_Cat_id = selectedSubCategory.sub_cat_id
//         // console.log("selectedSubCategory", selectedSubCategory);

//         if (selectedSubCategory) {
//             setPrice(selectedSubCategory.standard_price); // Update the price
//         }

//         setFormData({
//             ...formData,
//             sub_cat_id: JSON.stringify(sub_Cat_id),
//             price: selectedSubCategory.standard_price,  // Update price state based on selected subcategory
//         });
//     };

//     const handelLogout = () => {
//         Cookies.remove('providerid');
//         Cookies.remove('name');
//         Cookies.remove('email');
//         navigate('/provider-login');
//     }

//     return (
//         <div className='bg-[#202531]'>
//             <nav class="fixed top-0 z-50   shadow-xl w-full bg-[#202531]  dark:bg-gray-800 dark:border-gray-700">
//                 <div class="px-3 py-3 lg:px-5 lg:pl-3">
//                     <div class="flex items-center justify-between">
//                         <div class="flex items-center justify-start rtl:justify-end">
//                             <button
//                                 type="button"
//                                 className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//                                 onClick={toggleSidebar}
//                             >
//                                 <span className="sr-only">Open sidebar</span>
//                                 <svg
//                                     className="w-6 h-6"
//                                     aria-hidden="true"
//                                     fill="currentColor"
//                                     viewBox="0 0 20 20"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                         clipRule="evenodd"
//                                         fillRule="evenodd"
//                                         d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
//                                     ></path>
//                                 </svg>
//                             </button>
//                             <a class="flex ms-2 md:me-24">
//                                 <img src={logo} className=" w-10 h-10 me-3" alt="FlowBite Logo" />
//                                 <span class="self-center text-white  text-xl font-bold sm:text-2xl whitespace-nowrap dark:text-white">ON YOUR SERVICE </span>
//                             </a>
//                         </div>
//                         <div className="flex items-center">
//                             <div className="dropdown-container">
//                                 <button
//                                     type="button"
//                                     className="flex px-2 py-2 text-sm bg-[#202531] rounded-full"
//                                     aria-expanded="false"
//                                     onClick={toggleDropdown}
//                                 >
//                                     <CgProfile size={30} color="white" />
//                                 </button>
//                                 {isDropdownOpen && (
//                                     <div className="dropdown-menu">
//                                         <div className="px-4 py-3">
//                                             <p className="text-md capitalize  text-gray-900 font-bold dark:text-white">
//                                                 {username}
//                                             </p>
//                                             <p className="text-sm  capitalize text-gray-900 truncate dark:text-gray-300">
//                                                 {email ? email : "Not Logged In"}
//                                             </p>
//                                         </div>
//                                         <ul>
//                                             <li onClick={openModal}>
//                                                 <a

//                                                     data-modal-target="authentication-modal"
//                                                     data-modal-toggle="authentication-modal"
//                                                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
//                                                 >
//                                                     Edit Profile
//                                                 </a>
//                                             </li>


//                                             {/* <li>
//                                                 <a
//                                                     href="#"
//                                                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
//                                                 >
//                                                     Earnings
//                                                 </a>
//                                             </li> */}
//                                             <li onClick={handelLogout}>
//                                                 <a
//                                                     href="#"
//                                                     className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
//                                                 >
//                                                     Sign out
//                                                 </a>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </nav>
//             {/* 202531 */}


//             <aside
//                 id="logo-sidebar"
//                 className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 bg-[#202531]  dark:bg-gray-800 dark:border-gray-700 transition-transform  ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
//                     } sm:translate-x-0`}
//                 aria-label="Sidebar"
//             >
//                 <div class="h-full px-3  pb-4 overflow-y-auto bg-[#202531] dark:bg-gray-800">
//                     <ul class="space-y-2 font-medium">
//                         <li>
//                             <NavLink to="/provider-homepage" className={({ isActive }) =>
//                                 `flex items-center p-2 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${isActive ? "bg-gray-200 dark:bg-gray-700" : "text-gray-100"
//                                 }`
//                             }>
//                                 <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
//                                     <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
//                                     <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
//                                 </svg>
//                                 <span class="ms-3">Dashboard</span>
//                             </NavLink>
//                         </li>
//                         <li>
//                             <a href="/service-details" class="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
//                                 <svg xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-notebook-tabs"><path d="M2 6h4" /><path d="M2 10h4" /><path d="M2 14h4" /><path d="M2 18h4" /><rect width="16" height="20" x="4" y="2" rx="2" /><path d="M15 2v20" /><path d="M15 7h5" /><path d="M15 12h5" /><path d="M15 17h5" /></svg>
//                                 <span class="flex-1 ms-3 whitespace-nowrap">Service Details</span>
//                             </a>
//                         </li>
//                         <li>
//                             <button
//                                 onClick={() => setIsEmployeeOpen(!isEmployeeOpen)}
//                                 className="flex items-center w-full p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//                             >
//                                 <div className='flex justify-between '>
//                                     <div className='flex'>
//                                         <svg
//                                             className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white"
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             fill="currentColor"
//                                             viewBox="0 0 18 18"
//                                         >
//                                             <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
//                                         </svg>
//                                         <span className="flex-1 ms-3">Employee</span>
//                                     </div>
//                                     <div>
//                                         <svg
//                                             className={`w-5 h-5 transform ${isEmployeeOpen ? "rotate-180" : ""
//                                                 }`}
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             fill="none"
//                                             viewBox="0 0 24 24"
//                                             stroke="currentColor"
//                                             strokeWidth={2}
//                                         >
//                                             <path
//                                                 strokeLinecap="round"
//                                                 strokeLinejoin="round"
//                                                 d="M19 9l-7 7-7-7"
//                                             />
//                                         </svg>
//                                     </div>
//                                 </div>
//                             </button>
//                             {isEmployeeOpen && (
//                                 <ul className="ml-6 space-y-2">
//                                     <li>
//                                         <NavLink
//                                             to="/add-employee"
//                                             className="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//                                         >
//                                             Add Employee
//                                         </NavLink>
//                                     </li>
//                                     <li>
//                                         <NavLink
//                                             to="/employee-list"
//                                             className="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
//                                         >
//                                             Employee List
//                                         </NavLink>
//                                     </li>
//                                 </ul>
//                             )}
//                         </li>
//                         <li>
//                             <a href="#" class="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
//                                 <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
//                                     <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
//                                 </svg>
//                                 <span class="flex-1 ms-3 whitespace-nowrap">Order History</span>
//                             </a>
//                         </li>
//                         <li>
//                             <a href="/provider-login" class="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
//                                 <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
//                                     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
//                                 </svg>
//                                 <span class="flex-1 ms-3 whitespace-nowrap">Sign In</span>
//                             </a>
//                         </li>
//                         <li>
//                             <a href="/provider-register" class="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
//                                 <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
//                                     <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
//                                     <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
//                                     <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
//                                 </svg>
//                                 <span class="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
//                             </a>
//                         </li>
//                     </ul>
//                 </div>
//             </aside>



//             {/* =======================MODAL========================= */}

//             <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
//                 <Alert
//                     autoHideDuration={3000} // 3 seconds
//                     onClose={handleClose}
//                     severity={snackbarSeverity}
//                     variant="filled"
//                     sx={{ width: '100%' }}
//                 >
//                     {snackbarMessage}
//                 </Alert>
//             </Snackbar>



//             {isModalOpen && (
//                 <div
//                     id="authentication-modal"
//                     tabIndex="-1"
//                     aria-hidden={!isModalOpen}
//                     className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
//                 >
//                     <div class="relative  p-4 w-full max-w-xl max-h-full">
//                         <div
//                             className="relative bg-white rounded-lg shadow dark:bg-gray-700 overflow-y-auto max-h-[90vh]"
//                             style={{ scrollbarWidth: 'none' }} // Hide scrollbar for Firefox
//                         >

//                             <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
//                                 <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
//                                     Edit your profile !!
//                                 </h3>
//                                 <button
//                                     type="button"
//                                     onClick={closeModal}
//                                     className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
//                                 >
//                                     <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
//                                         <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
//                                     </svg>
//                                     <span class="sr-only">Close modal</span>
//                                 </button>
//                             </div>

//                             <div class="p-4 md:p-5 overflow-y-auto no-scrollbar">


//                                 <form class="max-w-md mx-auto">
//                                     <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input type="email"
//                                                 name="email"
//                                                 value={formData.email}
//                                                 onChange={(e) => setFormData({ ...formData, email: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required readOnly />
//                                             <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
//                                         </div>
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input type="number"
//                                                 name="age"
//                                                 id="floating_age"
//                                                 value={formData.age} // Prefill with formData.age
//                                                 onChange={(e) => setFormData({ ...formData, age: e.target.value })} // Handle change
//                                                 class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
//                                             <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Age</label>
//                                         </div>
//                                     </div>

//                                     <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input type="text"
//                                                 name="name"
//                                                 value={formData.name}
//                                                 onChange={(e) => setFormData({ ...formData, name: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
//                                             <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
//                                         </div>
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input
//                                                 type="date"
//                                                 name="DOB"
//                                                 id="DOB"
//                                                 value={formData.DOB} // Prefill with formData.DOB
//                                                 onChange={(e) => setFormData({ ...formData, DOB: e.target.value })} // Handle change
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 placeholder=" "

//                                                 required
//                                             />
//                                             <label
//                                                 htmlFor="DOB"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Date of Birth
//                                             </label>
//                                         </div>
//                                     </div>



//                                     {/* <div className="grid md:grid-cols-2 md:gap-6">
//                                         <div className="relative z-0 w-full mb-5 group">
//                                             <select
//                                                 id='masterId'
//                                                 name="masterId"
//                                                 value={formData.masterId}
//                                                 onChange={(e) => handleMasterServiceChange(e)}
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 required
//                                             >
//                                                 <option value="" disabled>
//                                                     Select Master Service
//                                                 </option>
//                                                 {masterServices.map((service) => (
//                                                     <option key={service.id} value={service.masterId}>
//                                                         {service.masterName}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <label
//                                                 htmlFor="masterId"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Master Service
//                                             </label>
//                                         </div>

//                                         <div className="relative z-0 w-full mb-5 group">
//                                             <select
//                                                 name="cat_id"
//                                                 value={formData.cat_id}
//                                                 onChange={(e) => handleCategoryChange(e)}
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 required
//                                             >
//                                                 <option value="" disabled>
//                                                     Select Category
//                                                 </option>
//                                                 {categories.map((category) => (
//                                                     <option key={category.id} value={category.cat_id}>
//                                                         {category.cat_name}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <label
//                                                 htmlFor="cat_id"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Category
//                                             </label>
//                                         </div>
//                                     </div> */}


//                                     {/* <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <select
//                                                 id="sub_cat_id"
//                                                 name="sub_cat_id"
//                                                 value={formData.sub_cat_id}
//                                                 onChange={(e) => handleSubCategoryChange(e)}
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 required
//                                             >

//                                                 <option value="" disabled>
//                                                     Select Subcategory
//                                                 </option>
//                                                 {subCategories.map((subCategory) => (
//                                                     <option key={subCategory.id} value={subCategory.sub_cat_id}>
//                                                         {subCategory.sub_cat_name}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                             <label for="sub_cat_id" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sub Category</label>
//                                         </div>
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input
//                                                 type="text"
//                                                 name="price"
//                                                 value={formData.price}
//                                                 onChange={(e) => setFormData({ ...formData, price: e.target.value })}
//                                                 id="price"
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 placeholder=" "
//                                             />
//                                             <label for="price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price</label>
//                                         </div>
//                                     </div> */}


//                                     <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-2 group">
//                                             <input
//                                                 type="text"
//                                                 name="address"
//                                                 value={formData.address}
//                                                 onChange={(e) => setFormData({ ...formData, address: e.target.value })}
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 placeholder=" "
//                                                 required

//                                             />
//                                             <label
//                                                 htmlFor="floating_address"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Address
//                                             </label>
//                                         </div>
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input type="tel"
//                                                 name="phone"
//                                                 disabled
//                                                 value={formData.phone}
//                                                 onChange={(e) => setFormData({ ...formData, phone: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
//                                             <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
//                                         </div>
//                                         {/* <div class="relative z-0 w-full mb-2 group">
//                                             <label
//                                                 className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//                                                 htmlFor="providerImage"
//                                             >
//                                                 Thumbnail
//                                             </label>

//                                             <input
//                                                 className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
//                                                 id="providerImage"
//                                                 type="file"
//                                                 // Handle file change
//                                                 required
//                                             />
//                                         </div> */}


//                                     </div>

//                                     <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <select
//                                                 name="floating_doc_type"
//                                                 id="floating_doc_type"
//                                                 value={formData.documentType} // Bind to formData
//                                                 onChange={(e) => setFormData({ ...formData, documentType: e.target.value })} // Handle change
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 required

//                                             >
//                                                 <option value="" disabled>Select Document Type</option>
//                                                 <option value="Aadhar Card">Aadhar Card</option>
//                                                 <option value="PAN Card">PAN Card</option>
//                                             </select>
//                                             <label
//                                                 for="floating_doc_type"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Document Type
//                                             </label>
//                                         </div>

//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input
//                                                 type="number"
//                                                 name="floating_adhaar"
//                                                 id="floating_adhaar"
//                                                 value={formData.documentNumber} // Bind to formData
//                                                 onChange={(e) => setFormData({ ...formData, documentNumber: e.target.value })} // Handle change
//                                                 className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
//                                                 placeholder=" "
//                                                 required

//                                             />
//                                             <label
//                                                 for="floating_adhaar"
//                                                 className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
//                                             >
//                                                 Card Number
//                                             </label>
//                                         </div>
//                                     </div>



//                                     <div className="relative z-0 w-full mb-5 group">
//                                         <h2 className="text-lg font-medium mb-3 text-gray-900 dark:text-white">
//                                             Edit Available Time Slots
//                                         </h2>

//                                         {/* Time Range Input */}
//                                         <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
//                                             Add New Time Range
//                                         </label>
//                                         <div className="flex items-center gap-2">
//                                             <input
//                                                 type="time"
//                                                 name="startTime"
//                                                 value={timeRange.startTime}
//                                                 onChange={(e) => setTimeRange({ ...timeRange, startTime: e.target.value })}
//                                                 className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                             />
//                                             <span className="text-gray-600 dark:text-gray-400">to</span>
//                                             <input
//                                                 type="time"
//                                                 name="endTime"
//                                                 value={timeRange.endTime}
//                                                 onChange={(e) => setTimeRange({ ...timeRange, endTime: e.target.value })}
//                                                 className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                             />
//                                             <button
//                                                 type="button"
//                                                 onClick={handleAddTimeRange}
//                                                 className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
//                                             >
//                                                 Add
//                                             </button>
//                                         </div>

//                                         {/* Display Existing Time Slots */}
//                                         <div className="mt-3">
//                                             <p className="text-sm font-medium text-gray-900 dark:text-white">
//                                                 Existing Time Slots (from API):
//                                             </p>
//                                             <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400">
//                                                 {Array.isArray(formData.availableTime) && formData.availableTime.length > 0 ? (
//                                                     formData.availableTime.map((slot, index) => (
//                                                         <li key={index} className="flex items-center justify-between">
//                                                             {slot.time}
//                                                             <button
//                                                                 type="button"
//                                                                 onClick={() => handleRemoveAvailableTime(index)}
//                                                                 className="text-red-500 hover:text-red-700 font-medium ml-4"
//                                                             >
//                                                                 Remove
//                                                             </button>
//                                                         </li>
//                                                     ))
//                                                 ) : (
//                                                     <li className="text-gray-500">No existing time slots available.</li>
//                                                 )}
//                                             </ul>
//                                         </div>

//                                         {/* Display Newly Added Time Slots */}
//                                         {newAvailableTime.length > 0 && (
//                                             <div className="mt-3">
//                                                 <p className="text-sm font-medium text-gray-900 dark:text-white">
//                                                     Newly Added Time Slots:
//                                                 </p>
//                                                 <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400">
//                                                     {newAvailableTime.map((slot, index) => (
//                                                         <li key={index} className="flex items-center justify-between">
//                                                             {slot.time}
//                                                             <button
//                                                                 type="button"
//                                                                 onClick={() => handleRemoveNewTimeRange(index)}
//                                                                 className="text-red-500 hover:text-red-700 font-medium ml-4"
//                                                             >
//                                                                 Remove
//                                                             </button>
//                                                         </li>
//                                                     ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>



//                                     {/* <div class="grid md:grid-cols-2 md:gap-6">
//                                         <div class="relative z-0 w-full mb-5 group">
//                                             <input type="tel"
//                                                 name="phone"
//                                                 disabled
//                                                 value={formData.phone}
//                                                 onChange={(e) => setFormData({ ...formData, phone: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
//                                             <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
//                                         </div>

//                                     </div> */}
//                                     <button
//                                         type="button"
//                                         onClick={updateProviderDetails}
//                                         className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//                                     >
//                                         Update Details
//                                     </button>

//                                 </form>

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     )
// }

// export default Navbar


// ==================================


import React, { useState, useEffect } from 'react'
import logo from '../assets/oys_logo.png'

import { CgProfile } from "react-icons/cg";
import Cookies from 'js-cookie'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import io from 'socket.io-client';
import { getMasterCategory, getSubCategory, editproviderDetails, subcategoryservice, providerAuthRegister, getServiceEditData, getDataToEdit } from '../Service/provider-api-path'
import { cleanAvailableTime, cleanDescription, formatToYYYYMMDD } from '../utils/Helpers.js'
import '../pages/ProviderNavbar.css'
import { NavLink } from "react-router-dom";


// const socket = io('http://88.99.163.249:4956');
const socket=io('https://onyourservice.com')
console.log('socket: ', socket);

const Navbar = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState(false);

    const [masterServices, setMasterServices] = useState([]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState("");

    // const [fields, setFields] = useState([0]); // Default one div
    const [price, setPrice] = useState("");


    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isEmployeeOpen, setIsEmployeeOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [timeRange, setTimeRange] = useState({ startTime: "", endTime: "" });
    const [newAvailableTime, setNewAvailableTime] = useState([]); // New slots added dynamically


    // Function to format time in 12-hour format
    const formatTime = (time) => {
        const [hours, minutes] = time.split(":").map(Number);
        const period = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour format
        return `${formattedHours}:${minutes.toString().padStart(2, "0")}${period}`;
    };

    useEffect(() => {
        socket.on('provider-booked', (data) => {
            console.log('data: ', data.message);
            alert(data.message);
            setSnackbarMessage(data.message);
        });
        return () => {
            socket.off('pushNotification');
        };
    }, []);

    // Add a new time slot to newAvailableTime
    const handleAddTimeRange = () => {
        if (timeRange.startTime && timeRange.endTime) {
            const formattedStartTime = formatTime(timeRange.startTime);
            const formattedEndTime = formatTime(timeRange.endTime);

            // Add time slot only to newAvailableTime
            const newTimeSlot = { time: `${formattedStartTime} - ${formattedEndTime}` };
            setNewAvailableTime((prev) => [...prev, newTimeSlot]);

            // Clear input fields
            setTimeRange({ startTime: "", endTime: "" });
        }
    };

    const navigate = useNavigate();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };
    const id = Cookies.get("providerid")
    // console.log("ID",id)

    const [formData, setFormData] = useState({
        name: '',
        age: '',
        email: '',
        password: '',
        phone: '',
        DOB: '',
        masterId: '',
        cat_id: '',
        sub_cat_id: '',  // Store selected sub_cat_id
        price: '',
        address: '',          // Stsore address
        providerImage: null,      // Store the selected providerImage file
        documentType: '',    // Store selected document type
        documentNumber: '',
        providerId: "",
        description: '',
        availableTime: "",
        newAvailableTime: "",
        images_details: "",

    });

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const username = Cookies.get('name')
    const email = Cookies.get('email')
    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };
    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };


    const fetchMasterServices = async () => {
        try {
            const response = await axios.get(getMasterCategory);
            // console.log("MATERSERVIE", response.data)
            setMasterServices(response.data); // Full list of master services
        } catch (error) {
            console.error("Error fetching master services:", error);
        }
    };

    const fetchCategories = async (serviceId) => {
        try {
            const response = await axios.get(`${getSubCategory}?masterid=${serviceId}`);

            setCategories(response.data); // Full list of categories
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchSubCategories = async (categoryId) => {
        console.log('categoryId: ', categoryId);
        try {
            const response = await axios.get(`${subcategoryservice}/${categoryId}`);
            setSubCategories(response.data); // Full list of subcategories
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };




    const fetchEditData = async () => {
        try {
            const response = await axios.get(`${getDataToEdit}?provider_id=${id}`);
            console.log("Fetch data=-=-", response.data);
            const editData = response.data.result[0];
            console.log('editData: ', editData);

            // Fetch service data for dropdowns
            const data = {
                masterId: editData.CategoryMasterId,
                cat_id: editData.CategoryCategoryId,
                sub_cat_id: editData.CategorySubCategoryIds,
            };
            console.log('data: ', data);

            const serviceResponse = await axios.post(getServiceEditData, data);

            // Set dropdown options
            await fetchMasterServices();
            await fetchCategories(editData.CategoryMasterId);
            await fetchSubCategories(editData.CategoryCategoryId);

            setFormData({
                name: editData.ProviderName,
                age: editData.AGE,
                DOB: formatToYYYYMMDD(editData.DOB),
                email: editData.ProviderEmail,
                phone: editData.ProviderPhone,
                address: editData.ProviderAddress,
                documentType: editData.ProviderDocumentType,
                documentNumber: editData.ProviderDocumentNumber,
                masterId: editData.CategoryMasterId,
                cat_id: editData.CategoryCategoryId,
                sub_cat_id: editData.CategorySubCategoryIds,
                price: editData.ServicePrice,
                description: cleanDescription(editData.ServiceDescription),
                availableTime: cleanAvailableTime(editData.ServiceAvailableTime),
                providerId: id,
                images_details: cleanDescription(editData.ServiceImagesDetails),
                providerImage: null,
            });
        } catch (error) {
            console.error("Error fetching edit data:", error);
        }
    };



    useEffect(() => {
        fetchEditData(); // Fetch data when component mounts
    }, []);



    const updateProviderDetails = async () => {
        try {
            // Ensure availableTime is correctly structured
            const updatedAvailableTime = formData.availableTime.map((slot) =>
                typeof slot === 'string' ? { time: slot } : slot
            );

            // Ensure newAvailableTime is correctly structured
            const updatedNewAvailableTime = newAvailableTime.map((slot) => ({
                time: slot.time || slot,
            }));

            // Prepare final form data
            const updatedFormData = {
                ...formData,
                availableTime: updatedAvailableTime, // Flat JSON with "time" as the key
                newAvailableTime: updatedNewAvailableTime, // Same for new slots
            };

            // Submit data to the API
            const response = await axios.put(editproviderDetails, updatedFormData);

            if (response.status === 200) {
                setSnackbarMessage('Profile Updated !!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

                setIsModalOpen(false);
                setNewAvailableTime([]); // Clear newAvailableTime after success
            } else {
                setSnackbarMessage('Some error occurred');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error updating provider details:", error);
            setSnackbarMessage("Failed to update provider details");
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };






    const handleRemoveAvailableTime = (index) => {
        setFormData((prev) => ({
            ...prev,
            availableTime: prev.availableTime.filter((_, i) => i !== index),
        }));
    };

    // Remove newly added time slots
    const handleRemoveNewTimeRange = (index) => {
        setNewAvailableTime((prev) => prev.filter((_, i) => i !== index));
    };


    useEffect(() => {
        fetchMasterServices();
        fetchEditData()
    }, []);



    const handleMasterServiceChange = (e) => {
        const masterId = e.target.value;
        setFormData({ ...formData, masterId }); // Update masterId
        fetchCategories(masterId); // Fetch categories for the selected master service
    };


    const handleCategoryChange = (event) => {
        setFormData({ ...formData, cat_id: event.target.value });
        const categoryId = event.target.value;
        setSelectedCategory(categoryId);
        fetchSubCategories(categoryId); // Fetch subcategories based on selected categoryId
    };


    const handleSubCategoryChange = (e) => {
        // setFormData({ ...formData, sub_cat_id: e.target.value });
        const selectedId = e.target.value;
        // console.log("selectr id", selectedId)
        setSelectedSubCategory(selectedId);

        // Find the corresponding subcategory using the correct id field
        const selectedSubCategory = subCategories.find(
            (subCategory) => subCategory.sub_cat_id === parseInt(selectedId) // Use sub_cat_id for comparison
        );
        const sub_Cat_id = selectedSubCategory.sub_cat_id
        // console.log("selectedSubCategory", selectedSubCategory);

        if (selectedSubCategory) {
            setPrice(selectedSubCategory.standard_price); // Update the price
        }

        setFormData({
            ...formData,
            sub_cat_id: JSON.stringify(sub_Cat_id),
            price: selectedSubCategory.standard_price,  // Update price state based on selected subcategory
        });
    };

    const handelLogout = () => {
        Cookies.remove('providerid');
        Cookies.remove('name');
        Cookies.remove('email');
        navigate('/provider-login');
    }

    return (
        <div className='bg-[#202531]'>
            <nav class="fixed top-0 z-50   shadow-xl w-full bg-[#202531]  dark:bg-gray-800 dark:border-gray-700">
                <div class="px-3 py-3 lg:px-5 lg:pl-3">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center justify-start rtl:justify-end">
                            <button
                                type="button"
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                onClick={toggleSidebar}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        clipRule="evenodd"
                                        fillRule="evenodd"
                                        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                    ></path>
                                </svg>
                            </button>
                            <a class="flex ms-2 md:me-24">
                                <img src={logo} className=" w-10 h-10 me-3" alt="FlowBite Logo" />
                                <span class="self-center text-white  text-xl font-bold sm:text-2xl whitespace-nowrap dark:text-white">ON YOUR SERVICE </span>
                            </a>
                        </div>
                        <div className="flex items-center">
                        <div className="dropdown-container">
  <button
    type="button"
    className="flex px-2 py-2 text-sm bg-[#202531] rounded-full"
    aria-expanded="false"
    onClick={toggleDropdown}
  >
    <CgProfile size={30} color="white" />
  </button>
  {isDropdownOpen && (
    <div className="dropdown-menu">
      <div className="px-4 py-3">
        <p className="text-md capitalize text-gray-900 font-bold dark:text-white">
          {username}
        </p>
        <p className="text-sm capitalize text-gray-900 truncate dark:text-gray-300">
          {email ? email : "Not Logged In"}
        </p>
      </div>
      <ul>
        <li
          onClick={() => {
            openModal();
            setIsDropdownOpen(false); // Close dropdown
          }}
        >
          <a
            data-modal-target="authentication-modal"
            data-modal-toggle="authentication-modal"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            Edit Profile
          </a>
        </li>

        <li
          onClick={() => {
            setIsSwitchAccountModalOpen(true);
            setIsDropdownOpen(false); // Close dropdown
          }}
        >
          <a
            href="#"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            Switch Account
          </a>
        </li>

        <li
          onClick={() => {
            handelLogout();
            setIsDropdownOpen(false); // Close dropdown
          }}
        >
          <a
            href="#"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            Sign out
          </a>
        </li>
      </ul>
    </div>
  )}
</div>

                        </div>
                    </div>
                </div>
            </nav>
            {/* 202531 */}


            {isSwitchAccountModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-gradient-to-r from-white to-gray-100 rounded-lg shadow-2xl p-8 w-full max-w-md">
      <h2 className="text-2xl font-extrabold text-center mb-6 text-gray-700">
        Wanna Switch Account?
      </h2>
      <p className="text-center text-gray-500 mb-6">
        Choose how you want to log in. You can switch between your accounts with ease.
      </p>
      <ul className="space-y-4">
        <li>
          <button
            onClick={() => navigate('/provider-login')}
            className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg py-3 transition-all"
          >
            Login to Another Account
          </button>
        </li>
        <li>
          <button
            onClick={() => navigate('/login')}
            className="w-full text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-lg py-3 transition-all"
          >
            Login as a User
          </button>
        </li>
      </ul>
      <button
        onClick={() => setIsSwitchAccountModalOpen(false)}
        className="mt-6 w-full text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg py-3 transition-all"
      >
        Close
      </button>
    </div>
  </div>
)}


            <aside
                id="logo-sidebar"
                className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 bg-[#202531]  dark:bg-gray-800 dark:border-gray-700 transition-transform  ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                    } sm:translate-x-0`}
                aria-label="Sidebar"
            >
                <div class="h-full px-3  pb-4 overflow-y-auto bg-[#202531] dark:bg-gray-800">
                    <ul class="space-y-2 font-medium">
                        <li>
                            <NavLink to="/provider-homepage" className={({ isActive }) =>
                                `flex items-center p-2 rounded-lg hover:text-gray-800 hover:bg-gray-100  ${isActive ? "bg-gray-200 dark:bg-gray-700" : "text-gray-100"
                                }`
                            }>
                                <svg class="w-5 h-5 text-gray-500 transition duration-75 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span class="ms-3">Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <a href="/service-details" class="flex items-center p-2 text-gray-100 rounded-lg  hover:bg-gray-100 hover:text-gray-800  group">
                                <svg xmlns="http://www.w3.org/2000/svg" className='flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-notebook-tabs"><path d="M2 6h4" /><path d="M2 10h4" /><path d="M2 14h4" /><path d="M2 18h4" /><rect width="16" height="20" x="4" y="2" rx="2" /><path d="M15 2v20" /><path d="M15 7h5" /><path d="M15 12h5" /><path d="M15 17h5" /></svg>
                                <span class="flex-1 ms-3 whitespace-nowrap">Service Details</span>
                            </a>
                        </li>
                        <li>
                            <button
                                onClick={() => setIsEmployeeOpen(!isEmployeeOpen)}
                                className="flex items-center w-full p-2 text-gray-100 rounded-lg hover:text-gray-800 hover:bg-gray-100 "
                            >
                                <div className='flex justify-between '>
                                    <div className='flex'>
                                        <svg
                                            className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 18 18"
                                        >
                                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                        </svg>
                                        <span className="flex-1 ms-3">Employee</span>
                                    </div>
                                    <div>
                                        <svg
                                            className={`w-5 h-5 transform ${isEmployeeOpen ? "rotate-180" : ""
                                                }`}
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19 9l-7 7-7-7"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                            {isEmployeeOpen && (
                                <ul className="ml-6 space-y-2">
                                    <li>
                                        <NavLink
                                            to="/add-employee"
                                            className="flex items-center p-2 text-gray-100 rounded-lg hover:text-gray-800  hover:bg-gray-100 "
                                        >
                                            Add Employee
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/employee-list"
                                            className="flex items-center p-2 text-gray-100 rounded-lg hover:text-gray-800 hover:bg-gray-100 "
                                        >
                                            Employee List
                                        </NavLink>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <a href="/provider-order-history" class="flex items-center p-2 text-gray-100 rounded-lg hover:bg-gray-100 hover:text-gray-800 group">
                                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400  dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                    <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                                </svg>
                                <span class="flex-1 ms-3 whitespace-nowrap">Order History</span>
                            </a>
                        </li>
                        {/* <li>
                            <a href="/provider-gallery" class="flex items-center p-2 text-gray-100 rounded-lg  hover:bg-gray-100 hover:text-gray-800  group">
                                <svg
                                    class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  dark:group-hover:text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <rect x="3" y="3" width="18" height="14" rx="2" ry="2"></rect>
                                    <circle cx="9" cy="8" r="2"></circle>
                                    <path d="M21 21L16.65 15.65a2 2 0 0 0-2.83 0L12 18l-3.88-3.88a2 2 0 0 0-2.83 0L3 21"></path>
                                </svg>
                                <span class="flex-1 ms-3 whitespace-nowrap">Gallery</span>
                            </a>
                        </li> */}

                        {/* <li>
                            <a href="/provider-register" class="flex items-center p-2 text-gray-100 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-100 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                                </svg>
                                <span class="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </aside>



            {/* =======================MODAL========================= */}

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    autoHideDuration={3000} // 3 seconds
                    onClose={handleClose}
                    severity={snackbarSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>



            {isModalOpen && (
                <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-hidden={!isModalOpen}
                    className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
                >
                    <div class="relative  p-4 w-full max-w-xl max-h-full">
                        <div
                            className="relative bg-white rounded-lg shadow dark:bg-gray-700 overflow-y-auto max-h-[90vh]"
                            style={{ scrollbarWidth: 'none' }} // Hide scrollbar for Firefox
                        >

                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                    Edit your profile !!
                                </h3>
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>

                            <div class="p-4 md:p-5 overflow-y-auto no-scrollbar">


                                <form class="max-w-md mx-auto">
                                    <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required readOnly />
                                            <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                                        </div>
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input type="number"
                                                name="age"
                                                id="floating_age"
                                                value={formData.age} // Prefill with formData.age
                                                onChange={(e) => setFormData({ ...formData, age: e.target.value })} // Handle change
                                                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                            <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Age</label>
                                        </div>
                                    </div>

                                    <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                            <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Username</label>
                                        </div>
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input
                                                type="date"
                                                name="DOB"
                                                id="DOB"
                                                value={formData.DOB} // Prefill with formData.DOB
                                                onChange={(e) => setFormData({ ...formData, DOB: e.target.value })} // Handle change
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "

                                                required
                                            />
                                            <label
                                                htmlFor="DOB"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Date of Birth
                                            </label>
                                        </div>
                                    </div>



                                    {/* <div className="grid md:grid-cols-2 md:gap-6">
                                        <div className="relative z-0 w-full mb-5 group">
                                            <select
                                                id='masterId'
                                                name="masterId"
                                                value={formData.masterId}
                                                onChange={(e) => handleMasterServiceChange(e)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                required
                                            >
                                                <option value="" disabled>
                                                    Select Master Service
                                                </option>
                                                {masterServices.map((service) => (
                                                    <option key={service.id} value={service.masterId}>
                                                        {service.masterName}
                                                    </option>
                                                ))}
                                            </select>
                                            <label
                                                htmlFor="masterId"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Master Service
                                            </label>
                                        </div>

                                        <div className="relative z-0 w-full mb-5 group">
                                            <select
                                                name="cat_id"
                                                value={formData.cat_id}
                                                onChange={(e) => handleCategoryChange(e)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                required
                                            >
                                                <option value="" disabled>
                                                    Select Category
                                                </option>
                                                {categories.map((category) => (
                                                    <option key={category.id} value={category.cat_id}>
                                                        {category.cat_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label
                                                htmlFor="cat_id"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Category
                                            </label>
                                        </div>
                                    </div> */}


                                    {/* <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-5 group">
                                            <select
                                                id="sub_cat_id"
                                                name="sub_cat_id"
                                                value={formData.sub_cat_id}
                                                onChange={(e) => handleSubCategoryChange(e)}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                required
                                            >

                                                <option value="" disabled>
                                                    Select Subcategory
                                                </option>
                                                {subCategories.map((subCategory) => (
                                                    <option key={subCategory.id} value={subCategory.sub_cat_id}>
                                                        {subCategory.sub_cat_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label for="sub_cat_id" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sub Category</label>
                                        </div>
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input
                                                type="text"
                                                name="price"
                                                value={formData.price}
                                                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                                                id="price"
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                            />
                                            <label for="price" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price</label>
                                        </div>
                                    </div> */}


                                    <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-2 group">
                                            <input
                                                type="text"
                                                name="address"
                                                value={formData.address}
                                                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required

                                            />
                                            <label
                                                htmlFor="floating_address"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Address
                                            </label>
                                        </div>
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input type="tel"
                                                name="phone"
                                                disabled
                                                value={formData.phone}
                                                onChange={(e) => setFormData({ ...formData, phone: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                            <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                                        </div>
                                        {/* <div class="relative z-0 w-full mb-2 group">
                                            <label
                                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                htmlFor="providerImage"
                                            >
                                                Thumbnail
                                            </label>

                                            <input
                                                className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                                id="providerImage"
                                                type="file"
                                                // Handle file change
                                                required
                                            />
                                        </div> */}


                                    </div>

                                    <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-5 group">
                                            <select
                                                name="floating_doc_type"
                                                id="floating_doc_type"
                                                value={formData.documentType} // Bind to formData
                                                onChange={(e) => setFormData({ ...formData, documentType: e.target.value })} // Handle change
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                required

                                            >
                                                <option value="" disabled>Select Document Type</option>
                                                <option value="Aadhar Card">Aadhar Card</option>
                                                <option value="PAN Card">PAN Card</option>
                                            </select>
                                            <label
                                                for="floating_doc_type"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Document Type
                                            </label>
                                        </div>

                                        <div class="relative z-0 w-full mb-5 group">
                                            <input
                                                type="number"
                                                name="floating_adhaar"
                                                id="floating_adhaar"
                                                value={formData.documentNumber} // Bind to formData
                                                onChange={(e) => setFormData({ ...formData, documentNumber: e.target.value })} // Handle change
                                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                placeholder=" "
                                                required

                                            />
                                            <label
                                                for="floating_adhaar"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Card Number
                                            </label>
                                        </div>
                                    </div>



                                    <div className="relative z-0 w-full mb-5 group">
                                        <h2 className="text-lg font-medium mb-3 text-gray-900 dark:text-white">
                                            Edit Available Time Slots
                                        </h2>

                                        {/* Time Range Input */}
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Add New Time Range
                                        </label>
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="time"
                                                name="startTime"
                                                value={timeRange.startTime}
                                                onChange={(e) => setTimeRange({ ...timeRange, startTime: e.target.value })}
                                                className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                            <span className="text-gray-600 dark:text-gray-400">to</span>
                                            <input
                                                type="time"
                                                name="endTime"
                                                value={timeRange.endTime}
                                                onChange={(e) => setTimeRange({ ...timeRange, endTime: e.target.value })}
                                                className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                            <button
                                                type="button"
                                                onClick={handleAddTimeRange}
                                                className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
                                            >
                                                Add
                                            </button>
                                        </div>

                                        <div className="mt-3">
                                            <p className="text-sm font-medium text-gray-900 dark:text-white">
                                                Existing Time Slots (from API):
                                            </p>
                                            <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400">
                                                {Array.isArray(formData.availableTime) && formData.availableTime.length > 0 ? (
                                                    formData.availableTime.map((slot, index) => (
                                                        <li key={index} className="flex items-center justify-between">
                                                            {slot.time}
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemoveAvailableTime(index)}
                                                                className="text-red-500 hover:text-red-700 font-medium ml-4"
                                                            >
                                                                Remove
                                                            </button>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li className="text-gray-500">No existing time slots available.</li>
                                                )}
                                            </ul>
                                        </div>

                                        {newAvailableTime.length > 0 && (
                                            <div className="mt-3">
                                                <p className="text-sm font-medium text-gray-900 dark:text-white">
                                                    Newly Added Time Slots:
                                                </p>
                                                <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400">
                                                    {newAvailableTime.map((slot, index) => (
                                                        <li key={index} className="flex items-center justify-between">
                                                            {slot.time}
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRemoveNewTimeRange(index)}
                                                                className="text-red-500 hover:text-red-700 font-medium ml-4"
                                                            >
                                                                Remove
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>



                                    {/* <div class="grid md:grid-cols-2 md:gap-6">
                                        <div class="relative z-0 w-full mb-5 group">
                                            <input type="tel"
                                                name="phone"
                                                disabled
                                                value={formData.phone}
                                                onChange={(e) => setFormData({ ...formData, phone: e.target.value })} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                            <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                                        </div>

                                    </div> */}
                                    <button
                                        type="button"
                                        onClick={updateProviderDetails}
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        Update Details
                                    </button>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Navbar
