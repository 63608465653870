import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SavedAddressApi } from "../../Service/api-path";

const SavedAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await SavedAddressApi(user_id);
        console.log("Saved Addresses:", response.data);
        setAddresses(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching addresses:", error);
        setError("Failed to load saved addresses.");
        setLoading(false);
      }
    };

    if (user_id) fetchAddresses();
  }, [user_id]);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="bg-[#E2D9FA] min-h-screen flex justify-center items-center">
      <div className="w-full max-w-3xl bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700 p-6 relative">
        <button
          className="absolute top-2 right-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
          onClick={handleClose}
        >
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white mb-6">Saved Addresses</h2>

        {loading ? (
          <p className="text-gray-600 dark:text-white">Loading addresses...</p>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : addresses.length === 0 ? (
          <p className="text-gray-600 dark:text-white">No saved addresses found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {addresses.map((address) => (
              <div
                key={address.id}
                className="bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md"
              >
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Address {address.id}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-300 mt-2">{address.Address}</p>

                {address.latitude && address.longitude && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                    Coordinates: {address.latitude}, {address.longitude}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedAddress;