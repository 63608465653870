import React, { useState, useEffect } from "react";
import axios from "axios";
import { providerOrderHistory } from "../../Service/provider-api-path";
import Cookies from "js-cookie";
// import { format } from "date-fns";
const OrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const id = Cookies.get("providerid");

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await axios.get(`${providerOrderHistory}?provider_id=${id}`);
        console.log("ORDER HISTORY", response.data);
        setOrderHistory(response.data.result);
      } catch (error) {
        console.error("Error fetching order history:", error);
      }
    };

    fetchOrderHistory();
  }, []);

  return (
    <div className="  border   bg-gray-100 flex justify-center min-h-screen">
      <div className="sm:ml-58 xl:ml-60 md:ml-60 mt-10 max-w-lg md:max-w-5xl pt-10 w-full">
      <div className="flex   flex-wrap items-center justify-between space-y-4 md:space-y-0 pb-4 bg-white dark:bg-gray-900">
              <h1 className="text-xl px-3 text-gray-600 font-bold uppercase ">
                Order History
              </h1>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                 
                    
                    <th scope="col" className="px-1 py-3">
                      Booking Date
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Scheduled Date
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Scheduled Time
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Service
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Address
                    </th>
                    <th scope="col" className="px-1 py-3">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
  {orderHistory.map((order) => (
    <tr
      key={order.id}
      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    >
      {/* Name and Email */}
      <td className="flex items-center px-6 py-4 whitespace-nowrap">
        <img
          className="w-10 h-10 rounded-full shadow-xl"
          src={order.profilePic || "/default-profile.jpg"}
          alt={order.name}
        />
        <div className="pl-3">
          <div className="text-sm font-semibold text-gray-900 dark:text-white">
            {order.name}
          </div>
          <div className="text-sm text-gray-500">{order.email}</div>
        </div>
      </td>

      {/* Other Columns */}
      <td className="px-1  py-4">
  {new Date(order.createdon).toISOString().slice(0, 10)}
</td>

      <td className="px-1 py-4">{`${order.schedule_date} `}</td>
      <td className="px-1 py-4">{`${order.schedule_time}`}</td>

      <td className="px-1 py-4">{order.sub_cat_name}</td>
      <td className="px-1 py-4">{order.user_address}</td>
      <td className="px-1 py-4">
        <span
          className={`px-2 py-1 text-xs font-medium rounded ${
            order.IsserviceDone
              ? "bg-green-200 text-green-800"
              : "bg-yellow-200 text-yellow-800"
          }`}
        >
          {order.IsserviceDone ? "Completed" : "Pending"}
        </span>
      </td>
    </tr>
  ))}
  {orderHistory.length === 0 && (
    <tr>
      <td colSpan="8" className="text-center py-4 text-gray-500">
        No orders found.
      </td>
    </tr>
  )}
</tbody>

              </table>
            </div>
        <div className="border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
          <div className="relative  overflow-x-auto shadow-md sm:rounded-lg">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
