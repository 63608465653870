import React from 'react'
import { Star, X } from "lucide-react";

function BookingCommentModel() {
    return (
        <div>
            <div className="relative bg-white p-4 shadow rounded-lg">

                <div className="flex flex-col space-y-5">
                    <div className="flex items-center space-x-2">
                        <span className="text-4xl font-bold text-black">4.81</span>
                        <span className="text-gray-500 text-sm">1.4M reviews</span>
                    </div>
                    <div className="mt-4 sm:mt-0 w-full sm:w-auto">
                        {[
                            { icon: <Star size={14} />, stars: 5, count: '1.3M' },
                            { icon: <Star size={14} />, stars: 4, count: '30K' },
                            { icon: <Star size={14} />, stars: 3, count: '14K' },
                            { icon: <Star size={14} />, stars: 2, count: '10K' },
                            { icon: <Star size={14} />, stars: 1, count: '35K' },
                        ]?.map((rating, index) => (
                            <div key={index} className="flex items-center space-x-2">
                                <span className="text-sm text-black flex items-center">
                                    {rating.icon} {rating.stars}
                                </span>
                                <div className="flex-1 h-1 bg-gray-200 rounded-full overflow-hidden">
                                    <div
                                        className="h-full bg-black"
                                        style={{ width: `${rating.stars === 5 ? 80 : rating.stars * 10}%` }}
                                    />
                                </div>
                                <span className="text-sm text-gray-600">{rating.count}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="mt-[3px] bg-white p-4 shadow rounded-lg">
                <h2 className="text-xl font-semibold text-black">All reviews</h2>

                <div className="flex flex-wrap items-center space-x-2 mt-4">
                    <button className="px-4 py-2 bg-gray-100 rounded-full text-sm text-black">Most detailed</button>
                    <button className="px-4 py-2 bg-gray-100 rounded-full text-sm text-black">In my area</button>
                    <button className="px-4 py-2 bg-gray-100 rounded-full text-sm text-black">Frequent users</button>
                    <button className="ml-auto text-blue-500 text-sm">Filter</button>
                </div>

                <div className="mt-4 max-h-[60vh] bg-white p-4 shadow rounded-lg overflow-y-auto">
                    <div className="mt-5">
                        {[
                            {
                                name: "Chaitanya Rao",
                                date: "Dec 18, 2024",
                                issue: "For Power issue",
                                rating: 5,
                                review:
                                    "Samson is beyond excellent, super clear, well-articulated explanation who made us feel super comfortable. He’s honest and passionate about his work and I’ll book him again instantly if needed!",
                            },
                            {
                                name: "John Doe",
                                date: "Dec 17, 2024",
                                issue: "For Plumbing issue",
                                rating: 4,
                                review:
                                    "John was efficient and friendly. He explained the problem clearly and resolved it in no time. Highly recommend his services!",
                            },
                            {
                                name: "Jane Smith",
                                date: "Dec 16, 2024",
                                issue: "For Electrical issue",
                                rating: 5,
                                review:
                                    "Jane went above and beyond to ensure everything was fixed perfectly. Her professionalism and skill are unmatched!",
                            },
                            {
                                name: "Robert Brown",
                                date: "Dec 15, 2024",
                                issue: "For HVAC issue",
                                rating: 3,
                                review:
                                    "Robert did an okay job. While he fixed the issue, the process took longer than expected. Overall, a decent experience.",
                            },
                            {
                                name: "Emily Davis",
                                date: "Dec 14, 2024",
                                issue: "For Maintenance issue",
                                rating: 4,
                                review:
                                    "Emily is fantastic! She quickly identified the issue and fixed it efficiently. I’d definitely hire her again.",
                            },
                        ]?.map((review, index) => (
                            <div key={index} className="mb-5">
                                <div className="flex justify-between">
                                    <div>
                                        <h3 className="text-lg font-medium text-black">{review.name}</h3>
                                        <span className="text-[10px] text-gray-500 font-semibold">
                                            {review.date} • {review.issue}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <span className="text-white bg-green-500 rounded-full px-3 py-1 text-sm">
                                            {review.rating} ★
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <p className="text-gray-700 text-sm">{review.review}</p>
                                </div>
                                {/* Add a horizontal line except for the last review */}
                                {index < 4 && <hr className="my-4 border-gray-300" />}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingCommentModel
