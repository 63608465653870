

// Function to format date to YYYY-MM-DD
export const formatToYYYYMMDD = (isoDateString) => {
    if (!isoDateString) return "";
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};




// Function to clean and parse a description
export const cleanDescription = (description) => {
    try {
        // Remove backslashes and parse JSON
        const cleanedString = description.replace(/\\/g, ""); // Remove backslashes
        return JSON.parse(cleanedString);
    } catch (error) {
        console.error("Error parsing description:", error);
        return description; // Fallback if parsing fails
    }
};

// Function to clean and parse availableTime
export const cleanAvailableTime = (timeData) => {

    console.log("cleanAvailableTime",timeData)
    try {
        if (!timeData) return []; // Return empty array if null or empty

        if (typeof timeData === "string") {
            const cleanedString = timeData.trim().replace(/^"|"$/g, ""); // Remove surrounding quotes
            const unescapedString = cleanedString.replace(/\\/g, ""); // Remove backslashes
            console.log("=-=-=-=-",JSON.parse(unescapedString))
            return JSON.parse(unescapedString); // Parse JSON
        }

        if (Array.isArray(timeData)) {
            return timeData; // If already an array, return as is
        }

        return []; // Default to empty array
    } catch (error) {
        console.error("Error parsing availableTime:", error);
        return []; // Fallback
    }
};
