import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, loginApi, verifyPhoneOTP } from "../../Service/api-path"
import { CircularProgress, Snackbar } from "@mui/material";
import ReCAPTCHA from 'react-google-recaptcha'
import { RefreshCw } from "lucide-react";
import Navbar from "../Navbar";
import HeaderBanner from "../Home/HeaderBanner/HeaderBanner";
import { GoogleLogin } from '@react-oauth/google';
// import { useDispatch } from "react-redux";
// import io from 'socket.io-client';
// const socket = io('http://localhost:4956');

const InputField = ({ label, type, value, onChange, placeholder, errorMessage }) => (
  <div className="mb-4">
    <label className="block text-gray-700 font-semibold mb-2">{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`w-full p-3 border ${errorMessage ? "border-red-500" : "border-gray-300"} rounded-lg`}
    />
    {errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
  </div>
);

const LoginPage = ({currentAddress}) => {
  const [profile, setProfile] = useState([]);
  
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "", otp: "" });
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor
      (Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = '';
    for (let i = 0; i < 5; i++) {
      captcha += generateRandomChar(48, 57); // Generate a random digit (ASCII 48-57)
    }
    return captcha;
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)'];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = '20px Roboto Mono';
      ctx.fillStyle = textColors[Math.floor(
        Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput('');
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  const handleInputChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) setErrors({ ...errors, email: "" });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) setErrors({ ...errors, password: "" });
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    if (errors.otp) setErrors({ ...errors, otp: "" });
  };

  const validateInputs = () => {
    let valid = true;
    let newErrors = { email: "", password: "", otp: "" };

    if (isPhoneLogin) {
      if (!email) {
        newErrors.email = "Phone number is required.";
        valid = false;
      }
      if (isOtpSent && !otp) {
        newErrors.otp = "OTP is required.";
        valid = false;
      }
    } else {
      if (!email) {
        newErrors.email = "Email is required.";
        valid = false;
      }
      if (!password) {
        newErrors.password = "Password is required.";
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!userInput) {
      setLoading(false);
      alert("Please enter captcha.");
      return;
    }

    if (userInput !== captchaText) {
      setLoading(false);
      alert("Incorrect captcha. Please try again.");
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      initializeCaptcha(ctx);
      return;
    }

    setIsCaptchaVerified(true);

    if (!validateInputs()) {
      setLoading(false);
      alert("Please fill out all required fields correctly.");
      return;
    }

    // if (!validateInputs()) return;
    try {
      const payload = isPhoneLogin
        ? { phone: email, otp }
        : { email, password };

      console.log('loginApi: ', loginApi);

      const response = await loginApi(payload);
      console.log("Login success:", response.data);

      if (response.status === 201) {
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("profilePic", response.data.profilePic);

        setLoading(false);
        setMessage(response.data.msg);
        handleClick();
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error.response?.data || error.message);
      setMessage(error.response?.data?.msg || "Something went wrong. Please try again.");
      handleClick();
    }
    finally {
      setLoading(false);
    }
  };


  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
        const { credential } = credentialResponse;
        const res = await axios.get(
            `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${credential}`
        );
  
        console.log("Google Profile:", res.data);
        setProfile(res.data);
  
        const { email, name, picture } = res.data;
        let profilePic = picture || `https://ui-avatars.com/api/?name=${encodeURIComponent(name || email[0])}&background=random`;
  
        // Check if user already exists
        const checkUserResponse = await axios.post(`${API_URL}/api/user-auth/google-login`, {
            email,
        });
  
        console.log("Check User Response:", checkUserResponse.data);
  
        if (checkUserResponse.data.userExists && checkUserResponse.data.userExists.length > 0) {
            const userId = checkUserResponse.data.userExists[0].id;
      console.log(userId,"===userid====")
            // console.log("User already exists. Redirecting to home...");
            localStorage.setItem("user_id", userId);
            localStorage.setItem("email", email);
            localStorage.setItem("name", name);
            localStorage.setItem("profilePic", profilePic);
            localStorage.setItem("token", credential); 

            navigate("/");
            return;
        }
  
        // If user does not exist, save to database
        const saveResponse = await axios.post(`${API_URL}/api/user-auth/google-auth`, {
            email,
            name,
        });
  
        console.log("Save Response:", saveResponse.data);
        
        if (saveResponse.data.success) {
            console.log("User saved successfully. Fetching user ID...");
  
            // Fetch user ID after saving
            const newUserResponse = await axios.post(`${API_URL}/api/user-auth/google-login`, {
                email,
            });
  
            console.log("New User Response:", newUserResponse.data);
  
            if (newUserResponse.data.userExists && newUserResponse.data.userExists.length > 0) {
                const userId = newUserResponse.data.userExists[0].id;
  
                // Store in localStorage
                localStorage.setItem("user_id", userId);
                localStorage.setItem("email", email);
                localStorage.setItem("name", name);
                localStorage.setItem("profilePic", profilePic);
                localStorage.setItem("token", credential); 

  
                navigate("/");
            }
        }
    } catch (err) {
        if (err.response) {
            console.error("Server Error:", err.response.status, err.response.data);
        } else {
            console.error("Network/Request Error:", err.message);
        }
    }
  };

  const handleSendOtp = async () => {
    if (!email) {
      setErrors({ ...errors, email: "Phone number is required." });
      return;
    }

    try {
      const response = await loginApi({ phone: email });
      console.log("OTP sent:", response.data);
      if (response.status === 201) {
        setMessage(response.data.msg);
        handleClick();
        setIsOtpSent(true);
      }
    } catch (error) {
      console.error("Send OTP error:", error.response?.data || error.message);
      setApiError("Failed to send OTP. Please try again.");
      setMessage(error.response?.data?.msg || "something went wrong. Please try again.");
      handleClick();
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      // setErrors({ ...errors, otp: "OTP is required." });
      handleClick();
      return;
    }

    try {
      const response = await verifyPhoneOTP({ phone: email, otp });
      console.log("OTP verified:", response.data);
      if (response.status === 200) {
        setMessage(response.data.msg);
        handleClick();
        setOtpVerified(true);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("name", response.data.name);
        // dispatch(login({ user: response.data.user_id }));
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
    } catch (error) {
      console.error("Verify OTP error:", error.response?.data || error.message);
      // setApiError("Invalid OTP. Please try again.");
      setMessage(error.response?.data?.msg || "Invalid OTP. Please try again.");
      handleClick();
    }
  };

  return (
    <>
      <Navbar currentAddress={currentAddress}/>
      <HeaderBanner />

      <div className="min-h-screen flex justify-center items-center bg-white p-4">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md border-[0.2px] border-black">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Login to Your Account</h2>

          {apiError && <p className="text-red-500 text-center mb-4">{apiError}</p>}
          

         
          
          <div className="flex justify-around mb-4 gap-2">
            {/* <button
              onClick={() => setIsPhoneLogin(false)}
              className={`py-2 px-4 rounded-lg ${!isPhoneLogin ? "bg-blue-500 text-white" : "bg-gray-300"}`}
            >
              Login with Email
            </button> */}
            {/* <button
              onClick={() => setIsPhoneLogin(true)}
              className={`py-2 px-4 rounded-lg ${isPhoneLogin ? "bg-blue-500 text-white" : "bg-gray-300"}`}
            >
              Login with Phone
            </button> */}
          </div>

          <form onSubmit={handleSubmit}>
            {isPhoneLogin ? (
              <>
                <InputField
                  required
                  label="Phone"
                  type="text"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                  errorMessage={errors.email}
                />
                {isOtpSent && !otpVerified && (
                  <>
                    <InputField
                      label="OTP"
                      required
                      type="text"
                      value={otp}
                      onChange={handleOtpChange}
                      placeholder="Enter OTP"
                      errorMessage={errors.otp}
                    />
                    <button
                      type="button"
                      onClick={handleVerifyOtp}
                      className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
                    >
                      Verify OTP
                    </button>
                  </>
                )}
                {!isOtpSent && (
                  <button
                    type="button"
                    onClick={handleSendOtp}
                    className="w-full bg-yellow-500 text-white py-3 rounded-lg hover:bg-yellow-600"
                  >
                    Send OTP
                  </button>
                )}
              </>
            ) : (
              <>
                <InputField
                  label="Email"
                  required
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  errorMessage={errors.email}
                />
                <InputField
                  required
                  label="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                  errorMessage={errors.password}
                />

              </>
            )}
            <div className="">
              <div className="container border-gray-400 border mb-1">
                <div className="wrapper">
                  <div className="flex justify-between items-center gap-2">
                    <canvas ref={canvasRef}
                      width="200"
                      height="70"
                      className="w-[40%] max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg  "
                    >

                    </canvas>
                    <RefreshCw className="cursor-pointer" size={16} onClick={
                      () => initializeCaptcha(
                        canvasRef.current.getContext('2d'))} />
                    <input
                      type="text"
                      id="user-input"
                      className="w-[30%] p-1 mr-1"
                      placeholder="Enter Captcha"
                      value={userInput}
                      onChange={handleUserInputChange} />
                  </div>
                  
                </div>
              </div>
              
            </div>
   

            <div className="mb-3 text-right flex justify-between">
              
              <p
                onClick={() => navigate("/register")}
                className="text-blue-500 underline cursor-pointer hover:text-blue-700"
              >
                Sign Up
              </p>
              <p
                onClick={() => navigate("/login/forgot-password")}
                className="text-blue-500 underline cursor-pointer hover:text-blue-700"
              >
                Forgot Password?
              </p>
            </div>

            <p className="flex justify-center items-center mb-2 text-[18px]">Or Login with:</p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-6">
        <GoogleLogin
         onSuccess={handleGoogleLoginSuccess}
         onError={() => alert("Google Login Failed!")}
           />
          </div>
            <button
              type="submit"
              className={`w-full py-3 rounded-lg cursor-pointer ${(!isPhoneLogin && email && password) || (isPhoneLogin && otpVerified) ? "bg-blue-500" : "bg-blue-600"
                } text-white font-semibold hover:bg-blue-400 `}
              disabled={!((!isPhoneLogin && email && password) || (isPhoneLogin && otpVerified))}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </button>

        
          </form>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </div>
    </>
  );
};

export default LoginPage;
