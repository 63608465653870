import React from 'react';
import promoImage from '../assets/promobg.png';
import './Promo.css'
import { useLottie } from "lottie-react";
import PromoLottie from '../animations/Promo.json'
import logo from '../assets/oys_logo.png'
import { Link } from 'react-router-dom';

const Promo = () => {

    const options = {
        animationData: PromoLottie,
        autoplay: true,
        loop: true,
    };

    const { View } = useLottie(options);
    return (
        <div
            style={{
                backgroundImage: `url(${promoImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '150vh',
                width: '100%',
            }}
        >
            <div className='flex flex-col items-center'
                style={{
                    height: '100%',
                    color: 'white',
                    // textShadow: '2px 2px 5px rgba(0, 0, 0, 0.7)',
                    textAlign: 'center',
                }}
            >
                <div className='flex py-5   flex-col rowdies-light '>
                    <h1 className='font-semibold flex flex-col text-3xl  text-white'>Welcome To  On Your Service Platform !!</h1>

                    <p class=" text-gray-100 dark:text-gray-400 py-1">"OnYour Service" brings the best services to your doorstep. With just a few clicks, relax and let us take care of everything!</p>

                </div>

                {/* <div className=' py-3 px-5 md:px-0 '>
                    <div className=''>
                        <a class="flex h-[450px]  flex-col  bg-white  border-gray-200 rounded-lg shadow md:flex-row md:max-w-5xl  ">

                            <div className="flex justify-center items-center  flex-col  ">
                                <div className='w-[290px]    h-[200px] flex  items-center md:w-[290px] md:h-[290px]'>
                                    {View}

                                </div>
                                <p className='text-gray-800 font-bold rowdies-light'>From salon and spa treatments to home cleaning, repairs, and renovation, we cater to all your needs.</p>
                            </div>


                            <div class="flex flex-col rowdies-light  shadow-lg p-4 leading-normal">
                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-blue-500 dark:text-white">Choose Who You Wanna Be !</h5>

                            </div>
                        </a>
                    </div>

                </div> */}

<div className="py-3 px-5 md:px-0">
      <div className="flex flex-col max-w-4xl md:flex-row h-auto md:h-[450px] bg-white border-gray-200 rounded-lg shadow">
        {/* Left Section */}
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 p-4">
          <div className="w-[290px] h-[200px] flex items-center md:w-[290px] md:h-[290px]">
            {View}
          </div>
          <p className="text-gray-800 font-bold text-center px-4">
            From salon and spa treatments to home cleaning, repairs, and renovation, we cater to all your needs.
          </p>
        </div>

        {/* Right Section */}
        <div className="flex flex-col justify-center items-center w-full md:w-1/2 p-4">
        <img src={logo}  className='w-[200px] h-[150px]'/>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-500">
            Choose Who You Wanna Be!
          </h5>
          <p className="text-gray-600 text-center mb-4">
            Sign up now as a provider to offer services or join as a user to explore our platform.
          </p>
          <div className="flex flex-col md:flex md:flex-row gap-4">
            <Link to='/provider-register' className="bg-blue-500 font-bold text-white px-4 py-2 rounded hover:bg-blue-600">
              Sign Up as Service Provider
            </Link>
            <Link to='/register' className="bg-green-500  font-bold text-white px-4 py-2 rounded hover:bg-green-600">
              Sign Up as User
            </Link>
          </div>
        </div>
      </div>
    </div>

            </div>



        </div>
    );
};

export default Promo;
