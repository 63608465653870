// import React, { useState } from 'react';
// import image from '../../assets/loginprovider.jpg';
// import { useLottie } from "lottie-react";
// import userlogo from '../../animations/userlogo.json';
// import axios from 'axios';

// import Cookies from 'js-cookie'
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import { providerLoginByNumber, providerLoginbyEmail,forgotProviderPassword } from '../../Service/provider-api-path';
// import { Link } from 'react-router-dom';
// import { Vortex } from 'react-loader-spinner'
// import {useNavigate} from 'react-router-dom'

// const ProviderLogin = () => {
//   const [isPhoneLogin, setIsPhoneLogin] = useState(false);
//   const [formData, setFormData] = useState({ email: '', phone: '', password: '' });
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
//   const [loading, setLoading] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [resetEmail, setResetEmail] = useState('');

//   const options = {
//     animationData: userlogo,
//     autoplay: true,
//     loop: false,
//   };


//   const navigate=useNavigate()
//   const { View } = useLottie(options);

//   const toggleLoginMethod = () => {
//     setIsPhoneLogin(!isPhoneLogin);
//     setFormData({ email: '', phone: '', password: '' }); // Reset form data when toggling
//   };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     try {
//       const response = isPhoneLogin
//         ? await axios.post(providerLoginByNumber, {
//             phone: formData.phone,
//             password: formData.password,
//           })
//         : await axios.post(providerLoginbyEmail, {
//             email: formData.email,
//             password: formData.password,
//           });


//           console.log("Login response", response.data)
//       if (response.status === 200) {
//         setLoading(false)
//         setSnackbarMessage('Login successful! Redirecting...');
//         setSnackbarSeverity('success');
//         setSnackbarOpen(true);
//         Cookies.set("name",response.data.name)
//         Cookies.set("email",response.data.email)
//         Cookies.set("providerid",response.data.provider_id)
//         setTimeout(() => {
//          navigate('/provider-homepage')
//         }, 3000);
//       } else {
//         throw new Error(response.data.message || 'Login failed.');
//       }
//     } catch (error) {
//       setLoading(false)

//       setSnackbarMessage(error.response?.data?.message || 'An error occurred.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleResetPassword = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(forgotProviderPassword, { email: resetEmail });
//       console.log("Response reset password", response.data)
//       if (response.status === 200) {
//         setSnackbarMessage('Password reset link sent to your email.');
//         setSnackbarSeverity('success');
//       } else {
//         setSnackbarMessage('Error sending password reset link.');
//         setSnackbarSeverity('error');
//       }
//       setSnackbarOpen(true);
//     } catch (error) {
//       setSnackbarMessage('Error sending password reset link.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   };

//   return (
//     <div className="flex justify-center items-center min-h-screen ">
//     {/* Form Section */}
//     <div className="hidden  w-full mt-[-10px] md:mt-0 md:w-1/2 md:flex items-center justify-center">
//         <img
//             src={image}
//             alt="Provider Signup Illustration"
//             className="max-h-[600px] object-contain"
//         />
//     </div>

//     <div className="flex justify-center  bg-white p-4  w-full max-w-3xl">


//         <div class="w-full max-w-sm p-2 shadow-lg shadow-[#2A454E] bg-white border border-gray-200 rounded-lg  sm:p-6 md:p-4 dark:bg-gray-800 dark:border-gray-700">
//         <form className="space-y-4" onSubmit={handleSubmit}>
//             <div className="flex justify-center items-center">
//               <div className="w-[90px] h-[80px]">{View}</div>
//             </div>
//             <h2 className="text-2xl text-[#2A454E] font-bold text-center">
//               Provider Login
//             </h2>

//             {/* Toggle Button */}
//             <div>
//               <button
//                 type="button"
//                 onClick={toggleLoginMethod}
//                 className="text-blue-600 font-bold text-sm dark:text-blue-400"
//               >
//                 {isPhoneLogin
//                   ? 'Login with Email?'
//                   : 'Login with Phone Number?'}
//               </button>
//             </div>

//             {/* Dynamic Input Fields */}
//             {isPhoneLogin ? (
//               <div>
//                 <input
//                   type="text"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                   placeholder="Enter Phone Number"
//                   className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
//                   required
//                 />
//               </div>
//             ) : (
//               <div>
//                 <input
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   placeholder="Enter Email"
//                   className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
//                   required
//                 />
//               </div>
//             )}
//             <div>
//               <input
//                 type="password"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 placeholder="Enter Password"
//                 className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
//                 required
//               />
//             </div>


//                 <div class="flex items-start">

//                 <a
      
//       onClick={(e) => {
//         e.preventDefault();
//         toggleModal();
//       }}
//       className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500"
//     >
//       Forget Password?
//     </a>
//                 </div>


// <div className='flex justify-center mx-auto mt-5'>
// {loading?
//  (
//   <button type="submit" class="w-full items-center flex justify-center text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> <Vortex
//   visible={true}
//   height="30"
//   width="30"
//   ariaLabel="vortex-loading"
//   colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
// /></button>
  
// ):
// <button type="submit" class="w-full text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>}
// </div>

                




//                 <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
//                     Not registered? <a  class="text-blue-700 hover:underline dark:text-blue-500"><Link to='/provider-register   '>Create account</Link></a>
//                 </div>
//             </form>
//         </div>



//     </div>



//     {/* =========================== */}

//      {isModalOpen && (
// <div
//   className="fixed  px-4 inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
//   onClick={toggleModal}
// >
//   <div
//     className="bg-white rounded-lg shadow dark:bg-gray-700"
//     onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
//   >

// <div className='flex justify-end'>
//         <button
//         type="button"
//         onClick={toggleModal}
//         className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8  flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
//       >
//         <svg
//           className="w-3 h-3"
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 14 14"
//         >
//           <path
//             stroke="currentColor"
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
//           />
//         </svg>
//         <span className="sr-only">Close modal</span>
//       </button>
//             </div>
//     <div className="flex flex-col items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
        

//       <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
//         Password Reset 
//       </h3>
//       <p className='text-sm font-semibold py-2 text-gray-700  '>Forgot your password ? Enter your email address below.</p>
  
//     </div>

//     <div className="p-4">
//     <form  className="space-y-4" onSubmit={handleResetPassword}>
//         <div>
//         <label htmlFor="resetEmail">Enter your email below</label>
//           <input
//            className="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
//             placeholder="user@example.com"
//                   type="email"
//                   id="resetEmail"
//                   name="resetEmail"
//                   value={resetEmail}
//                   onChange={(e) => setResetEmail(e.target.value)}
//                   required
//                 />
//         </div>
        
//         <button
//           type="submit"
//           className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   </div>
// </div>
// )}


// <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar> 
// </div>
//   );
// };

// export default ProviderLogin;

// ===========================================================================



import React, { useState } from 'react';
import image from '../../assets/loginprovider.jpg';
import { useLottie } from "lottie-react";
import userlogo from '../../animations/userlogo.json';
import axios from 'axios';

import Cookies from 'js-cookie'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { providerLoginByNumber, providerLoginbyEmail,forgotProviderPassword } from '../../Service/provider-api-path';
import { Link } from 'react-router-dom';
import { Vortex } from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'

const ProviderLogin = () => {
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);
  const [formData, setFormData] = useState({ email: '', phone: '', password: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  const options = {
    animationData: userlogo,
    autoplay: true,
    loop: false,
  };


  const navigate=useNavigate()
  const { View } = useLottie(options);

  const toggleLoginMethod = () => {
    setIsPhoneLogin(!isPhoneLogin);
    setFormData({ email: '', phone: '', password: '' }); // Reset form data when toggling
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = isPhoneLogin
        ? await axios.post(providerLoginByNumber, {
            phone: formData.phone,
            password: formData.password,
          })
        : await axios.post(providerLoginbyEmail, {
            email: formData.email,
            password: formData.password,
          });


          console.log("Login response", response.data)
      if (response.status === 200) {
        setLoading(false)
        setSnackbarMessage('Login successful! Redirecting...');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        Cookies.set("name",response.data.name)
        Cookies.set("email",response.data.email)
        Cookies.set("providerid",response.data.provider_id)
        setTimeout(() => {
         navigate('/provider-homepage')
        }, 3000);
      } else {
        throw new Error(response.data.message || 'Login failed.');
      }
    } catch (error) {
      setLoading(false)

      setSnackbarMessage(error.response?.data?.message || 'An error occurred.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(forgotProviderPassword, { email: resetEmail });
      console.log("Response reset password", response.data)
      if (response.status === 200) {
        setSnackbarMessage('Password reset link sent to your email.');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Error sending password reset link.');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error sending password reset link.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen ">
    {/* Form Section */}
    <div className="hidden  w-full mt-[-10px] md:mt-0 md:w-1/2 md:flex items-center justify-center">
        <img
            src={image}
            alt="Provider Signup Illustration"
            className="max-h-[600px] object-contain"
        />
    </div>

    <div className="flex justify-center  bg-white p-4  w-full max-w-3xl">


        <div class="w-full max-w-sm p-2 shadow-lg shadow-[#2A454E] bg-white border border-gray-200 rounded-lg  sm:p-6 md:p-4 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="flex justify-center items-center">
              <div className="w-[90px] h-[80px]">{View}</div>
            </div>
            <h2 className="text-2xl text-[#2A454E] font-bold text-center">
              Provider Login
            </h2>

            {/* Toggle Button */}
            <div>
              <button
                type="button"
                onClick={toggleLoginMethod}
                className="text-blue-600 font-bold text-sm dark:text-blue-400"
              >
                {isPhoneLogin
                  ? 'Login with Email?'
                  : 'Login with Phone Number?'}
              </button>
            </div>

            {/* Dynamic Input Fields */}
            {isPhoneLogin ? (
              <div>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter Phone Number"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                  required
                />
              </div>
            ) : (
              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                  required
                />
              </div>
            )}
            <div>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter Password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                required
              />
            </div>


                <div class="flex items-start">

                <a
      
      onClick={(e) => {
        e.preventDefault();
        toggleModal();
      }}
      className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500"
    >
      Forget Password?
    </a>
                </div>


<div className='flex justify-center mx-auto mt-5'>
{loading?
 (
  <button type="submit" class="w-full items-center flex justify-center text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> <Vortex
  visible={true}
  height="30"
  width="30"
  ariaLabel="vortex-loading"
  colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
/></button>
  
):
<button type="submit" class="w-full text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login to your account</button>}
</div>

                




                <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
                    Not registered? <a  class="text-blue-700 hover:underline dark:text-blue-500"><Link to='/provider-register   '>Create account</Link></a>
                </div>
            </form>
        </div>



    </div>



    {/* =========================== */}

     {isModalOpen && (
<div
  className="fixed  px-4 inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
  onClick={toggleModal}
>
  <div
    className="bg-white rounded-lg shadow dark:bg-gray-700"
    onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
  >

<div className='flex justify-end'>
        <button
        type="button"
        onClick={toggleModal}
        className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8  flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          className="w-3 h-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span className="sr-only">Close modal</span>
      </button>
            </div>
    <div className="flex flex-col items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
        

      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
        Password Reset 
      </h3>
      <p className='text-sm font-semibold py-2 text-gray-700  '>Forgot your password ? Enter your email address below.</p>
  
    </div>

    <div className="p-4">
    <form  className="space-y-4" onSubmit={handleResetPassword}>
        <div>
        <label htmlFor="resetEmail">Enter your email below</label>
          <input
           className="bg-gray-50 mt-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="user@example.com"
                  type="email"
                  id="resetEmail"
                  name="resetEmail"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  required
                />
        </div>
        
        <button
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
)}


<Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar> 
</div>
  );
};

export default ProviderLogin;
