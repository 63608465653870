import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SocketProvider } from './context/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(



  // <GoogleOAuthProvider clientId="611059867743-qc53svmr5on80la76iv6obu13jh67u0n.apps.googleusercontent.com">

    <React.StrictMode>
       <GoogleOAuthProvider clientId="717908879621-r3j7lvg1jsor1qu5un0ocpjra3hr936i.apps.googleusercontent.com">
      <SocketProvider>
        <App />
  
      </SocketProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
