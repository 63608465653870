


import React, { useState } from "react";
import { StandaloneSearchBox, GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FiX } from "react-icons/fi";
import { Snackbar } from "@mui/material";
import { getProviderOnDemand } from "../../Service/api-path"; // Adjust the import path as necessary

function AddressModal({ isAddressOpen, toggleAddressModal, subCatId, onProviderCheck }) {
    console.log("SUB CAT ID ADDRESS ",subCatId)
  const [searchBox, setSearchBox] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [address, setAddress] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(markerPosition,"====position")
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });
 
  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places?.length > 0) {
        const { geometry, formatted_address } = places[0];
        if (geometry && geometry.location) {
          const position = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          setMarkerPosition(position);
          setAddress(formatted_address);
        }
      }
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSave = async () => {
    if (!markerPosition || !subCatId) {
      setSnackbarMessage("Please select a valid address and try again.");
      setSnackbarOpen(true);
      return;
    }
  
    try {
      setLoading(true);
      const response = await getProviderOnDemand({
        lat: markerPosition.lat,
        lng: markerPosition.lng,
        sub_cat_id: subCatId,
        radius: 5,
      });
  
      if (response?.data?.result?.length > 0) {
        // Pass data back to the parent
        onProviderCheck(markerPosition, address); 
        toggleAddressModal(); // Close modal
      } else {
        setSnackbarMessage("No service providers available in the selected area.");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error checking providers:", error);
      setSnackbarMessage("An error occurred while checking providers.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  

  return isAddressOpen ? (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white w-[90%] p-6 md:w-1/3 rounded-lg shadow-lg relative">
        <button
          onClick={toggleAddressModal}
          className="absolute top-2 right-3 text-gray-500 hover:text-gray-800"
        >
          <FiX size={20} />
        </button>
        <h2 className="text-lg font-bold mb-4">Search for a location</h2>
        {isLoaded ? (
          <>
            <StandaloneSearchBox
              onLoad={(ref) => setSearchBox(ref)}
              onPlacesChanged={handlePlacesChanged}
            >
              <input
                type="text"
                placeholder="Search for your location"
                className="w-full p-2 border rounded-md"
              />
            </StandaloneSearchBox>
            <div className="mt-3">
              <GoogleMap
                center={markerPosition || { lat: 37.7749, lng: -122.4194 }}
                zoom={12}
                mapContainerStyle={{ width: "100%", height: "200px" }}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            </div>
            <textarea
              value={address}
              readOnly
              className="w-full mt-3 p-2 border rounded-md"
              placeholder="Selected Address"
            ></textarea>
            <div className="mt-4 text-center">
              <button
                onClick={handleSave}
                className={`px-6 py-2 text-white rounded-lg ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"}`}
                disabled={loading}
              >
                {loading ? "Checking..." : "Save"}
              </button>
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  ) : null;
}

export default AddressModal;





