
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../Service/api-path";
import { AiOutlineClose } from "react-icons/ai";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getUserDetails();
        setUserData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user profile.");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user_id]);

  const handleClose = () => {
    navigate(-1);
  };

  const formattedDate = userData?.createdon?.split('T')[0];

  return (
    <div className="bg-gradient-to-r from-purple-200 min-h-screen flex justify-center items-center">
      <div className="w-full max-w-2xl bg-white rounded-3xl shadow-lg overflow-hidden relative">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
          onClick={handleClose}
        >
          <AiOutlineClose size={24} color="black" />
        </button>
        <div className="h-32 bg-gradient-to-r from-red-400 to-blue-400"></div>
        <div className="flex flex-col items-center -mt-16">
          <img
            className="w-32 h-32 rounded-full border-4 border-white"
            src={userData?.profilePic}
            alt="User Profile"
          />
          <h2 className="text-2xl font-bold text-gray-900 mt-2">{userData?.name || "User Name"}</h2>
          <p className="text-gray-500">{userData?.email || "user@example.com"}</p>
          <p className="text-gray-600 mt-4">Created On: {formattedDate || "N/A"}</p>
          <div className="bg-gray-100 border border-gray-300 rounded-lg p-4 mb-6 mt-4 w-3/4 text-center">
            <h3 className="text-lg font-semibold text-gray-800">Address</h3>
            <p className="text-gray-600">{userData?.Address || "No address available"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
