
import React, { useState, useEffect } from 'react'
import image from '../../assets/providerSignup.jpg'
import './ProviderRegister.css'
import axios from 'axios'
import { getMasterCategory, getSubCategory, subcategoryservice, providerAuthRegister } from '../../Service/provider-api-path'
import '../MapModal/Modal.css'
import userlogo from '../../animations/userlogo.json'
import { useLottie } from "lottie-react";
import { Vortex } from 'react-loader-spinner'
import { useNavigate, Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useAddress from '../../lib/userAddress'
import moment from 'moment'
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { FaLocationArrow } from "react-icons/fa";
import Modal from "react-modal";
import "leaflet/dist/leaflet.css";
Modal.setAppElement("#root");
const ProviderRegister = () => {


  const [masterServices, setMasterServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  // const [fields, setFields] = useState([0]); // Default one div
  const [price, setPrice] = useState("");
  console.log("price", price)
  const [docType, setDocType] = useState('');
  const [docNumber, setDocNumber] = useState('');
  const [fields, setFields] = useState([{ image: null, description: '' }]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [showMapModal, setShowMapModal] = useState(false);


  const navigate = useNavigate();

  // Form submits code 

  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
    password: '',
    phone: '',
    DOB: '',
    masterId: '',
    cat_id: '',
    sub_cat_id: [],  // Store selected sub_cat_id
    price: '',
    address: '',          // Store address
    providerImage: null,      // Store the selected providerImage file
    documentType: '',    // Store selected document type
    documentNumber: '',
    availableTime: [],
    suggestion: '',
    document: null,
    latitude: null,
    longitude: null,
  });

  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places']
  });
  const [newTimeSlot, setNewTimeSlot] = useState("");
  const [timeRange, setTimeRange] = useState({ startTime: "", endTime: "" });




  const handleMapOpen = () => setIsOpen(true);
  const handleMapClose = () => setIsOpen(false);

  const handleMapLoad = (map) => setMap(map);

  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places?.length > 0) {
        const { geometry, formatted_address } = places[0];
        if (geometry && geometry.location) {
          const position = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          setMarkerPosition(position);
          setFormData((prev) => ({ ...prev, address: formatted_address, latitude: position.lat, longitude: position.lng }));
        }
      }
    }
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const address = await reverseGeocode(latitude, longitude);
          setFormData({ ...formData, address, latitude, longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        (error) => console.error(error.message)
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.results?.[0]?.formatted_address || "";
    } catch (error) {
      console.error("Error reverse geocoding:", error);
      return "";
    }
  };

  const handleTimeRangeChange = (e) => {
    const { name, value } = e.target;
    setTimeRange({
      ...timeRange,
      [name]: value, // Update startTime or endTime
    });
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12; // Convert 24-hour time to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")}${period}`;
  };



  const handleAddTimeRange = () => {
    if (timeRange.startTime && timeRange.endTime) {
      const formattedStartTime = formatTime(timeRange.startTime);
      const formattedEndTime = formatTime(timeRange.endTime);

      const formattedTimeRange = `${formattedStartTime} - ${formattedEndTime}`;

      const newTimeSlot = {
        "time": formattedTimeRange, // Key explicitly set as a string
      };

      console.log("NEW TIME SLOT", JSON.stringify(newTimeSlot, null, 2)); // Log properly formatted JSON

      setFormData({
        ...formData,
        availableTime: [...formData.availableTime, newTimeSlot],
      });

      // Clear the time range inputs
      setTimeRange({ startTime: "", endTime: "" });

      // Log the entire availableTime array as JSON
      console.log("Available Time:", JSON.stringify([...formData.availableTime, newTimeSlot], null, 2));
    }
  };



  const handleRemoveTimeRange = (index) => {
    setFormData({
      ...formData,
      availableTime: formData.availableTime.filter((_, i) => i !== index), // Remove the object at the given index
    });
  };


  const handleNewTimeSlotChange = (e) => {
    setNewTimeSlot(e.target.value);
  };

  const handleAddTimeSlot = () => {
    if (newTimeSlot.trim() !== "") {
      setFormData({
        ...formData,
        availableTime: [...formData.availableTime, newTimeSlot], // Add new time slot
      });
      setNewTimeSlot(""); // Clear the input field
    }
  };

  const handleRemoveTimeSlot = (index) => {
    setFormData({
      ...formData,
      availableTime: formData.availableTime.filter((_, i) => i !== index), // Remove specific time slot
    });
  };

  console.log("Form data", formData)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };
  const handleChange = (e) => {

    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      if (name === "DOB") {
        const selectedDate = moment(value);
        const currentDate = moment();
        const age = currentDate.diff(selectedDate, "years");

        // Check if the selected date is in the future
        if (selectedDate.isAfter(currentDate, "day")) {
          setSnackbarMessage("Future dates are not allowed. Please select a valid date of birth.");
          setSnackbarSeverity("error");
          setOpen(true);
          return;
        }

        // Check if the user is under 18
        if (age < 18) {
          setSnackbarMessage("You must be at least 18 years old.");
          setSnackbarSeverity("error");
          setOpen(true);
          return;
        }

        // Reset error message if valid
        setSnackbarMessage("");
      }
      setFormData({ ...formData, [name]: value });
    }
  };





  const handleUploadDocChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setFormData((prevState) => ({
      ...prevState,
      document: file, // Store the selected file
    }));
  };

  const handlesuggestionChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = new FormData();
    for (const key in formData) {
      if (key === "cat_id" || key === "sub_cat_id" || key === "availableTime") {
        payload.append(key, JSON.stringify(formData[key]));
      } else {
        payload.append(key, formData[key]);
      }
    }

    fields.forEach((field, index) => {
      if (field.image) {
        payload.append(`images${index + 1}`, field.image);
      }
      // if (field.description) {
      //   payload.append(`description${index + 1}`, field.description);
      // }
    });

    const descriptions = fields.map((field) => field.description);
    payload.append("description", JSON.stringify(descriptions));


    try {
      const response = await axios.post(providerAuthRegister, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage('Registration successful!');
        setSnackbarSeverity('success');
        setOpen(true);
        setTimeout(() => navigate('/otp-verification', { state: { email: formData.email } }), 3000);
      }
    } catch (error) {
      console.error('Error:', error.response.data.message);
      setSnackbarMessage(error.response.data.message);
      setSnackbarSeverity('error');
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };


  const handlePrice = (e) => {
    setFormData({
      ...formData,
      price: e.target.value,
    });
  };


  const handleThumbnailChange = (e) => {
    setFormData({
      ...formData,
      providerImage: e.target.files[0],  // Store the selected file
    });
  };

  const handleAddressChange = (e) => {
    setFormData({
      ...formData,
      address: e.target.value,
    });
  };


  const handleSelectLocation = ({ address, latitude, longitude }) => {
    setFormData({ ...formData, address, latitude, longitude });
  };

  //   -----------------------------------------------------------------

  const options = {
    animationData: userlogo,
    autoplay: true,
    loop: false

    // loop: true
  };

  const { View } = useLottie(options);
  // Fetch master services on component mount
  useEffect(() => {
    const fetchMasterServices = async () => {
      try {
        const response = await axios.get(getMasterCategory);
        console.log("Response master services", response.data)
        setMasterServices(response.data);
      } catch (error) {
        console.error("Error fetching master services:", error);
      }
    };

    fetchMasterServices();
  }, []);

  // Fetch categories when a master service is selected
  const fetchCategories = async (serviceId) => {
    try {
      const response = await axios.get(`${getSubCategory}?masterid=${serviceId}`);
      setCategories(response.data);
      setSelectedCategory(""); // Reset selected category when master service changes
      setSubCategories([]); // Reset subcategories
      setSelectedSubCategory(""); // Reset selected subcategory
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch subcategories when a category is selected
  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await axios.get(`${subcategoryservice}/${categoryId}`);
      console.log("sub category", response.data)
      setSubCategories(response.data);
      setSelectedSubCategory(""); // Reset subcategory
      setPrice(""); // Reset price
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  // Handle master service selection
  const handleMasterServiceChange = (event) => {
    setFormData({ ...formData, masterId: event.target.value });
    const serviceId = event.target.value;
    setSelectedService(serviceId);
    fetchCategories(serviceId); // Fetch categories based on selected serviceId
  };

  // Handle category selection
  const handleCategoryChange = (event) => {
    const selectedId = Number(event.target.value); // Convert to number
    setFormData({ ...formData, cat_id: selectedId });
    fetchSubCategories(selectedId); // Fetch subcategories based on selected category
  };


  const handleSubCategoryChange = (event) => {
    const selectedId = event.target.value;
    const updatedSubCategories = formData.sub_cat_id.includes(selectedId)
      ? formData.sub_cat_id.filter((id) => id !== selectedId)
      : [...formData.sub_cat_id, selectedId];

    setFormData({ ...formData, sub_cat_id: updatedSubCategories });
  };

  // Function to add a new field
  const addField = () => {
    if (fields.length < 3) {
      setFields([...fields, { image: null, description: '' }]);
    }
  };

  // Remove a field
  const removeField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  // Handle file upload
  const handleFileChange = (index, file) => {
    const updatedFields = [...fields];
    updatedFields[index].image = file;
    console.log("updatedFields", updatedFields)
    setFields(updatedFields);
  };


  const handleDescriptionChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index].description = value;
    console.log("updatedFieldsdescription", updatedFields)
    setFields(updatedFields);
  };


  const handleDocumentTypeChange = (e) => {
    setFormData({
      ...formData,
      documentType: e.target.value,
      documentNumber: "", // Clear number field when type changes
    });
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value;

    if (formData.documentType === "Aadhar Card") {
      // Remove non-digit characters and limit to 12 digits
      value = value.replace(/\D/g, "").slice(0, 12);

      // Format Aadhar as XXXX-XXXX-XXXX
      value = value
        .replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3")
        .replace(/-{1,2}$/, ""); // Ensure proper hyphen placement
    } else if (formData.documentType === "PAN Card") {
      // Allow only alphanumeric, limit to 10 characters
      value = value.replace(/[^A-Za-z0-9]/g, "").slice(0, 10);
    }

    setFormData({
      ...formData,
      documentNumber: value,
    });
  };

  const validateDocumentNumber = () => {
    const { documentType, documentNumber } = formData;

    if (documentType === "Aadhar Card") {
      // Validate Aadhar (12 digits)
      const aadharRegex = /^\d{4}-\d{4}-\d{4}$/;
      return aadharRegex.test(documentNumber)
        ? "Valid Aadhar Number"
        : "Invalid Aadhar Number";
    } else if (documentType === "PAN Card") {
      // Validate PAN (10 alphanumeric characters)
      const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      return panRegex.test(documentNumber)
        ? "Valid PAN Number"
        : "Invalid PAN Number";
    }
    return "Please select a document type.";
  };

  const fetchLatLong = async () => {
    if (formData.address.trim() === "") return; // Skip if the address is empty

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; // Your Google API Key
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      formData.address
    )}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setFormData((prev) => ({
          ...prev,
          latitude: location.lat,
          longitude: location.lng,
        }));
        console.log("Lat::::::::", location.lat, "Lng:", location.lng); // Debug
      } else {
        console.error("Geocoding failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching latitude and longitude:", error);
    }
  };




  return (
    <div className="flex justify-center items-center min-h-screen ">
      {/* Form Section */}

      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl">
        <a href="#" class=" container mx-auto block max-w-lg p-4 bg-white border border-gray-200 rounded-lg shadow-lg shadow-[#2A454E]">
          {/*  hide-scrollbar */}
          <div className="max-h-[500px] py-5 overflow-y-auto">
            <form onSubmit={handleSubmit} >
              <div className=' mb-2 flex justify-center items-center  '>
                <div className='w-[90px] h-[80px]'>

                  {View}
                </div>
              </div>
              <h2 className="text-2xl  text-[#2A454E] font-bold mb-3 text-center">Provider Register</h2>
              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="username"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Name
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="number"
                    name="age"
                    id="age"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.age}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="age"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Age
                  </label>
                </div>
              </div>

              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 focus:outline-none peer"
                    placeholder=" "
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email address
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="password"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Password
                  </label>
                </div>
              </div>



              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="phone"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Phone Number
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="date"
                    name="DOB"
                    id="DOB"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.DOB}
                    onChange={handleChange}
                    required
                  />
                  <label
                    htmlFor="DOB"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Date of Birth
                  </label>
                </div>

              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <select
                    id="dropdown_masterservice"
                    name="masterservice"
                    value={formData.masterId}
                    onChange={handleMasterServiceChange}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    required
                  >
                    <option value="" disabled>Select Master Service</option>
                    {masterServices.map((service) => (
                      <option key={service.id} value={service.masterId}>
                        {service.masterName}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="dropdown_masterservice"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Master Service
                  </label>
                </div>

                {/* Subcategory Dropdown */}
                <div className="relative z-0 w-full mb-5 group">
                  <select
                    id="dropdown_category"
                    name="cat_id"
                    value={formData.cat_id}
                    onChange={handleCategoryChange}
                    // multiple
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    required
                  >
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.cat_id}>
                        {category.cat_name}
                      </option>
                    ))}
                  </select>
                  <label
                    htmlFor="dropdown_category"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Category
                  </label>
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <div
                    id="subCategory"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                  >
                    <div className="text-gray-900 dark:text-gray-400 mb-2">
                      Select a subcategory
                    </div>
                    {subCategories.map((subCategory) => (
                      <div key={subCategory.id} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          id={`subCategory-${subCategory.id}`}
                          value={subCategory.sub_cat_id}
                          name="sub_cat_id"
                          onChange={handleSubCategoryChange}
                          className="w-3 h-3 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor={`subCategory-${subCategory.id}`}
                          className="ml-2 text-sm  text-gray-900 dark:text-gray-300"
                        >
                          {subCategory.sub_cat_name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <label
                    htmlFor="floating_subcategory"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Sub Category
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    value={formData.price}
                    onChange={handlePrice}
                    name="floating_price"
                    id="floating_price"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor="floating_price"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Price
                  </label>
                </div>
              </div>


              {/* <div class="relative z-0 w-full mb-2 group">
                  <input
                    type="text"
                    name="floating_address"
                    id="floating_address"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    
                    readOnly
                    value={formData.address}
                    // onChange={handleAddressChange}
                    // onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  />
                  <label
                    htmlFor="floating_address"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Address
                  </label>
                  <button type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"  onClick={handleMapOpen}>Search Location</button>
                
                </div> */}
              <div className="relative z-0 w-full py-5  mb-2 group">
                <div className="flex items-center gap-5">
                  {/* Label */}
                  <label
                    htmlFor="floating_address"
                    className="text-sm text-gray-500 dark:text-gray-400 font-medium"
                  >
                    Address
                  </label>

                  {/* Button */}
                  <button
                    type="button"
                    className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={handleMapOpen}
                  >
                    Search Location
                  </button>
                </div>

                {/* Input */}
                <input
                  type="text"
                  name="floating_address"
                  id="floating_address"
                  className="block mt-2 py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })} // Update the address
                  onBlur={fetchLatLong} // Fetch lat and long on blur
                />
              </div>



              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-2 group">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    htmlFor="providerImage"
                  >
                    Thumbnail
                  </label>

                  <input
                    className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="providerImage"
                    type="file"
                    onChange={handleThumbnailChange}  // Handle file change
                    required
                  />
                </div>
              </div>


              {/* <div className="grid md:grid-cols-2 md:gap-6">
              
                <div className="relative z-0 w-full mb-5 group">
                  <select
                    name="floating_doc_type"
                    id="floating_doc_type"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    required
                    value={formData.documentType}
                    onChange={handleDocumentTypeChange}
                  >
                    <option value="" disabled>
                      Select Document Type
                    </option>
                    <option value="Aadhar Card">Aadhar Card</option>
                    <option value="PAN Card">PAN Card</option>
                  </select>
                  <label
                    htmlFor="floating_doc_type"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Document Type
                  </label>
                </div>

              
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_document_number"
                    id="floating_document_number"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder={
                      formData.documentType
                        ? `Enter ${formData.documentType === "Aadhar Card" ? "12-digit Aadhar Number (e.g., 1234-5678-9012)" : "10-digit PAN Number"}`
                        : "Document Number"
                    }
                    required
                    value={formData.documentNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      handleCardNumberChange(e); // Format and validate input in the handler
                    }}
                    maxLength={formData.documentType === "Aadhar Card" ? 14 : 10} // Allow for dashes in Aadhar
                    pattern={
                      formData.documentType === "Aadhar Card"
                        ? "\\d{4}-\\d{4}-\\d{4}" // Accept formatted Aadhar number
                        : "[A-Z]{5}[0-9]{4}[A-Z]" // For PAN card
                    }
                    title={
                      formData.documentType === "Aadhar Card"
                        ? "Aadhar Card must be in the format XXXX-XXXX-XXXX."
                        : "PAN Card must be a 10-character alphanumeric value."
                    }


                  />

                  <label
                    htmlFor="floating_document_number"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >

                  </label>
                </div>
              </div>
              <div class="grid md:grid-cols-2 md:gap-6">
                <div class="relative z-0 w-full mb-5 group">
                  <input
                    type="file"
                    name="document"
                    id="document"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    onChange={handleUploadDocChange}
                    required
                  />
                  <label
                    htmlFor="document"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Upload Document
                  </label>
                </div>
                <div class="relative z-0 w-full mb-5 group">
                  <textarea
                    name="suggestion"
                    id="suggestion"
                    rows="1"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={formData.suggestion}
                    onChange={handlesuggestionChange}

                  ></textarea>
                  <label
                    htmlFor="suggestion"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Got Suggestions? Let Us Know!
                  </label>
                </div>
              </div> */}


<div className="grid md:grid-cols-2 md:gap-6">
  {/* Document Type Selection */}
  <div className="relative z-0 w-full mb-5 group">
    <select
      name="floating_doc_type"
      id="floating_doc_type"
      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
      required
      value={formData.documentType}
      onChange={handleDocumentTypeChange}
    >
      <option value="" disabled>
        Select Document Type
      </option>
      <option value="Passport">Passport</option>
      <option value="NRC">NRC</option>
      <option value="Driving License">Driving License</option>
    </select>
    <label
      htmlFor="floating_doc_type"
      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
    >
      Document Type
    </label>
  </div>
 {/* File Upload Input */}
 <div className="relative z-0 w-full mb-5 group">
    <input
      type="file"
      name="document"
      id="document"
      accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
      className="block w-full mb-5 text-xs mt-4 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
      onChange={handleUploadDocChange}
      required
    />
    <label
      htmlFor="document"
      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
    >
      Upload Document
    </label>
  </div>

</div>




             
              <div className="relative z-0 w-full mb-5 group">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Select Time Range
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="time"
                    name="startTime"
                    value={timeRange.startTime}
                    onChange={handleTimeRangeChange}
                    className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span className="text-gray-600 dark:text-gray-400">to</span>
                  <input
                    type="time"
                    name="endTime"
                    value={timeRange.endTime}
                    onChange={handleTimeRangeChange}
                    className="block py-2 px-2 w-full text-sm text-gray-900 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button
                    type="button"
                    onClick={handleAddTimeRange}
                    className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
                  >
                    Add
                  </button>
                </div>

                {/* Display Selected Time Slots */}
                {formData.availableTime.length > 0 && (
                  <div className="mt-3">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      Selected Time Slots:
                    </p>
                    <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400">
                      {formData.availableTime.map((slot, index) => (
                        <li key={index} className="flex justify-between items-center">
                          {slot.time} {/* Display the formatted time */}
                          <button
                            type="button"
                            onClick={() => handleRemoveTimeRange(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>




              <div>

                <div className="p-4">
                  {/* Dynamically Added Divs */}
                 <span className='text-gray-700 font-semibold'>   Upload Atleast 3 Work Images</span>
                  {fields.map((field, index) => (
                    <div
                      key={index}
                      className="grid border mt-2 px-3 py-2 mb-4 md:grid-cols-2 md:gap-6 bg-gray-100 rounded-lg shadow-sm"
                    >
                      {/* File Upload Input */}
                      <div className="relative z-0 w-full mb-5 group">
                        <label
                          className="block mb-2 text-sm font-medium text-gray-900"
                          htmlFor={`file_input_${index}`}
                        >
                          {/* Upload Atleast 3 Work Images */}
                          {`Image ${index + 1}`}
                        </label>
                        <input
                          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                          id={`file_input_${index}`}
                          type="file"
                          onChange={(e) => handleFileChange(index, e.target.files[0])}
                          required
                        />
                      </div>

                      {/* Description Input */}
                      <div className="relative z-0 w-full mb-5 group">
                        <input
                          type="text"
                          name={`text_input_${index}`}
                          id={`text_input_${index}`}
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          value={field.description}
                          onChange={(e) => handleDescriptionChange(index, e.target.value)}
                          required
                        />
                        <label
                          htmlFor={`text_input_${index}`}
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100"
                        >
                          Description
                        </label>
                      </div>

                      {/* Remove Button */}
                      {fields.length > 1 && (
                        <button
                          type="button"
                          onClick={() => removeField(index)}
                          className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  ))}

                  {/* Add Button */}
                  {fields.length < 3 && (
                    <button
                      type="button"
                      onClick={addField}
                      className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
                    >
                      Add More Work Images
                    </button>
                  )}

                </div>


                <div className="grid md:grid-cols-2 md:gap-6">
 

 {/* Suggestions Input */}
 <div className="relative  px-4 z-0 w-full mb-5 group">
   <textarea
     name="suggestion"
     id="suggestion"
     rows="1"
     className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
     placeholder=" "
     value={formData.suggestion}
     onChange={handlesuggestionChange}
   ></textarea>
   <label
     htmlFor="suggestion"
     className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
   >
     Got Suggestions? Let Us Know!
   </label>
 </div>
</div>

              </div>

             
              <div className='flex justify-center w-full'>
                <button
                  type="submit"
                  disabled={loading} // Disable button when loading
                  className={`w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center ${loading
                    ? 'bg-[#F5B400] cursor-not-allowed' // Disabled state
                    : 'bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                    }`}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      {/* <Vortex
        visible={true}
        height="25"
        width="25"
        ariaLabel="vortex-loading"
        wrapperStyle={{ marginRight: '8px' }}
        colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
      /> */}
                      Submitting ...
                    </div>
                  ) : (
                    'Submit'
                  )}
                </button>


              </div>
              <div class="text-sm py-3  font-medium text-gray-500 dark:text-gray-300">
                Already have an account ? <a class="text-blue-700 hover:underline dark:text-blue-500"><Link to='/provider-login'>Login to your account</Link></a>
              </div>


              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                  onClose={handleClose}
                  severity={snackbarSeverity}
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>

            </form>

            <Modal
              isOpen={isOpen}
              onRequestClose={handleMapClose}
              className="modal top-60"
              overlayClassName="overlay"
            >
              {isLoaded ? (
                <>
                  <h3 className="modal-title text-center border-b-2 pb-2 text-lg font-bold">Search for a location</h3>

                  {/* Input for location search */}
                  <StandaloneSearchBox
                    onLoad={(ref) => setSearchBox(ref)}
                    onPlacesChanged={handlePlacesChanged}
                  >
                    <input
                      type="text"
                      placeholder="Search for a location"
                      className="w-full p-2 border rounded-md mt-3"
                    />
                  </StandaloneSearchBox>

                  {/* Display map or placeholder */}
                  <div className="flex justify-center items-center bg-gray-200 mt-3" style={{ height: "150px" }}>
                    {markerPosition ? (
                      <GoogleMap
                        onLoad={handleMapLoad}
                        center={markerPosition || { lat: 37.7749, lng: -122.4194 }}
                        zoom={12}
                        mapContainerStyle={{ width: "100%", height: "150px" }}
                      >
                        {markerPosition && <Marker position={markerPosition} />}
                      </GoogleMap>
                    ) : (
                      <div className="text-gray-500">Map Preview</div>
                    )}
                  </div>

                  {/* Current Location and Address Input */}
                  <div className="flex items-center mt-4">
                    <button
                      className="bg-blue-500 text-white flex items-center gap-2 px-4 py-2 rounded-md"
                      onClick={getCurrentLocation}
                    >
                      <FaLocationArrow /> Current Location
                    </button>
                  </div>

                  <textarea
                    value={formData.address}
                    placeholder="Your Address..."
                    readOnly
                    className="w-full mt-3 p-2 border rounded-md"
                  ></textarea>

                  {/* Save Button */}
                  <div className="flex justify-center mt-4">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-md"
                      onClick={handleMapClose}
                    >
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <div>Loading...</div>
              )}
            </Modal>



          </div>
        </a>

        {/* w-full  text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 */}
      </div>

      <div className="hidden  w-full md:w-1/2 md:flex items-center justify-center">
        <img
          src={image}
          alt="Provider Signup Illustration"
          className="max-h-[600px] object-contain"
        />
      </div>

    </div>


  )
}



export default ProviderRegister
