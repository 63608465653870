
// const main = `http://88.99.163.249:4956`
// const main = `http://localhost:4956`

const main = ``
// const baseUrl = "http://192.168.1.44:8003/api/user-auth"
// export  {baseUrl}

const baseUrl = `${main}/api/user-auth`
export  {baseUrl}

const loginApi = `${main}/api/user-auth/login`
export  {loginApi}

const verifyPhoneOTP = `${main}/api/user-auth/verify/phone`
export  {verifyPhoneOTP}

const forgotPassword = `${main}/api/user-auth/password/forgot`
export  {forgotPassword}

const resetPassword = `${main}/api/user-auth/password/reset`
export  {resetPassword}

const getMasterCategory = `${main}/api/get/mastercat`
export  {getMasterCategory}

const getCategoryByMasterId = `${main}/api/get/mastercat/category`
export  {getCategoryByMasterId}

const getNotification = `${main}/api/graphql/count`
export  {getNotification}

const getSubCategory=`${main}/api/get/mastercat/category`
export {getSubCategory}

const subcategoryservice=`${main}/api/get/mastercat/category/sub-cat`
export {subcategoryservice}

const providerAuthRegister=`${main}/api/provider-auth/register`
export {providerAuthRegister}

const emailverify=`${main}/api/provider-auth/email/verify`
export {emailverify}

const providerLoginbyEmail=`${main}/api/provider-auth/login`
export {providerLoginbyEmail}

const providerLoginByNumber=`${main}/api/provider-auth/login`
export {providerLoginByNumber}

const forgotProviderPassword = `${main}/api/provider-auth/forgot-password`
export  {forgotProviderPassword}

const getBookingRequest=`${main}/api/provider/oddit/all-job`
export {getBookingRequest}


const getDashboardFigures=`${main}/api/provider/oddit/provider-figure`
export {getDashboardFigures}


const getDataToEdit=`${main}/api/provider/oddit/provider-details`
export {getDataToEdit}


const getServiceEditData=`${main}/api/provider/oddit/provider-service-details`
export {getServiceEditData}


const editproviderDetails=`${main}/api/provider/oddit/update-job`
export {editproviderDetails}

const acceptBookingRequest=`${main}/api/provider/oddit/job-approval`
export {acceptBookingRequest}

const addSubProvider=`${main}/api/sub-provider/subsequently/add-sub-provider`
export {addSubProvider}

const getSubProviderList=`${main}/api/sub-provider/subsequently/get-sub-provider`
export {getSubProviderList}

const getProviderServideDetails = `${main}/api/provider/oddit/get-service-detail/edit`
export {getProviderServideDetails}

// http://localhost:4956/api/provider/oddit/get-service-detail/edit?provider_id=76

const getSubCatList = `${main}/api/provider/oddit/getsub-cat-list`
export {getSubCatList}

const addSubCatProvider = `${main}/api/provider/oddit/add-sub-cat`
export {addSubCatProvider}

const deleteSubCategory = `${main}/api/provider/oddit/delete-sub-cat`
export {deleteSubCategory}

const getCategoryToLeftAdd = `${main}/api/provider/oddit/get-add-category`
export {getCategoryToLeftAdd}

const deleteCategory = `${main}/api/provider/oddit/delete-category`
export {deleteCategory}

const getCurrentMasterCategory = `${main}/api/provider/oddit-masterlist`
export {getCurrentMasterCategory}

const getCurrentCategory = `${main}/api/provider/oddit-categorylist`
export {getCurrentCategory}

const getSubCurrentCategory = `${main}/api/provider/oddit-subcategorylist`
export {getSubCurrentCategory}

const getSubProviderListTable = `${main}/api/provider/oddit/get-sub-provider`
export {getSubProviderListTable}

const deleteSubProvider = `${main}/api/sub-provider/subsequently/delete-sub-provider`
export {deleteSubProvider}

const providerOrderHistory=`${main}/api/provider/oddit/all-transactions`
export {providerOrderHistory}

