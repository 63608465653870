import axios from 'axios';

// const API_URL = 'http://localhost:4956';
// const API_URL = 'http://88.99.163.249:4956';
const API_URL = '';
export {API_URL}
export const getMastercategory = () => axios.get(`${API_URL}/api/get/mastercat`);
export const getCategory = (mastercat) => axios.get(`${API_URL}/api/get/mastercat/category/?masterid=${mastercat}`);
export const getSubCategory = (catid) => axios.get(`${API_URL}/api/get/mastercat/category/sub-cat/${catid}`);
export const loginApi = (payload) => axios.post(`${API_URL}/api/user-auth/login`, payload);

export const registerApi = ({ name, email,password, provider, phone,Address,profilePic }) => axios.post(`${API_URL}/api/user-auth/register`, { name, email, provider,password, phone,Address,profilePic });


export const verifyPhoneOTP = ({ phone, otp }) => axios.post(`${API_URL}/api/user-auth/verify/phone`, { phone, otp });
export const verifyEmailOTP = ({ email, otp }) => axios.post(`${API_URL}/api/user-auth/verify/email`, { email, otp });
// export const resendPhoneOTP = `${API_URL}/api/user-auth/phone/resend`;
export const resendEmailOTP = (email) => axios.post(`${API_URL}/api/user-auth/email/resend`, { email });
export const resetPassword = ({ password, token }) => axios.post(`${API_URL}/api/user-auth/password/reset?token=${token}`, { password });
export const forgotPassword = (email) => axios.post(`${API_URL}/api/user-auth/password/forgot`, { email });
export const logout = ({ user_id, type }) => axios.delete(`${API_URL}/api/user-auth/logout`, { data: { user_id, type } });
export const getNotification = () => axios.get(`${API_URL}/api/graphql/count?user_id=${localStorage.getItem("user_id")}`);

export const getCartItemList = ({user_id}) => axios.get(`${API_URL}/api/checkout/cart/get-cart?user_id=${user_id}`);

export const addToCard = ({user_id,provider_id,masterId,cat_id,sub_cat_id,quantity,price,booking_time,schedule_date}) => axios.post(`${API_URL}/api/checkout/cart/add-to-cart`, { user_id,provider_id, masterId, cat_id, sub_cat_id, quantity,price,booking_time,schedule_date });

export const removeFromCart = ({user_id,provider_id,masterId,cat_id,sub_cat_id}) => axios.put(`${API_URL}/api/checkout/cart/remove-from-cart`,{ user_id,provider_id, masterId, cat_id, sub_cat_id });

// export const getProviderOnDemand = ({location, sub_cat_id}) => axios.get(`${API_URL}/api/provider/oddit/location?city=${location}&scat_id=${sub_cat_id}`);

export const getProviderOnDemand = ({ lat, lng, sub_cat_id, radius = 5 }) => 
    axios.get(`${API_URL}/api/provider/oddit/location?lat=${lat}&lng=${lng}&sub_cat_id=${sub_cat_id}&radius=${radius}`);



export const getDetailsForBookingApi = ({user_id}) => axios.get(`${API_URL}/api/user/user-booking-details?user_id=${user_id}`);
export const payment = ({user_id,orders}) => axios.post(`${API_URL}/api/user/buy-service`, {user_id,orders});
export const googleLogin = ({email}) => axios.post(`${API_URL}/api/user-auth/auth/login`, {email});


// user details 
export const getUserDetails = () => axios.get(`${API_URL}/api/profile/get-profile-details?user_id=${localStorage.getItem("user_id")}`);


export const userOrderHistory = ()=> axios.get(`${API_URL}/api/user/order-history?user_id=${localStorage.getItem("user_id")}`)

export const SavedAddressApi = ()=> axios.get(`${API_URL}/api/profile/user-addresses?user_id=${localStorage.getItem("user_id")}`)
// http://localhost:4956/api/profile/get-profile-details?user_id=155