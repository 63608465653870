import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import otpimage from '../../assets/password.png';
import { Vortex } from 'react-loader-spinner';
import { useLocation ,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { emailverify } from '../../Service/provider-api-path.js'

const ProviderVerifyOTP = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const location = useLocation();
  const email = location.state?.email || 'your email';
  const navigate=useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVerify = async () => {
    if (otp.length !== 6) {
      setSnackbarMessage('Please enter a valid 4-digit OTP.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(emailverify, { email, otp });
      if (response.status === 200) {
        setSnackbarMessage('OTP verified successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
            navigate('/provider-login');
          }, 3000);
        // Navigate or perform additional actions after OTP verification
      } else {
        setSnackbarMessage('Invalid OTP. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);

        setOtp('')
      }
    } catch (error) {
      setSnackbarMessage('Invalid OTP. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setOtp('')
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-sm p-6 mt-[130px] bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
        <div className="flex justify-center">
          <img
            className="h-20 w-20 rounded-lg shadow-xl dark:shadow-gray-800"
            src={otpimage}
            alt="Email verification"
          />
        </div>
        <h5 className="mb-2 text-center my-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Please check your email.
        </h5>
        <p className="text-sm text-center">
          We have sent a code to <span className="font-semibold">{email}</span>
        </p>

        <div className="flex justify-center mx-auto mt-5">
          {loading ? (
            <Vortex
              visible={true}
              height="80"
              width="80"
              ariaLabel="vortex-loading"
              colors={['#FACC15', '#F81DA2', '#FACC15', '#F81DA2', '#FACC15', '#F81DA2']}
            />
          ) : (
            <OtpInput
              value={otp}
              onChange={setOtp}
              inputStyle={{
                width: '2em',
                height: '2em',
                fontSize: '1em',
                borderRadius: '4px',
                border: '2px solid blue',
                marginTop: '20px',
                color: 'black',
              }}
              numInputs={6}
              renderSeparator={<span className="mt-5">-</span>}
              renderInput={(props) => <input {...props} />}
            />
          )}
        </div>

        <button
          type="button"
          onClick={handleVerify}
          className="w-full text-white bg-[#F5B400] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mt-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={loading}
        >
          Verify OTP
        </button>
      </div>

      {/* Snackbar for status messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ProviderVerifyOTP;
