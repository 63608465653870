import { createContext, useState } from "react";
import io from 'socket.io-client';
import Cookies from 'js-cookie';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    // socket initialize code...
    const [socketForProvider, setSocketForProvider] = useState(null);
    const createSocketConnectionForProvider = () => {
        const providerid = Cookies.get('providerid');
        console.log('providerid: ', providerid);
        // const socket = io('http://88.99.163.249:4956');
        const socket = io('https://onyourservice.com',{
        // const socket = io('http://88.99.163.249:4956', {
            query: { providerid },
        });

        console.log('socket: ', socket);
        setSocketForProvider(socket);
        // return socket;
    }
    let value = { socketForProvider, createSocketConnectionForProvider }
    return <SocketContext.Provider value={value}>
        {children}
    </SocketContext.Provider>
}