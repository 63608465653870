// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { userOrderHistory } from "../../Service/api-path";

// const UserOrderHistory = () => {
//   const [userData, setUserData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   const user_id = localStorage.getItem("user_id");
//   console.log(user_id, "==user_id");

//   useEffect(() => {
//     const fetchUserData = async () => {
//       if (!user_id) {
//         setError("User ID not found");
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await userOrderHistory({ user_id });
//         console.log("USER ORDER DETAILS", response);
//         setUserData(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         setError("Failed to load user profile.");
//         setLoading(false);
//       }
//     };

//     fetchUserData();
//   }, [user_id]);

//   const handleClose = () => {
//     navigate(-1);
//   };

//   return (
//     <div className="bg-[#E2D9FA] h-screen flex justify-center items-center">
//       <div className="w-full max-w-xl bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700 relative">
//         <button
//           className="absolute top-2 right-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
//           onClick={handleClose}
//         >
//           <svg
//             className="w-5 h-5"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M6 18L18 6M6 6l12 12"
//             />
//           </svg>
//         </button>

//         <div className="flex flex-col items-center pb-10">
//           {loading ? (
//             <p className="text-gray-600 dark:text-white">Loading profile...</p>
//           ) : error ? (
//             <p className="text-red-600">{error}</p>
//           ) : userData.length > 0 ? (
//             userData.map((order) => (
//               <div key={order.Booking_id} className="mb-4">
//                 <h5 className="text-xl font-medium text-gray-900 dark:text-white">
//                   {order.sub_category_name}
//                 </h5>
//                 <p className="text-sm text-gray-500 dark:text-gray-400">
//                   Scheduled: {order.schedule_date} - {order.schedule_time}
//                 </p>
//               </div>
//             ))
//           ) : (
//             <p className="my-3 text-gray-600 dark:text-white">No orders found.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserOrderHistory;



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserDetails, userOrderHistory } from "../../Service/api-path";
import { FaCalendarAlt, FaClock, FaMapMarkerAlt, FaTimes } from "react-icons/fa";

const OrderHistory = () => {
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const user_id = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await userOrderHistory();
        setOrderData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order history:", error);
        setError("Failed to load order history.");
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [user_id]);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center py-10">
      <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg p-8">
        <div className="flex justify-between items-center border-b pb-4 mb-6">
          <h2 className="text-3xl font-bold text-gray-900">Order History</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            ✕
          </button>
        </div>

        {loading ? (
          <p className="text-gray-600 text-center">Loading order history...</p>
        ) : error ? (
          <p className="text-red-600 text-center">{error}</p>
        ) : orderData.length === 0 ? (
          <p className="text-gray-600 text-center">No order history available.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {orderData.map((order) => (
              <div
                key={order.Booking_id}
                className="bg-gradient-to-r from-blue-100 to-blue-200 border rounded-2xl p-6 shadow-md hover:shadow-lg transition transform hover:scale-105"
              >
                <div className="flex justify-between items-start">
                  <h3 className="text-2xl font-semibold text-gray-900">{order.sub_category_name}</h3>
                  <button className="text-gray-400 hover:text-gray-600">
                    <FaTimes />
                  </button>
                </div>
                <div className="text-sm text-gray-700 mt-2 space-y-2">
                  <p className="flex items-center gap-2">
                    <FaCalendarAlt className="text-blue-500" /> {order.schedule_date}
                  </p>
                  <p className="flex items-center gap-2">
                    <FaClock className="text-blue-500" /> {order.schedule_time}
                  </p>
                  <p className="flex items-center gap-2">
                    <FaMapMarkerAlt className="text-blue-500" /> {order.user_address}
                  </p>
                  <p>
                    <strong>Provider:</strong> {order.provider_name}
                  </p>
                  <p>
                    <strong>Quantity:</strong> {order.quantity}
                  </p>
                  {/* <p
                    className={`text-sm font-medium ${order.Payment_Status ? "text-green-600" : "text-red-600"}`}
                  >
                  <strong>Payment Status</strong>  {order.Payment_Status ? "Paid" : "Pending"}
                  </p> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistory;

