import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import HeaderBanner from '../components/Home/HeaderBanner/HeaderBanner'
import ServiceDetails from '../components/Home/ServiceDetails'
import ExperienceCrousal from '../components/Home/Experience/ExperienceCrousal'
import ServiceModel from '../components/Home/ServiceModel/ServiceModel'
import Footer from '../components/Footer'

function Home() {
  const [add, setAdd] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');

  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          console.log("object", data);
          setAdd(data.address);
          const address = data.address;
          // setCurrentAddress(data.address?.city || data.display_name?.split(",")[4] || "Unknown location");
          // setCurrentAddress(data.address?.city || data.display_name);
          let currentAddress = "";
          if (address?.town && address.house_number) {
            currentAddress = `${address.house_number} ,${address.town}, ${address.state} , ${address.country}`;
          } else if (address?.state_district && address?.suburb) {
            currentAddress = `${address.suburb}, ${address.state} ,${address.country}`;
          } else if (address?.state_district && address?.city) {
            // currentAddress = "Unknown location";
            currentAddress = `${address.city}, ${address.state}`
          }
          else if (address?.city && address?.country) {
            currentAddress = `${address.city}, ${address.country}`
          }

          // Set the current address
          console.log('currentAddress: ', currentAddress);
          setCurrentAddress(currentAddress);
        })
        .catch((error) => console.error("Error fetching location:", error));
    });
  };

  useEffect(() => {
    getLocation();
  }, []);






  

  return (
    <div>
      <Navbar currentAddress={currentAddress} />
      <HeaderBanner />
      <ServiceDetails />
      <ExperienceCrousal />
      <ServiceModel />
      <Footer />

    </div>
  )
}

export default Home



