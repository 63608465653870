import React from "react";
import { Outlet } from "react-router-dom";
import ProviderNavbar from "../components/ProviderNavbar.js";



const ProviderLayout = () => {
  return (
    <div className="flex bg-[#202531]">

      <ProviderNavbar />
        <main className="flex-1 p-4 bg-gray-100 dark:bg-gray-900">
        <Outlet />
      </main>
    </div>
  );
};

export default ProviderLayout;
