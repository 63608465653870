import { useEffect, useState } from "react";
import { ChevronRight, Star } from "lucide-react";
import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ScrollPanel } from 'primereact/scrollpanel';

import { motion } from 'framer-motion';
import PolicyModel from "../ModelPolicy/PolicyModel";
import CommentModel from "../CommentModel/CommentModel";
import { getProviderOnDemand, getSubCategory } from "../../../Service/api-path";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingModel from "../BookingModel/BookingModel";

function ListProvider() {
    const location = useLocation();
    // console.log('location: ', location);
    const { subCategories } = location.state || {};
    // console.log('Sub Categories:', subCategories);
    const detaultSub_Cat = subCategories[0].sub_cat_id;
    const [loadingProvider, setLoadingProvider] = useState(false);
    const { cat_id } = useParams();
    // console.log('cat_id: ', cat_id);
    const [providerList, setProviderList] = useState([]);
    const [showMoreOffers, setShowMoreOffers] = useState(false);
    // const cardNames = ["Card 1", "Card 2", "Card 3"];
    const [isOpen, setIsOpen] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [cardNames, setCardNames] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [isOpenComment, setIsOpenComment] = useState(false);
    const istoggleModelBooking = (service) => {
        setSelectedService(service);
        setIsBooking(!isBooking);
    }
    const toggleModalComment = () => {
        setIsOpenComment(!isOpenComment);
    }
    const toggleModal = () => {
        setIsOpen(!isOpen);
    }
    const toggleOffers = () => {
        setShowMoreOffers(!showMoreOffers);
    };

    const [firstImages, setFirstImages] = useState([]);

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                        reject(error);
                    }
                );
            } else {
                reject(new Error("Geolocation not supported by browser"));
            }
        });
    };

    const handelOdditProvider = async (service) => {
        setLoadingProvider(true);
        try {
            // Get user's current location
            const location = await getUserLocation();
            console.log("User Location:", location);
           console.log("LAT:",location.lat, "LONG:",location.lng)
            // Fetch providers based on location and service category
            const response = await getProviderOnDemand({
                lat: location.lat,
                lng: location.lng,
                sub_cat_id: service.sub_cat_id,
                radius: 5, // Default to 5km
            });

            console.log("Response Providers:", response.data);
            setProviderList(Array.isArray(response.data.result) ? response.data.result : []);
        } catch (error) {
            console.error("Error fetching providers:", error);
        } finally {
            setLoadingProvider(false);
        }
    };

    // const handelOdditProvider = async (service) => {
    //     setLoadingProvider(true);
    //     console.log('service:=-=-= ', service);
    //     try {
    //         const response = await (getProviderOnDemand({ location: 'Chandigarh', sub_cat_id: service.sub_cat_id }));

    //         console.log("RESPONSE PROVIDER ONDEMAND",response.data)
    //         setProviderList(Array.isArray(response.data.result) ? response.data.result : []);
    //         console.log(providerList)
    //         setLoadingProvider(false);
            

    //     } catch (error) {
    //         console.log('error: ', error);
    //         setLoadingProvider(false);
    //     }
    //     finally {
    //         setLoadingProvider(false);
    //     }
    // }


    useEffect(() => {
        if (subCategories && subCategories.length > 0) {
            const images = subCategories?.map((category) => {
                const parsedImages = JSON.parse(category.image_banner_url).images;
                return parsedImages.length > 0 ? parsedImages[0].url : '';
            });
            setFirstImages(images);
        }
        handelOdditProvider(subCategories[0]);
    }, [subCategories]);
    // console.log('firstImages: ', firstImages);

    const getItemSubCategory = async () => {
        const response = await getSubCategory(cat_id);
        setCardNames(response.data);
        setCategoryName(response.data[0].category_name);

        // console.log('response: ', response.data);
    }

    useEffect(() => {
        getItemSubCategory(cat_id)
    }, [cat_id])



    return (
        <div className="flex flex-col lg:flex-row   gap-5 min-h-screen bg-gray-100 p-4">
            {/* Left Sidebar */}
            <div className="w-full ml-[-5px] md:ml-1  md:max-h-[100vh] lg:w-2/4 bg-white shadow-md rounded-lg p-4 mx-2"> <ScrollPanel style={{ width: '100%', height: '100%' }}>
                <h2 className="text-xl font-semibold mb-4">Service</h2>
                <div className="space-y-4">
                    {loadingProvider ? (
                        <div className="flex flex-wrap gap-4 justify-center">
                            {Array.from({ length: 3 }).map((_, index) => (
                                <div
                                    key={index}
                                    className="animate-pulse bg-white shadow-md rounded-lg p-4 w-full flex flex-col space-y-4"
                                >
                                    <div className="flex items-start space-x-4">
                                        <div className="bg-gray-200 h-32 w-32 rounded-lg"></div>

                                        <div className="flex-1 space-y-2">
                                            <div className="bg-gray-200 h-4 rounded w-3/4"></div>
                                            <div className="bg-gray-200 h-2 rounded w-1/2"></div>
                                            <div className="bg-gray-200 h-2 rounded w-2/3"></div>
                                            <div className="bg-gray-200 h-2 rounded w-1/4"></div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    ) : providerList.length > 0 ? (
                        providerList.map((provider, index) => (
                            <motion.div
                                key={index}
                                whileTap={{ scale: 1.1 }}
                                className="cursor-pointer flex items-start space-x-4 bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-transform"
                            >
                                <div className="flex flex-col items-center">
                                    <img
                                        src={provider?.providerImage || 'https://via.placeholder.com/150'}
                                        alt={provider.name}
                                        className="rounded-lg w-32"
                                    />
                                    <button
                                        onClick={() => istoggleModelBooking(provider)}
                                        className="mt-[-13%] px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                                    >
                                        + Add
                                    </button>
                                </div>

                                <div className="flex-1 space-y-[2px]">
                                    <h3 className="text-lg font-bold font-urbanist">{provider.name}</h3>
                                    <p className="text-gray-500 text-sm">
                                        Save more on your {provider.sub_cat_name}
                                    </p>
                                    <div className="text-purple-600 text-[11px] font-semibold mt-2">
                                        ⭐ 4.82 (757K reviews)
                                    </div>
                                    <div className="text-green-600 text-[12px] font-semibold mt-1">
                                        Starts at ₹{provider.price}
                                    </div>
                                    <h1 className="text-[12px] text-[#807c7c]">
                                        Complete check-up to identify issues before repair
                                    </h1>
                                </div>
                            </motion.div>
                        ))
                    ) : (
                        <div className="flex justify-center items-center h-[50vh]">
                            <p className="text-gray-500">No providers available at the moment.</p>
                        </div>
                    )}


                </div>
            </ScrollPanel>

            </div>



            {/* Main Content */}
            <div className="w-full space-y-8 lg:w-[27%] bg-white shadow-md rounded-lg p-4 lg:mb-0">
                <div className="flex flex-col items-center lg:block space-y-3">
                    <h1 className="text-2xl font-roboto font-bold">{categoryName}</h1>
                    <div onClick={toggleModalComment} className=" text-sm font-bold cursor-pointer underline flex items-center gap-3"><Star size={17} className="text-purple-600" style={{ fill: "currentColor" }} />
                        4.81 (2k+ bookings)</div>
                </div>

                {/* <div onClick={toggleModal} className="bg-[#f5f5f5]  rounded-xl p-1 cursor-pointer hover:bg-[#f0f0f5]">
                    <div className="flex items-center gap-3 p-3">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 text-white bg-green-600 rounded-3xl"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M20 6L9 17l-5-5" />
                        </svg>
                        <div className="text-[#6e5454] text-sm font-semibold">UC COVER</div>
                    </div>
                    <div className="flex justify-end mt-[-8%]">
                        <ChevronRight />
                    </div>
                    <div className="pb-2">
                        <h1 className="text-sm ml-5 cursor-pointer"> 30 days warranty on service</h1>
                    </div>

                </div> */}

                <div className="border-2 rounded-lg flex justify-around items-center p-4 relative">
                    <p className="absolute top-[-12px] left-[10px] bg-white px-2 text-sm font-semibold">
                        Select a Service
                    </p>
                    <div className="grid grid-cols-2 gap-4">

                        {subCategories?.map((service, index) => (
                            <div className="w-full h-auto">

                                <motion.div
                                    key={index}
                                    onClick={() => handelOdditProvider(service)}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 1.3 }}
                                    className="cursor-pointer relative flex flex-col items-center justify-end p-2 bg-white shadow-md rounded-xl hover:shadow-lg transition-transform overflow-hidden"
                                >
                                    <div className="relative w-full h-20 mb-4">
                                        <img
                                            src={firstImages[index] || "https://via.placeholder.com/150"}
                                            alt={service.sub_cat_name}
                                            className="rounded-lg w-36 md:h-[90%]"
                                        />
                                    </div>
                                    <p className="cursor-pointer absolute bottom-0 w-full text-center flex justify-center items-center md:mt-0 text-[10px] md:text-[9px] font-normal font-bellota text-white bg-black/90 h-9">
                                        {service.sub_cat_name}
                                    </p>
                                </motion.div>
                            </div>

                        ))}
                    </div>
                </div>


            </div>


            {isOpen && (<PolicyModel isOpen={isOpen} toggleModal={toggleModal} />)}
            {isOpenComment && (<CommentModel isOpenComment={isOpenComment} toggleModalComment={toggleModalComment} />)}
            {isBooking && (<BookingModel isBooking={isBooking} istoggleModelBooking={istoggleModelBooking} service={selectedService} />)}


            <div className="w-full lg:w-1/4 flex flex-col overflow-y-scroll space-y-4 bg-white shadow-md rounded-lg p-4">
                {/* <div className="border p-4 rounded-lg hidden md:flex flex-col justify-center items-center">
                    <img className="h-[70%] w-[80%]" src="https://img.freepik.com/premium-vector/empty-cart_701961-7086.jpg" alt="" />
                    <h1 className="text-[#626161] font-semibold ">No items in your cart</h1>
                </div> */}

                <div>
                    <div className="bg-green-200 text-green-900 p-4 rounded-lg">
                        <p>Buy more save more</p>
                        <p>₹100 off 2nd item onwards</p>
                        <h1
                            className="text-sm font-bold text-black cursor-pointer"
                            onClick={toggleOffers}
                        >
                            {showMoreOffers ? "View Less Offers" : "View More Offers"}
                        </h1>
                        {showMoreOffers && (
                            <div className="mt-4">
                                <div className="bg-green-50 text-green-900 p-2 rounded-lg mb-2">
                                    <p>Offer 1: Get 20% off on orders above ₹500</p>
                                </div>
                                <div className="bg-green-50 text-green-900 p-2 rounded-lg">
                                    <p>Offer 2: Free shipping on orders above ₹1000</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-4 flex justify-between items-center border  p-4 rounded-md">
                    <div>
                        <h3 className="text-lg font-bold">UC Promise</h3>
                        <ul className="list-disc list-inside text-gray-600 text-sm">
                            <li>Verified Professionals</li>
                            <li>Hassle Free Booking</li>
                            <li>Transparent Pricing</li>
                        </ul>
                    </div>
                    <div>
                        <img className="h-14 w-14" src="https://res.cloudinary.com/urbanclap/image/upload/t_high_res_category/w_64,dpr_2,fl_progressive:steep,q_auto:low,f_auto,c_limit/images/growth/luminosity/1702985608819-4a9ba6.jpeg"
                            alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListProvider;
