import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import permission1 from '../../../utils/images/permission1.png'
import permission2 from '../../../utils/images/permission2.png'
import permission3 from '../../../utils/images/permission3.png'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BookingCommentModel from "./BookingCommentModel";
import { Snackbar } from "@mui/material";
import { addToCard } from "../../../Service/api-path";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {getCartItemList} from '../../../Service/api-path'

function BookingModel({ isBooking, istoggleModelBooking, service }) {
    console.log('service: ', service);
    const [open, setOpen] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [message, setMessage] = useState('');
    const [ablTime, setablTime] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [imagesDetails, setImagesDetails] = useState([]);
    const user_id = localStorage.getItem('user_id');
    const [BookDate, setBookDate] = useState('');
    const [cartCount,setCartCount]=useState('')
    

    useEffect(() => {
        if (service) {
            try {
                const parsedImages = JSON.parse(service.image_banner_url)?.images || [];
                setImageUrls(parsedImages.map(image => image.url));

                const cleanedAvailableTime = service.availableTime.replace(/\\/g, '').replace(/^"|"$/g, '');
                const decodedAvailableTime = JSON.parse(cleanedAvailableTime);
                setablTime(decodedAvailableTime.map(time => time.time));

                const parsedDescriptions = JSON.parse(service.description);
                console.log('parsedDescriptions: ', parsedDescriptions);
                setDescriptions(parsedDescriptions);

                const parsedImagesDetails = JSON.parse(service.images_details);
                setImagesDetails(parsedImagesDetails);
            } catch (error) {
                console.error('Error parsing service data:', error);
                setImageUrls([]);
                setablTime([]);
                setDescriptions([]);
                setImagesDetails([]);
            }
        }
    }, [service]);

    useEffect(()=>{
        setCartCount()
    },[cartCount])

    const NextArrow = ({ onClick }) => {
        return (
            <div
                className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer"
                onClick={onClick}
            >
                <FaArrowRight size={15} className="text-gray-800" />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div
                className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer"
                onClick={onClick}
            >
                <FaArrowLeft size={15} className="text-gray-800" />
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    if (!isBooking) return null;


  const GetCardList = async () => {
    console.log("GET CART ITEM USERID",user_id)
   const response = await getCartItemList({ user_id });
   setCartCount(response.data.result.length)
   console.log("length" ,response.data.result.length)
    console.log('cart: ', response?.data?.result);
  }



    const handleClick = () => {
        setOpen(true);
    };

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handelAddCart = async (time) => {
        console.log('Add to cart');
        if (!BookDate) {
            setMessage("Please select a date before booking");
            handleClick();
            return;
        }
        if (!user_id) {
            setMessage("You need to login first");
            handleClick();
        } else {
            const response = await addToCard({ 
                user_id, 
                provider_id: service.id, 
                masterId: service.masterId, 
                cat_id: service.cat_id, 
                sub_cat_id: service.sub_cat_id, 
                quantity: 1, 
                price: service.price, 
                booking_time: time, 
                schedule_date: BookDate 
            });
    
            console.log('response: Add To cart model ', response);
    
            if (response.data) {
                setMessage("Item added to cart");
                handleClick();
    
                // Close the booking modal after adding to cart
                setTimeout(() => {
                    istoggleModelBooking();
                }, 1000); // Delay slightly to allow message visibility
            }
        }
    };
    
    const handleDateChange = (newValue) => {
        if (newValue) {
            const formattedDate = formatDateToYYMMDD(newValue);
            setBookDate(formattedDate);
            console.log('Formatted Date:', formattedDate);
        }
    };
    const formatDateToYYMMDD = (date) => {
        return dayjs(date).format('YYYY-MM-DD');
    };


    return (
        <div className="fixed inset-0 flex items-center  justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-4 max-w-lg relative max-h-[85%] overflow-y-scroll">
                <div className="flex justify-between items-center mb-4 ">
                    <h2 className="text-xl font-semibold">
                        Booking - {service.name}
                    </h2>
                    <button
                        onClick={istoggleModelBooking}
                        className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
                        aria-label="Close"
                    >
                        ✕
                    </button>
                </div>

                <div className="overflow-hidden mt-3 ">
                    <Slider {...settings}>
                        {imageUrls?.map((image, index) => (
                            <div key={index} className="flex justify-center">
                                <img
                                    src={image}
                                    alt={`Slide ${index + 1}`}
                                    className="rounded-lg  h-48 w-full"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>

                <h1 className="text-xl mt-1 font-semibold font-mono">{service.cat_name} &rarr; {service.sub_cat_name}</h1>
                <div className="flex flex-col md:flex-row space-y-2 justify-between">
                    <p className="text-sm font-medium inline-flex  items-center gap-2 text-[#545454]"><span><FaStar /></span>
                        4.77 (99K reviews)</p>
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-3 md:gap-5">
                        {/* <p className="text-base md:ml-5">Slot - </p> */}
                        <LocalizationProvider
                            sx={{ width: "30%" }}
                            dateAdapter={AdapterDayjs}
                        >
                            <DemoContainer
                                components={[
                                    'DatePicker',
                                    'DateTimePicker',
                                    'TimePicker',
                                    'DateRangePicker',
                                    'DateTimeRangePicker',
                                ]}
                            >
                                <DemoItem>
                                    <DatePicker
                                          onChange={handleDateChange}
                                        disablePast
                                        views={['year', 'month', 'day']}
                                    />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </div>



                <div className="overflow-hidden md:mt-3 ">
                    <Slider {...settings2}>
                        {ablTime?.map((time, index) => (
                            <div key={index} className="p-2 ">
                                <div className="border rounded-lg p-1  shadow-md text-center md:w-[142px]">
                                    <h3 className="text-sm font-semibold inline-flex">{time}</h3>
                                    <p className="text-lg font-bold mt-2">₹ {service.price}</p>
                                    <button onClick={() => handelAddCart(time)} className="mt-4 px-4 py-2 bg-purple-500 text-white rounded-full hover:bg-purple-600">
                                        + Add
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <hr class="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded  dark:bg-[#cccccc]" />


                <div className="overflow-hidden">
                    <div className="cursor-pointer">
                        <img className="w-full transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-90 hover:bg-indigo-500 duration-300" src={permission1} alt="permission1" />
                    </div>
                    <hr className="w-full h-1 mx-auto my-2 bg-gray-100  rounded dark:bg-[#cccccc]" />
                    <div className="cursor-pointer">
                        <img className="w-full md:ml-5 transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-90 hover:bg-indigo-500 duration-300" src={permission2} alt="permission2" />
                    </div>
                    <hr className="w-full h-1 mx-auto my-2 bg-gray-100  rounded dark:bg-[#cccccc]" />
                    <div className="cursor-pointer">
                        <img className="w-full md:ml-4 transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-90 hover:bg-indigo-500 duration-300" src={permission3} alt="permission3" />
                    </div>
                    <hr className="w-full h-1 mx-auto my-2 bg-gray-100  rounded dark:bg-[#cccccc]" />
                </div>

                <div>
                    <Box>
                        <Stepper orientation="vertical">
                            {descriptions?.map((description, index) => (
                                <Step key={index} active >
                                    <StepLabel>
                                        {description}
                                    </StepLabel>
                                    <StepContent>
                                        <img
                                            src={imagesDetails[index] || "https://via.placeholder.com/150"}
                                            alt={`Step ${index + 1}`}
                                            className="w-full  h-48"
                                        />
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                </div>
                <hr class="w-full mt-6 h-1 mx-auto my-4 bg-gray-100 border-0 rounded  dark:bg-[#cccccc]" />


                <div className="mt-5">
                    {/* <BookingCommentModel /> */}
                </div>

            </div>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
            // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </div>
    );
}

export default BookingModel;